import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Col, Spin } from 'antd';
import { CommandToBeSendStatus } from '@/features/commandsToBeSend/commandToBeSend.enum';
import { DeviceModelProfileDto } from '@/features/modelProfile/modelProfile.dto';
import AdvancedFilter from '@/OptimusRoutes/components/filter/AdvancedFilter';
import useAdvancedFilter from '@/OptimusRoutes/hooks/useAdvancedFilter.hook';
import { OtherParams } from '@/OptimusRoutes/interfaces';
import { FilterData, FilterDataSelector } from '@/OptimusRoutes/types/filterComponent.types';

export enum FilterOptions {
	PIN = 1,
	MODEL = 2,
	COMMAND = 3,
	CREATED_AT_DATE = 4,
	STATUS = 5,
	PORT = 6,
	FIRMWARE = 7,
}

type Props<T> = {
	search: (search?: string | Partial<T> | undefined, filterParams?: OtherParams) => Promise<void>;
	models: DeviceModelProfileDto[];
	loading: boolean;
};

const CommandsToBeSendFilter = <T extends Object>({ search, models, loading }: Props<T>) => {
	const intl = useIntl();

	const { fetchFilteredData, clearFilters, setAppliedFilters } = useAdvancedFilter({
		search,
	});

	const modelOptions = models.map<FilterDataSelector>(({ id, description }) => ({
		key: id,
		value: id,
		label: description,
	}));

	const filters: FilterData[] = useMemo(() => {
		return [
			{
				id: FilterOptions.PIN,
				type: 'TEXT',
				filterwhere: 'pin',
				intlLabelCode: 'pin',
				placeholder: intl.formatMessage(translate('pinPlaceholder')),
			},
			{
				id: FilterOptions.MODEL,
				type: 'DROPDOWN',
				filterwhere: 'modelId',
				intlLabelCode: 'model',
				placeholder: intl.formatMessage(translate('modelPlaceholder')),
				options: modelOptions,
			},
			{
				id: FilterOptions.COMMAND,
				type: 'TEXT',
				filterwhere: 'command',
				intlLabelCode: 'command',
				placeholder: intl.formatMessage(translate('command')),
			},
			{
				id: FilterOptions.CREATED_AT_DATE,
				type: 'DATE',
				intlLabelCode: 'date',
				filterwhere: 'createdAt',
			},
			{
				id: FilterOptions.STATUS,
				type: 'DROPDOWN',
				filterwhere: 'status',
				intlLabelCode: 'status',
				options: Object.values(CommandToBeSendStatus).map((status) => ({
					key: 1,
					value: status,
					label: intl.formatMessage(translate(`COMMAND_${status}`)),
				})),
			},
		];
	}, [models]);

	useEffect(() => {
		fetchFilteredData();
	}, []);
	let Filter = <Spin spinning />;

	if (!loading) {
		Filter = (
			<AdvancedFilter
				filters={filters}
				onChange={setAppliedFilters}
				onClearFilters={clearFilters}
				key="advanced-filter"
				placeholder={intl.formatMessage(translate('advancedFilter'))}
			/>
		);
	}

	return <Col span={16}>{Filter}</Col>;
};

export default CommandsToBeSendFilter;

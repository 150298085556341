import { useEffect, useState } from 'react';
import { OtherParams } from '../interfaces';
import { AppliedFilter } from '../types/filterComponent.types';

type Props<T extends Object> = {
	search: (search?: string | Partial<T> | undefined, filterParams?: OtherParams | undefined) => Promise<void>;
	initialFilters?: AppliedFilter[] | [];
};

const useAdvancedFilter = <T extends Object>({ initialFilters = [], search }: Props<T>) => {
	const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>(initialFilters);
	const [filtersChanged, setFiltersChanged] = useState<Date>();

	const fetchFilteredData = async (clear?: boolean) => {
		if (clear) {
			setAppliedFilters([]);
			search('');
			return;
		}
		let searchItems: Partial<T> = {};
		appliedFilters.forEach((appliedFilter) => {
			searchItems = { [appliedFilter.filterwhere]: appliedFilter.value, ...searchItems };
		});

		search(searchItems);
	};

	const clearFilters = () => {
		fetchFilteredData(true);
		setFiltersChanged(new Date());
	};

	useEffect(() => {
		if (!appliedFilters || appliedFilters === initialFilters) {
			return;
		}

		fetchFilteredData();
		setFiltersChanged(new Date());
	}, [appliedFilters]);

	return {
		fetchFilteredData,
		clearFilters,
		setAppliedFilters,
		filtersChanged,
		appliedFilters,
	};
};

export default useAdvancedFilter;

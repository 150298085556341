import axios from 'axios';
import qs from 'qs';
import {
	CommandsToBeSend,
	CommandToBeSendInfoDto,
	CreateCommandToBeSendDto,
	CreateCommandToBeSendResponseDto,
} from '@/features/commandsToBeSend/commandsToBeSend.dto';
import { ApiResult, EntityFilter, Filter, IService, Result } from '../../OptimusRoutes/interfaces';

type Service = IService<CommandsToBeSend> & {
	getCommandInfo: (id: number) => Promise<Result<CommandToBeSendInfoDto>>;
	createCommands: (
		commands: CreateCommandToBeSendDto
	) => Promise<ApiResult<Result<CreateCommandToBeSendResponseDto>>>;
};

const get = async ({ skip, take, filter = {} }: Filter<CommandsToBeSend>): Promise<ApiResult<CommandsToBeSend[]>> => {
	const params = { skip, take, filter: JSON.stringify(filter) };
	const { data } = await axios.get<Result<ApiResult<CommandsToBeSend[]>>>(
		`/v2/commands/to-be-send?${qs.stringify(params)}`
	);
	if (!data.error && data.success) {
		return data.success;
	}
	return {
		count: 0,
		data: [],
	};
};
const getCommandInfo = async (id: number): Promise<Result<CommandToBeSendInfoDto>> => {
	const { data } = await axios.get<Result<CommandToBeSendInfoDto>>(`/v2/commands/to-be-send/${id}`);
	return data;
};

const remove = async (id: number): Promise<void> => await axios.delete(`/v2/commands/to-be-send/${id}`);

const deleteBatch = async (filter: EntityFilter<CommandsToBeSend> = {}): Promise<void> => {
	const data = { filter };
	// console.log({ data });
	await axios.delete(`/v2/commands/to-be-send/`, { data });
	return Promise.resolve();
};

const createCommands = async ({
	pins,
	command,
	isMultiple = false,
	applyToLast = 0,
	filter = '',
}: CreateCommandToBeSendDto): Promise<ApiResult<Result<CreateCommandToBeSendResponseDto>>> =>
	await axios.post('/v2/commands/to-be-send', {
		pins,
		command,
		isMultiple,
		applyToLast,
		filter: JSON.stringify(filter),
	});

const CommandsToBeSendService: Service = {
	get,
	getCommandInfo,
	delete: remove,
	deleteBatch,
	createCommands,
};

export default CommandsToBeSendService;

import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

type Props = {
	title: string;
	items: { label: string; value: string }[];
};

export const ClientInformationCard = ({ title, items }: Props) => {
	return (
		<div className="client-information-card">
			<Title level={3}>{title}</Title>
			<div className="client-information-card-content">
				{items.map((item) => (
					<div key={item.label} className="client-information-card-content-item">
						<span>{item.label}:</span>
						<span>{item.value}</span>
					</div>
				))}
			</div>
		</div>
	);
};

import axios, { CancelToken } from 'axios';
import { DeviceLastReportDto } from '@/features/map/types/devicesLastReport.types';
type Service = {
	getMapDevices: () => Promise<DeviceLastReportDto>;
};
const getMapDevices = async (cancelToken?: CancelToken) => {
	const response = await axios.get<DeviceLastReportDto>('/v2/devices/map', { cancelToken });
	return response.data || [];
};

const MapService: Service = {
	getMapDevices,
};

export default MapService;

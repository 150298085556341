import MathUtil from './MathUtils';

class PolyUtil {
	constructor() {
		this.MathUtil = new MathUtil();
	}

	LocationIndexOnEdgeOrPath = function (point, poly, closed, geodesic, toleranceEarth) {
		var size = poly.Count;
		if (size === 0) {
			return -1;
		}
		var tolerance = toleranceEarth / this.MathUtil.EarthRadius;
		var havTolerance = this.MathUtil.Hav(tolerance);
		var lat3 = this.MathUtil.ToRadians(point.latitude);
		var lng3 = this.MathUtil.ToRadians(point.longitude);
		var prev = poly[closed ? size - 1 : 0];
		var lat1 = this.MathUtil.ToRadians(prev.lat());
		var lng1 = this.MathUtil.ToRadians(prev.lng());
		var idx = 0;
		if (geodesic) {
			for (var point2 in poly) {
				var lat2 = this.MathUtil.ToRadians(poly[point2].lat());
				var lng2 = this.MathUtil.ToRadians(poly[point2].lng());
				if (this.IsOnSegmentGc(lat1, lng1, lat2, lng2, lat3, lng3, havTolerance)) {
					return Math.max(0, idx - 1);
				}
				lat1 = lat2;
				lng1 = lng2;
				idx++;
			}
		} else {
			//  We project the points to mercator space, where the Rhumb segment is a straight line,
			//  and compute the geodesic distance between point3 and the closest point on the
			//  segment. This method is an approximation, because it uses "closest" in mercator
			//  space which is not "closest" on the sphere -- but the error is small because
			//  "tolerance" is small.
			var minAcceptable = lat3 - tolerance;
			var maxAcceptable = lat3 + tolerance;
			var y1 = this.MathUtil.Mercator(lat1);
			var y3 = this.MathUtil.Mercator(lat3);
			var xTry = new Array(3);
			for (var secondPoint in poly) {
				var secondLat = this.MathUtil.ToRadians(poly[secondPoint].lat());
				var y2 = this.MathUtil.Mercator(secondLat);
				var secondLng = this.MathUtil.ToRadians(poly[secondPoint].lng());
				if (Math.max(lat1, secondLat) >= minAcceptable && Math.min(lat1, secondLat) <= maxAcceptable) {
					//  We offset longitudes by -lng1; the implicit x1 is 0.
					var x2 = this.MathUtil.Wrap(secondLng - lng1, Math.PI * -1, Math.PI);
					var x3Base = this.MathUtil.Wrap(lng3 - lng1, Math.PI * -1, Math.PI);
					xTry[0] = x3Base;
					//  Also explore wrapping of x3Base around the world in both directions.
					xTry[1] = x3Base + 2 * Math.PI;
					xTry[2] = x3Base - 2 * Math.PI;
					for (var x3 in xTry) {
						var dy = y2 - y1;
						var len2 = x2 * x2 + dy * dy;
						var t = 0;
						// TODO: Warning!!!, inline IF is not supported ?
						this.MathUtil.Clamp((x3 * x2 + (y3 - y1) * dy) / len2, 0, 1);
						var xClosest = t * x2;
						var yClosest = y1 + t * dy;
						var latClosest = this.MathUtil.InverseMercator(yClosest);
						var havDist = this.MathUtil.HavDistance(lat3, latClosest, x3 - xClosest);
						if (havDist < havTolerance) {
							return Math.max(0, idx - 1);
						}
					}
				}
				lat1 = secondLat;
				lng1 = secondLng;
				y1 = y2;
				idx++;
			}
		}
		return -1;
	};

	SinDeltaBearing = function (lat1, lng1, lat2, lng2, lat3, lng3) {
		var sinLat1 = Math.sin(lat1);
		var cosLat2 = Math.cos(lat2);
		var cosLat3 = Math.cos(lat3);
		var lat31 = lat3 - lat1;
		var lng31 = lng3 - lng1;
		var lat21 = lat2 - lat1;
		var lng21 = lng2 - lng1;
		var a = Math.sin(lng31) * cosLat3;
		var c = Math.sin(lng21) * cosLat2;
		var b = Math.sin(lat31) + 2 * (sinLat1 * (cosLat3 * this.MathUtil.Hav(lng31)));
		var d = Math.sin(lat21) + 2 * (sinLat1 * (cosLat2 * this.MathUtil.Hav(lng21)));
		var denom = (a * a + b * b) * (c * c + d * d);
		return denom <= 0 ? 1 : (a * d - b * c) / Math.sqrt(denom);
	};

	IsOnSegmentGc = function (lat1, lng1, lat2, lng2, lat3, lng3, havTolerance) {
		var havDist13 = this.MathUtil.HavDistance(lat1, lat3, lng1 - lng3);
		if (havDist13 <= havTolerance) {
			return true;
		}
		var havDist23 = this.MathUtil.HavDistance(lat2, lat3, lng2 - lng3);
		if (havDist23 <= havTolerance) {
			return true;
		}
		var sinBearing = this.SinDeltaBearing(lat1, lng1, lat2, lng2, lat3, lng3);
		var sinDist13 = this.MathUtil.SinFromHav(havDist13);
		var havCrossTrack = this.MathUtil.HavFromSin(sinDist13 * sinBearing);
		if (havCrossTrack > havTolerance) {
			return false;
		}
		var havDist12 = this.MathUtil.HavDistance(lat1, lat2, lng1 - lng2);
		var term = havDist12 + havCrossTrack * (1 - 2 * havDist12);
		if (havDist13 > term || havDist23 > term) {
			return false;
		}
		if (havDist12 < 0.74) {
			return true;
		}
		var cosCrossTrack = 1 - 2 * havCrossTrack;
		var havAlongTrack13 = (havDist13 - havCrossTrack) / cosCrossTrack;
		var havAlongTrack23 = (havDist23 - havCrossTrack) / cosCrossTrack;
		var sinSumAlongTrack = this.MathUtil.SinSumFromHav(havAlongTrack13, havAlongTrack23);
		return sinSumAlongTrack > 0;
		//  Compare with half-circle == Math.PI using sign of Math.sin().
	};
}
export default PolyUtil;

import axios, { CancelToken } from 'axios';
import { ApiResult, Result } from '../../OptimusRoutes/interfaces';
import { DownloadMutimediaDto, MultimediaDto } from './multimedia.dto';

type Service = {
	getEvents: (
		id: number,
		startDate: Date,
		endDate: Date,
		source?: CancelToken
	) => Promise<Result<ApiResult<MultimediaDto[]>>>;
	download: (args: DownloadMutimediaDto) => Promise<void>;
	getStreamFileUrl: (id: number) => Promise<Result<string>>;
	getLiveStreamUrl: (cameraId: number, channelKey: number) => Promise<Result<string>>;
	stopLiveSteam: (cameraId: number, channelKey: number) => Promise<Result>;
	getLiveStreamHealth: (cameraId: number, channelKey: number, cancelToken?: CancelToken) => Promise<boolean>;
};

const CHANNEL_HEALTHY_STATUS = 'HEALTHY';

const getEvents = async (
	id: number,
	startDate: Date,
	endDate: Date,
	source?: CancelToken
): Promise<Result<ApiResult<MultimediaDto[]>>> => {
	let options = {
		params: {
			'start-date': startDate,
			'end-date': endDate,
		},
		source,
	};

	const { data } = await axios.get<Result<ApiResult<MultimediaDto[]>>>(`/v2/multimedia/${id}/events`, options);
	return data;
};

const getStreamFileUrl = async (id: number): Promise<Result<string>> => {
	const { data } = await axios.get<Result<string>>(`/v2/multimedia/${id}/stream`);
	return data;
};

const getLiveStreamUrl = async (cameraId: number, channelKey: number): Promise<Result<string>> => {
	const { data } = await axios.get<Result<string>>(`/v2/camera/${cameraId}/channel/${channelKey}/live`);
	return data;
};

const stopLiveSteam = async (cameraId: number, channelKey: number): Promise<Result> => {
	const { data } = await axios.post<Result>(`/v2/camera/${cameraId}/channel/${channelKey}/live`);
	return data;
};

const getLiveStreamHealth = async (
	cameraId: number,
	channelKey: number,
	cancelToken?: CancelToken
): Promise<boolean> => {
	try {
		const {
			data: { success, error },
		} = await axios.get<Result<{ health: string }>>(`/v2/camera/${cameraId}/channel/${channelKey}/health`, {
			cancelToken,
		});
		if (error) {
			return false;
		}
		return success?.health === CHANNEL_HEALTHY_STATUS;
	} catch (error) {
		return false;
	}
};

const download = async ({ id, token, name }: DownloadMutimediaDto): Promise<void> => {
	const { data } = await axios.post<BlobPart>(
		`/v2/multimedia/${id}/download?token=${token}`,
		{},
		{ responseType: 'blob' }
	);
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', name);
	document.body.appendChild(link);
	link.click();
};

const MultimediaService: Service = {
	getEvents,
	download,
	getStreamFileUrl,
	getLiveStreamUrl,
	stopLiveSteam,
	getLiveStreamHealth,
};

export default MultimediaService;

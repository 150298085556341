import { MultimediaType } from '@/features/map/map.type';
import { ActionType, CleanReducerAction, IAction, LogOutAction } from '../../types/actionType';

type RequestVideoLoadingActionParams = {
	loading?: number;
	typeMedia?: MultimediaType;
};
interface RequestVideoLoadingAction extends IAction<RequestVideoLoadingActionParams | undefined> {
	type: ActionType.MULTIMEDIA_REQUEST_LOADING;
}

export type Action = RequestVideoLoadingAction | CleanReducerAction | LogOutAction;

export const RequestVideoLoading = ({
	loading,
	typeMedia,
}: RequestVideoLoadingActionParams = {}): RequestVideoLoadingAction => ({
	type: ActionType.MULTIMEDIA_REQUEST_LOADING,
	payload: {
		loading,
		typeMedia,
	},
});

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Alert, Button, Divider, message, Typography } from 'antd';
import { toDayjs } from '@/utilities/dateUtils';
import { DiagnosticActions, DiagnosticType, MessageStatus, Platform } from '../../diagnostics.dto';
import DiagnosticsService from '../../diagnostics.service';

type Props = {
	clientId: number;
	issue: string;
	lastSmsDate: string;
	suggestion: string;
	platform?: Platform;
	errorCode?: number;
	phoneNumber?: string;
	type?: DiagnosticType;
	messageStatus?: MessageStatus;
	action: { label: string; type: DiagnosticActions } | null;
};

const { Text } = Typography;

export const DiagnosticCard = ({
	clientId,
	issue,
	errorCode,
	phoneNumber = '',
	lastSmsDate,
	suggestion,
	action,
	platform = 'rastreo',
	messageStatus,
	type = 'error',
}: Props) => {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const [information, setInformation] = useState({
		issue,
		suggestion,
		action,
		type: type.toLowerCase() as DiagnosticType,
		errorCode,
		lastSmsDate,
		messageStatus,
	});

	const onClick = async () => {
		try {
			setIsLoading(true);
			switch (action?.type) {
				case 'ENABLE_PHONE_NUMBER': {
					const result = await DiagnosticsService.updatePhoneStatus({ phoneNumber, platform, clientId });

					if (result.error) {
						message.error(intl.formatMessage(translate('commonError')));
						return;
					}

					if (result.success?.enabled) {
						message.success(
							intl.formatMessage(translate('phoneNumberHasBeenEnabled'), {
								phoneNumber,
							})
						);
						setInformation({
							action: null,
							issue: 'Phone Number Enabled – Awaiting Event Trigger',
							suggestion:
								'The phone number has been successfully enabled. Please note that we must wait for the device to trigger another event in order for the notification to be sent.',
							type: 'info',
							errorCode: undefined,
							lastSmsDate: '',
							messageStatus,
						});
					}

					return;
				}
				default:
					break;
			}
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setIsLoading(false);
		}
	};

	const Action = information.action && (
		<Button type="primary" onClick={onClick} loading={isLoading}>
			{information.action?.label}
		</Button>
	);

	const PhoneNumber = phoneNumber && <Divider orientation="left">{phoneNumber}</Divider>;

	const LastMessageDate = information.lastSmsDate && (
		<Text>
			Last message was sent on
			<span className="last-message-date">
				{toDayjs(lastSmsDate).format('MMM DD, YYYY [at] hh:mm A')} - {information?.messageStatus}
			</span>
		</Text>
	);

	const ErrorCode = information.errorCode && <span className="error-code">{`(Code ${errorCode})  `}</span>;

	const Message = (
		<Text>
			{ErrorCode}
			{information.issue}
		</Text>
	);

	const Description = (
		<div className="description-container">
			<Text>{information.suggestion}</Text>
			{LastMessageDate}
		</div>
	);

	useEffect(() => {
		setInformation({
			issue,
			suggestion,
			action,
			type,
			errorCode,
			lastSmsDate,
			messageStatus,
		});
	}, [issue, suggestion, action, type, errorCode, lastSmsDate, messageStatus]);

	return (
		<div>
			{PhoneNumber}
			<Alert
				className="diagnostic-card"
				showIcon
				message={Message}
				description={Description}
				type={information.type}
				action={Action}
			/>
		</div>
	);
};

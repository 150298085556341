import { useEffect, useLayoutEffect, useRef } from 'react';
import axios from 'axios';
import { REFRESH_TOKEN_ENDPOINT } from '../constants/global';
import { cleanAuthInformation, setNewTokens } from '../features/auth/actions';
import { useAppDispatch, useAppSelector } from '../OptimusRoutes/hooks/redux.hooks';
import apiVertionInterceptor from './apiVersion.interceptor';
import errorInterceptor from './error.interceptor';
import guestInterceptor from './guest.interceptor';

type Props = {
	children: JSX.Element;
};

axios.defaults.baseURL = '/api';

const LOGOUT_ENDPOINT = '/v2/auth/logout';
const LOGIN_ENDPOINT = '/v2/auth/login';
const EXCLUDED_PATHS = [REFRESH_TOKEN_ENDPOINT, LOGIN_ENDPOINT, LOGOUT_ENDPOINT, '/Brandings'];
const MAX_RETRY = 5;

const sleep = (seconds: number) => {
	return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

const AxiosInterceptor = ({ children }: Props): JSX.Element => {
	const dispatch = useAppDispatch();
	const token = useAppSelector((state) => state.auth.token);
	const messages = useAppSelector((state) => state.language.messages);
	const refreshToken = useAppSelector((state) => state.auth.refreshToken);
	const isRenewing = useRef(false);
	const accessTokenRef = useRef('');
	const refreshTokenRef = useRef('');
	const url = process.env.REACT_APP_API_BASE_URL;

	const restoreSession = async () => {
		try {
			isRenewing.current = true;

			const response = await fetch(`${url}${REFRESH_TOKEN_ENDPOINT}`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${accessTokenRef.current}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					refreshToken: refreshTokenRef.current,
				}),
			});

			if (response.status === 401) {
				accessTokenRef.current = '';
				dispatch(cleanAuthInformation());
				return;
			}

			const data = await response.json();

			refreshTokenRef.current = data.refreshToken;

			dispatch(setNewTokens(data.accessToken, data.refreshToken, data.expiresIn, data.roles));

			return data.accessToken;
		} catch (error) {
			if (error instanceof Error && error.message.includes('401')) {
				accessTokenRef.current = '';
				dispatch(cleanAuthInformation());
			}
		} finally {
			isRenewing.current = false;
		}
	};

	useEffect(() => {
		accessTokenRef.current = token;
		refreshTokenRef.current = refreshToken;
	}, [refreshToken, token]);

	useEffect(() => {
		const requestInterceptor = axios.interceptors.request.use((config) => {
			return apiVertionInterceptor({ config, token: accessTokenRef.current });
		});

		return () => {
			axios.interceptors.request.eject(requestInterceptor);
		};
	}, []);

	useEffect(() => {
		let authinterceptor = -1;

		authinterceptor = axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				const { config } = error;

				switch (error.response.status) {
					case 401: {
						config.retryCounts = config.retryCounts ? config.retryCounts + 1 : 1;
						const isAnExcludedPath = EXCLUDED_PATHS.some((path) => config.url.includes(path));
						const canRetry = !config.retryCounts || config.retryCounts < MAX_RETRY;

						if (isAnExcludedPath || !accessTokenRef.current || !canRetry) {
							return Promise.reject();
						}

						const isSessionRestoring = isRenewing.current;

						if (isSessionRestoring && canRetry) {
							await sleep(5);
							return axios(config);
						}

						accessTokenRef.current = await restoreSession();

						apiVertionInterceptor({ config, token: accessTokenRef.current });
						return axios(config);
					}
					default:
						return Promise.reject();
				}
			}
		);

		return () => {
			if (authinterceptor > -1) {
				axios.interceptors.request.eject(authinterceptor);
			}
		};
	}, []);

	useLayoutEffect(() => {
		const guestInterceptorId = axios.interceptors.request.use(async (config) => {
			guestInterceptor({ config });
			return config;
		});
		return () => {
			if (guestInterceptorId > -1) {
				axios.interceptors.request.eject(guestInterceptorId);
			}
		};
	}, []);

	useEffect(() => {
		const errorinterceptor = axios.interceptors.response.use(
			function (response) {
				return response;
			},
			(error) => errorInterceptor(error, messages, dispatch)
		);
		return () => {
			axios.interceptors.response.eject(errorinterceptor);
		};
	}, []);

	return children;
};

export default AxiosInterceptor;

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileExcelOutlined } from '@ant-design/icons';
import { translate } from '@optimusgps/optimus-intl';
import { Button, Col, message, Row } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import ReportTable from '@/features/reports/Common/ReportTable';
import { MileagePerStateArgs } from '@/features/reports/interfaces';
import { MileagePerStateData } from '@/features/reports/mileagePerState/mileagePerState.dto';
import MileagePerStateOptions from '@/features/reports/mileagePerState/mileagePerStateOptions';
import MileagePerStateService from '@/features/reports/services/mileagePerState.service';
import unitSystem from '@/features/unitSystems';
import { YEAR_MONTH_DAY_TIME_FORMAT } from '@/OptimusRoutes/constants';
import { dateToString, dateToStringWithOutTimezone } from '@/utilities/dateUtils';

const MileagePerStateReport = () => {
	const intl = useIntl();
	const [loading, setLoading] = useState<boolean>(false);
	const [exportLoading, setExportLoading] = useState<boolean>(false);
	const source = axios.CancelToken.source();
	const [data, setData] = useState<MileagePerStateData[]>([]);
	useEffect(() => {
		return () => {
			source.cancel();
		};
	}, []);

	const onSubmit = async ({ devices, dateRange }: MileagePerStateArgs): Promise<void> => {
		try {
			setLoading(true);
			const dateRangeString = dateRange.map((date) => dateToString(date, YEAR_MONTH_DAY_TIME_FORMAT));
			const { success, error } = await MileagePerStateService.getData({
				devices,
				dateRange: dateRangeString,
				cancelToken: source.token,
			});
			if (!error && success) {
				setData(success);
			}
		} catch (error) {
			setData([]);
		} finally {
			setLoading(false);
		}
	};

	const exportMileagePerStateReport = async (): Promise<void> => {
		setExportLoading(true);
		try {
			await MileagePerStateService.export({ data });
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setExportLoading(false);
		}
	};

	const totalCount = Object.keys(_.groupBy(data, 'description')).length;

	const columns = [
		{
			Header: ' ',
			columns: [
				{
					Header: intl.formatMessage(translate('device')),
					id: 'deviceDescription',
					width: 410,
					accessor: (record: MileagePerStateData) => record.description,
				},
				{
					Header: intl.formatMessage(translate('state')),
					id: 'state',
					width: 200,
					accessor: (record: MileagePerStateData) => record.state,
					Aggregated: ' ',
				},
			],
		},
		{
			Header: intl.formatMessage(translate('date')),
			id: 'date',
			width: 95,
			accessor: (record: MileagePerStateData) =>
				dateToStringWithOutTimezone(record.startDate, unitSystem.getDateFormat()),
			Aggregated: ' ',
		},
		{
			Header: intl.formatMessage(translate('hour')),
			columns: [
				{
					Header: intl.formatMessage(translate('initial')),
					id: 'initialHour',
					width: 95,
					accessor: (record: MileagePerStateData) =>
						dateToStringWithOutTimezone(record.startDate, unitSystem.getTimeFormat()),
					Aggregated: ' ',
				},
				{
					Header: intl.formatMessage(translate('final')),
					id: 'finalHour',
					width: 95,
					accessor: (record: MileagePerStateData) =>
						dateToStringWithOutTimezone(record.endDate, unitSystem.getTimeFormat()),
					Aggregated: ' ',
				},
			],
		},
		{
			Header: intl.formatMessage(translate('distance')),
			id: 'distance',
			accessor: 'distance',
			aggregate: (vals: number[]) => _.sum(vals),
			width: 95,
			style: { textAlign: 'right' },
			Cell: ({ value }: { value: number }) =>
				unitSystem?.distance?.toString(value / 1000, {
					decimal: 2,
				}),
		},
	];

	const exportButton = data.length > 0 && !loading && (
		<Button
			loading={exportLoading}
			disabled={exportLoading}
			onClick={() => exportMileagePerStateReport()}
			icon={<FileExcelOutlined />}
		>
			<FormattedMessage {...translate('exportToExcel')} />
		</Button>
	);

	const content = (
		<Col span={24}>
			<ReportTable
				pivotBy={['deviceDescription', 'state']}
				data={data}
				totalCount={totalCount}
				columns={columns}
				loading={loading}
				className="-striped"
			/>
		</Col>
	);

	return (
		<Row className="mileage-per-state-report view">
			<Col span={24}>
				<h1 className="reportTitle">
					<FormattedMessage {...translate('mileagePerStateTitle')} />
				</h1>
			</Col>
			<Col span={24} className="mileage-per-state-report-options">
				<MileagePerStateOptions<MileagePerStateArgs>
					onSubmit={(values) => onSubmit(values)}
					loading={loading}
					exportButton={exportButton}
				/>
			</Col>
			{content}
		</Row>
	);
};

export default MileagePerStateReport;

import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { message } from 'antd';
import { removeDuplicates } from '../../utilities/util';
import { BaseService, DeleteBatchProps, EntityFilter, FilterParams, IService, OtherParams } from '../interfaces';

type UseFetchData<T, S> = {
	data: T[];
	total: number;
	search: (search?: string | S, filterParams?: OtherParams) => Promise<void>;
	deleteById: (id: number) => Promise<void>;
	deleteBatch: (deleteBatchProps: DeleteBatchProps) => Promise<void>;
	loading: boolean;
	loadData: (pagination?: Pagination) => void;
	fetchNext: () => void;
	reset: () => void;
	pagination: Pagination;
	configuredParams?: FilterParams<T>;
	updatePagination: (newPagination: Pagination) => void;
};

export type Pagination = {
	page: number;
	size: number;
};

type Props<T, Y = string | {}> = {
	service: BaseService<T> | IService<T>;
	formatSearchFilter?: (value: string | Y) => EntityFilter<T>;
	persist?: boolean;
	fetchAll?: boolean;
	pageSize?: number;
};

// TODO: If data fail when located on another page and the API returns error, the hook should return the same page where located
const useFetchData = <T, Y = string>({
	service,
	formatSearchFilter,
	persist = false,
	fetchAll = false,
	pageSize = 10,
}: Props<T, Y>): UseFetchData<T, Y> => {
	const intl = useIntl();

	const dataChunks = useRef(0);
	const count = useRef(0);

	const [data, setData] = useState<T[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [pagination, setPagination] = useState<Pagination>({ page: 1, size: pageSize });

	const [_search, setSearch] = useState<string | Y>('');
	const [configuredParams, setConfiguredParams] = useState<FilterParams<T>>();

	const loadPaginatedData = async (filter: EntityFilter<T>, newPagination?: Pagination) => {
		setLoading(true);
		const pag = newPagination || pagination;
		const response = await service.get(
			{
				skip: pag.size * (pag.page - 1),
				take: pag.size,
				filter,
			},
			{ ...configuredParams?.otherParams }
		);
		if (persist) {
			setData([...data, ...response.data]);
		} else {
			setData(response.data);
		}
		setTotal(response.count);
	};

	const loadData = useCallback(
		async (newPagination?: Pagination) => {
			try {
				setLoading(true);
				let filter = {};
				if (formatSearchFilter) {
					filter = formatSearchFilter(_search);
				}

				if (fetchAll) {
					const otherFilters = configuredParams || {};
					await fetchAllData(filter, otherFilters);
					return;
				}
				await loadPaginatedData(filter, newPagination);
			} catch (error) {
				message.error(intl.formatMessage(translate('commonError')));
			} finally {
				setLoading(false);
			}
		},
		[service, formatSearchFilter, persist]
	);

	const fetchAllData = async (filter: EntityFilter<T>, searchByOtherParams?: OtherParams) => {
		const fetchPage = async (skip: number, accumulatedData: T[]): Promise<T[]> => {
			try {
				const response = await service.get(
					{
						skip,
						take: pagination.size,
						filter,
					},
					{ ...searchByOtherParams }
				);

				dataChunks.current += response.data.length;
				count.current = response.count;

				const mergeData = [...accumulatedData, ...response.data];
				const newData = removeDuplicates(mergeData, 'id');

				const remainingData = response.count - dataChunks.current;
				const nextPageSkip = skip + pagination.size;

				if (remainingData > 0 && response.data.length > 0) {
					return fetchPage(nextPageSkip, newData);
				}

				return newData;
			} catch (e) {
				message.error(intl.formatMessage(translate('commonError')));
				return accumulatedData;
			}
		};

		setData([]);
		setLoading(true);

		const allData = await fetchPage(0, []);

		setTotal(count.current);
		setData(allData);

		count.current = 0;
		dataChunks.current = 0;
		setLoading(false);
	};

	const search = async (searchData: string | Y = _search, searchByOtherParams?: OtherParams) => {
		try {
			let filter = {};
			if (formatSearchFilter) {
				filter = formatSearchFilter(searchData);
			}

			if (fetchAll) {
				await fetchAllData(filter, searchByOtherParams);
				return;
			}

			const fetchByFilters = async () => {
				setLoading(true);
				const queryParams: FilterParams<T> = {
					filterParams: { skip: 0, take: pagination.size, filter },
					otherParams: searchByOtherParams && { ...searchByOtherParams },
				};
				setConfiguredParams(queryParams);

				const response = await service.get(queryParams.filterParams, queryParams.otherParams);
				const responseData = () => response?.data?.map((item, index) => ({ ...item, key: index }));
				setData(responseData);
				setTotal(response.count);
				setPagination({ ...pagination, page: 1 });
				setSearch(searchData);
			};

			await fetchByFilters();
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setLoading(false);
		}
	};

	const deleteById = async (id: number): Promise<void> => {
		try {
			setLoading(true);
			await (service as IService<T>).delete(id);
			setPagination({ ...pagination, page: 1 });
			loadData({ ...pagination, page: 1 });
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setLoading(false);
		}
	};

	const deleteBatch = async ({ ids, modifyAll }: DeleteBatchProps): Promise<void> => {
		try {
			setLoading(true);
			const deleteBatch = (service as IService<T>).deleteBatch;
			if (deleteBatch) {
				let filter = {};
				if (formatSearchFilter) {
					filter = formatSearchFilter(_search);
				}
				if (!modifyAll) {
					filter = { where: { id: { value: ids, op: 'in' } } };
				}

				await deleteBatch(filter);
				setPagination({ ...pagination, page: 1 });
				loadData({ ...pagination, page: 1 });
			}
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setLoading(false);
		}
	};

	const fetchNext = () => {
		if (!loading && data.length < total) {
			setPagination({ ...pagination, page: pagination.page + 1 });
		}
	};

	const reset = () => {
		setData([]);
		setTotal(0);
		setLoading(false);
		setPagination({ page: 1, size: 10 });
		loadData();
	};

	const updatePagination = (newPagination: Pagination) => {
		setPagination({ page: newPagination.page, size: newPagination.size });
		loadData({ page: newPagination.page, size: newPagination.size });
	};

	return {
		data,
		total,
		search,
		deleteById,
		deleteBatch,
		loading,
		loadData,
		pagination,
		fetchNext,
		reset,
		configuredParams,
		updatePagination,
	};
};

export default useFetchData;

import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import moment from 'moment';
import compile from 'string-template/compile';
import { MAX_NEAREST_DISTANCE, MAX_ZINDEX } from '../../constants/global';
import { GetNearestUnit } from '../../features/map/actions';
import { NearestDeviceRender, PoiContextMenu } from '../../features/map/MapButton';
import unitSystem from '../../features/unitSystems';
import icon from '../../lib/icon';

class poi {
	constructor({ map, point, config }) {
		this.selected = false;
		this.config = config;
		this.info = point;
		this.map = map;
		this.circle = undefined;
		this.mapPoint = undefined;
		this.removeListeners = undefined;
		this.selected = false;
		this.pointIsVisited = 0;
		this.inVisit = undefined;
		this.outVisit = undefined;
		this.visitByEvent = false;
		this.isIn = false;
		if (map) this.init(point);
	}

	init = (point) => {
		this.mapPoint = this.createMapPoint(point);

		if (!this.config.routeMonitor) {
			this.draw();
			this.config.eventEmmiter.addListener('endManagePoints', this.defaultEvents);
			this.config.eventEmmiter.addListener('managePoints', this.manageListeners);
			this.config.eventEmmiter.addListener('point_select', this.selectedPoints);
			this.config.eventEmmiter.addListener('drawingSelect', this.drawingSelect);
		} else {
			this.config.eventEmmiter.addListener('toggle_label', this.toggleLabel);
			this.show();
			this.toggleLabel();
		}
	};

	createMapPoint = (poi) => {
		let marker = this.config.routeMonitor
			? new MarkerWithLabel({
					position: {
						lat: poi.latitude,
						lng: poi.longitude,
					},
					options: {
						visible: true,
						optimized: false,
						clickable: true,
						zIndex: MAX_ZINDEX - 1,
						title: poi.name,
						animation: 2,
						draggable: !this.config.routeMonitor,
					},
					icon: icon.svgToIcon({
						id: `POI${poi.iconId}`,
						azimuth: 0,
						color: '#FF0000',
					}),
					labelVisible: true,
					labelContent: poi.name,
					labelAnchor: new window.google.maps.Point(0, -10),
					labelClass: 'places-label',
					labelZIndexOffset: MAX_ZINDEX - 1,
			  })
			: new window.google.maps.Marker({
					position: {
						lat: poi.latitude,
						lng: poi.longitude,
					},
					options: {
						visible: true,
						optimized: false,
						clickable: true,
						zIndex: MAX_ZINDEX - 1,
						title: poi.name,
						animation: 2,
						draggable: !this.config.routeMonitor,
					},
					icon: icon.svgToIcon({
						id: `POI${poi.iconId}`,
						azimuth: 0,
						color: poi.color,
					}),
			  });

		this.circle = new window.google.maps.Circle({
			strokeColor: '#FF0000',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: '#FF0000',
			fillOpacity: 0.35,
			center: {
				lat: poi.latitude,
				lng: poi.longitude,
			},
			radius: poi.radio,
		});

		if (!this.config.routeMonitor) {
			this.defaultEvents(marker);
		}
		return marker;
	};

	addListener = (listenerArray, cb = () => {}) => {
		if (this.removeListeners) {
			this.removeListeners();
		}
		this.removeListeners = () => {
			listenerArray.forEach((listener) => {
				window.google.maps.event.removeListener(listener);
			});
			cb();
		};
	};

	defaultEvents = (marker = this.mapPoint) => {
		marker.setOpacity(1);
		this.addListener(
			[
				marker.addListener('rightclick', (event) => {
					let contextMenu = document.getElementById('contextMenu');
					let menuGrid = [
						{
							key: 'edit',
							icon: 'icon-pencil',
							action: () => {
								this.config.eventEmmiter.emitEvent('edit_pointOfInterest_modal', [this.info, event]);
								this.hideContextMenu();
							},
						},
						{
							key: 'selectPoi',
							icon: 'icon-earth',
							action: () => {
								this.hideContextMenu();
								this.config.eventEmmiter.emitEvent('geoDrawing', []);
							},
						},
						{
							key: 'nearestTitle',
							icon: 'icon-drivers',
							action: () => {
								const nearestdevices = document.getElementById('nearestDevices');
								const { lat, lng } = event.latLng;
								const title = this.config.messages?.nearestTitle;

								let feedbackCompiler = compile(this.config.messages?.noDevicesFound);
								nearestdevices.classList.add('show');
								NearestDeviceRender({
									GetDevices: GetNearestUnit,
									nearestdevices,
									latitude: lat(),
									longitude: lng(),
									eventEmmiter: this.config.eventEmmiter,
									title,
									feedback: feedbackCompiler({
										radio: unitSystem.distance.toString(MAX_NEAREST_DISTANCE, { decimal: 0 }),
									}),
								});
								this.hideContextMenu();
							},
						},
						{
							key: 'close',
							icon: 'icon-close',
							action: () => this.hideContextMenu(),
						},
					];
					PoiContextMenu(contextMenu, menuGrid, this.config.messages);
					this.setContextMenuPosition(contextMenu, event);
				}),
				marker.addListener('dragend', (event) => {
					this.hideContextMenu();
					this.config.eventEmmiter.emitEvent('edit_pointOfInterest_modal', [this.info, event]);
				}),
				marker.addListener('click', () => {
					this.hideContextMenu();
					marker.setOpacity(1);
					this.config.eventEmmiter.emitEvent('managePoints', [this.info.id, false]);
				}),
			],
			() => {
				this.hideContextMenu();
			}
		);

		return marker;
	};

	drawingSelect = (response) => {
		let opacity = this.mapPoint.getOpacity();
		if (opacity === 1) return;
		if (response(this.info.latitude, this.info.longitude)) {
			this.config.eventEmmiter.emitEvent('point_select', [this.info.id, false]);
		}
	};

	selectedPoints = (pointId, remove = false) => {
		if (pointId === this.info.id) {
			if (!remove) {
				this.selected = true;
				this.mapPoint.setOpacity(1);
			} else {
				this.selected = false;
				this.mapPoint.setOpacity(0.3);
			}
		}
	};

	manageListeners = (id) => {
		if (id !== this.info.id) {
			this.hideContextMenu();
			this.mapPoint.setOpacity(0.3);
		}

		this.addListener(
			[
				this.mapPoint.addListener('rightclick', (event) => {
					this.hideContextMenu();
					let contextMenu = document.getElementById('contextMenu');

					let menuGrid = [
						{
							key: 'edit',
							icon: 'icon-pencil',
							action: () => {
								this.config.eventEmmiter.emitEvent('edit_pointOfInterest_modal', [this.info, event]);
								this.hideContextMenu();
							},
						},
						{
							key: 'tag',
							icon: 'icon-price-tag',
							action: () => {
								this.config.eventEmmiter.emitEvent('tag_modal', []);
								this.hideContextMenu();
							},
						},
						{
							key: 'selectPoi',
							icon: 'icon-earth',
							action: () => {
								this.hideContextMenu();
								this.config.eventEmmiter.emitEvent('geoDrawing', []);
							},
						},
						{
							key: 'nearestTitle',
							icon: 'icon-drivers',
							action: () => {
								const nearestdevices = document.getElementById('nearestDevices');
								const { lat, lng } = event.latLng;
								const title = this.config.messages?.nearestTitle;

								let feedbackCompiler = compile(this.config.messages?.noDevicesFound);
								nearestdevices.classList.add('show');
								NearestDeviceRender({
									GetDevices: GetNearestUnit,
									nearestdevices,
									latitude: lat(),
									longitude: lng(),
									eventEmmiter: this.config.eventEmmiter,
									title,
									feedback: feedbackCompiler({
										radio: unitSystem.distance.toString(MAX_NEAREST_DISTANCE, { decimal: 0 }),
									}),
								});
								this.hideContextMenu();
							},
						},
						{
							key: 'close',
							icon: 'icon-close',
							action: () => this.hideContextMenu(),
						},
					];

					PoiContextMenu(contextMenu, menuGrid, this.config.messages);
					this.setContextMenuPosition(contextMenu, event);
				}),
				this.mapPoint.addListener('dragend', (event) => {
					this.hideContextMenu();
					this.config.eventEmmiter.emitEvent('edit_pointOfInterest_modal', [this.info, event]);
				}),
				this.mapPoint.addListener('click', () => {
					this.hideContextMenu();
					let opacity = this.setOpacity(this.mapPoint);
					this.config.eventEmmiter.emitEvent('point_select', [this.info.id, opacity === 1]);
					this.config.eventEmmiter.emitEvent('last_select', []);
				}),
			],
			() => {
				this.mapPoint.setOpacity(1);
				this.hideContextMenu();
			}
		);
	};

	setContextMenuPosition = (contextMenu, event) => {
		contextMenu.style.left = `${event.domEvent.x - 12}px`;
		contextMenu.style.top = `${event.domEvent.y - 60}px`;
		contextMenu.style.display = 'block';
	};

	setOpacity = (marker = this.mapPoint) => {
		let opacity = marker.getOpacity();
		marker.setOpacity(opacity < 1 ? 1 : 0.3);

		return opacity;
	};

	hideContextMenu = () => {
		let contextMenu = document.getElementById('contextMenu');
		if (contextMenu) contextMenu.style.display = 'none';
	};

	draw = () => {
		this.setMapPointOptions({
			position: {
				lat: this.info.latitude,
				lng: this.info.longitude,
			},
			icon: icon.svgToIcon({
				id: `POI${this.info.iconId}`,
				azimuth: 0,
				color: this.info.color,
			}),
			visible: true,
			title: this.info.name,
		});
	};

	setMapPointOptions = (options, poi = this.info) => {
		this.mapPoint.setOptions({
			...options,
		});
		this.info = poi;
	};

	toggleLabel = () => {
		const showTags = this.mapPoint.get('labelClass')?.split(' ').includes('hideLabel');
		this.mapPoint.set('labelClass', `places-label ${!showTags ? 'hideLabel' : ''}`);
	};

	hide = () => {
		this.setMapPointOptions({
			map: null,
		});
		if (this.config.routeMonitor) {
			this.circle.setOptions({
				map: null,
			});
		}
	};

	hidePlace = () => {
		this.circle.setOptions({
			map: null,
		});
	};

	show = () => {
		this.setMapPointOptions({
			map: this.map,
		});
		if (this.config.routeMonitor) {
			this.circle.setOptions({
				map: this.map,
			});
			this.mapPoint.set('labelClass', 'places-label');
		}
	};
	showPoiWithPlace = () => {
		this.setMapPointOptions({ map: this.map });
		this.circle.setOptions({ map: this.map });
		this.mapPoint.set('labelClass', 'places-label');
	};

	updateMarker = (poi) => {
		this.setMapPointOptions(
			{
				map: this.map,
			},
			poi
		);
		this.draw();
	};

	updateCircle = (color) => {
		this.circle.setOptions({
			strokeColor: color,
			fillColor: color,
		});
		this.setMapPointOptions(
			{
				icon: icon.svgToIcon({
					id: `POI${this.info.iconId}`,
					azimuth: 0,
					color,
				}),
			},
			{
				...this.info,
				color,
			}
		);
	};

	updateVisits = (trace) => {
		this.analizeVisit(trace);
	};

	isPointInRadios = (point) =>
		window.google.maps.geometry.spherical.computeDistanceBetween(
			new window.google.maps.LatLng(point.latitude, point.longitude),
			this.mapPoint.getPosition()
		) <= this.info.radio;

	analizeVisit = (trace) => {
		this.isIn = this.isPointInRadios(trace);

		if (this.isIn && !this.visitByEvent) {
			//si esta dentro del punto y no se detecto evento
			if (trace.events.includes(2) || trace.events.includes(9) || trace.events.includes(46)) {
				// si hay un evento 2, 9, 46 detecta visita inmediata
				//sumar visitas
				if (this.pointIsVisited < 1) this.config.eventEmmiter.emitEvent('point_is_visited', [this.info.id]);
				this.pointIsVisited += 1;
				// reset flags
				this.inVisit = undefined;
				this.outVisit = undefined;
				this.visitByEvent = true;
			} else if (!this.inVisit) {
				// si esta adentro pero es el primer punto adentro
				this.inVisit = trace;
			}
		} else if (!this.isIn && this.visitByEvent) {
			//si no esta adentro del punto y tiene la flag de visita por evento reiniciar la flag
			this.visitByEvent = false;
		} else if (this.inVisit) {
			// si no esta adentro y tenia una visita
			this.outVisit = trace;
		}

		if (this.inVisit && this.outVisit) {
			// si despues de una entrada hay una salida

			let duration = moment.utc(this.outVisit.utcDate).diff(moment.utc(this.inVisit.utcDate), 'seconds');
			if (duration >= this.info.duration) {
				if (this.pointIsVisited < 1) this.config.eventEmmiter.emitEvent('point_is_visited', [this.info.id]);
				this.pointIsVisited++;
			}
			this.inVisit = undefined;
			this.outVisit = undefined;
			this.visitByEvent = false;
		}

		if (this.pointIsVisited === 1) this.updateCircle('#1890ff');
		else if (this.pointIsVisited > 1) this.updateCircle('#fadb14');
		else {
			this.updateCircle('#FF0000');
		}
	};
}

export default poi;

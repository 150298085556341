import axios from 'axios';
import qs from 'qs';
import { DeviceModelProfileDto } from '@/features/modelProfile/modelProfile.dto';
import { ApiResult, Filter, Result } from '../../OptimusRoutes/interfaces';

type Service = {
	getDeviceModels: (filter: Filter<DeviceModelProfileDto>) => Promise<ApiResult<DeviceModelProfileDto[]>>;
};

const getDeviceModels = async ({
	skip,
	take,
	filter = {},
}: Filter<DeviceModelProfileDto>): Promise<ApiResult<DeviceModelProfileDto[]>> => {
	const params = { skip, take, filter: JSON.stringify(filter) };
	const { data } = await axios.get<Result<ApiResult<DeviceModelProfileDto[]>>>(
		`/v2/model-profile/device-models?${qs.stringify(params)}`
	);
	if (!data.error && data.success) {
		return data.success;
	}
	return {
		count: 0,
		data: [],
	};
};

const ModelProfileService: Service = {
	getDeviceModels,
};

export default ModelProfileService;

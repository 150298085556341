import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Form, message } from 'antd';
import { DiagnosticType, PhoneStatusDiagnosticsDto } from '../diagnostics.dto';
import DiagnosticsService from '../diagnostics.service';
import { ClientInformationCard, DiagnosticCard, SmsForm } from './components';

type Diagnostic = {
	clientId: number;
	name: string;
	clientInfo: { label: string; value: string }[];
	data: PhoneStatusDiagnosticsDto[];
};

export const SmsDiagnosticsPage = () => {
	const intl = useIntl();
	const [form] = Form.useForm();

	const [loadingDiagnostics, setLoadingDiagnostics] = useState(false);
	const [diagnostics, setDiagnostics] = useState<Diagnostic>({
		clientId: 0,
		name: '',
		clientInfo: [],
		data: [],
	});

	const onSubmit = async () => {
		try {
			setLoadingDiagnostics(true);
			await form.validateFields();
			const values = form.getFieldsValue();
			const result = await DiagnosticsService.getPhoneStatus(values);

			if (result.error) {
				message.error(intl.formatMessage(translate('commonError')));
				return;
			}

			let clientInfo = [
				{
					key: 1,
					label: intl.formatMessage(translate('email')),
					value: values.email,
				},
				{
					key: 2,
					label: intl.formatMessage(translate('platform')),
					value: result.success?.platform === 'optimus' ? 'Optimus Tracking Pro' : 'Optimus Tracking',
				},
			];

			if (result.success?.platform === 'rastreo') {
				clientInfo.push({
					key: 3,
					label: intl.formatMessage(translate('migrated')),
					value: result.success?.isMigrated.toString(),
				});
			}

			setDiagnostics({
				clientId: result.success?.clientId || 0,
				name: result.success?.name || '',
				clientInfo,
				data: result.success?.phoneStatusDiagnostics || [],
			});
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setLoadingDiagnostics(false);
		}
	};

	const ClientInformation = diagnostics.name && (
		<ClientInformationCard title={diagnostics.name} items={diagnostics.clientInfo} />
	);

	const DiagnosticsResult = useMemo(
		() =>
			diagnostics.data.length > 0 && (
				<>
					{ClientInformation}
					{diagnostics?.data.map((diagnostic, index) => (
						<DiagnosticCard
							key={index}
							clientId={diagnostics.clientId}
							phoneNumber={diagnostic.phoneNumber}
							errorCode={diagnostic.errorCode}
							issue={diagnostic.issue}
							lastSmsDate={diagnostic.messageSentAt?.toString() || ''}
							suggestion={diagnostic.suggestion || ''}
							type={diagnostic.diagnosticType.toLowerCase() as DiagnosticType}
							platform={diagnostic.platform}
							messageStatus={diagnostic.messageStatus}
							action={
								diagnostic.action
									? {
											label: intl.formatMessage(translate('enablePhoneNumber')),
											type: diagnostic.action,
									  }
									: null
							}
						/>
					))}
				</>
			),
		[diagnostics]
	);
	return (
		<div className="page-container">
			<div className="diagnostics">
				<div className="smsDiagnostics">
					<SmsForm form={form} onSubmit={onSubmit} isLoading={loadingDiagnostics} />
					{DiagnosticsResult}
				</div>
			</div>
		</div>
	);
};

import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import _ from 'lodash';
import moment from 'moment';
import { selectedMapTrail } from '../../constants/global';
import { PoiContextMenu } from '../../features/map/MapButton';
import deviceInstance from './devices';
import places from './places';
import poiInstance from './poi';

class mapIntance {
	constructor(map, devices, options) {
		this.map = map;
		this.devices = {};
		this.poi = [];
		this.line = {};
		this.config = options;
		this.markerCluster = undefined;
		this.devicesCluster = undefined;
		this.drawingtool = undefined;
		this.directionsRenderer = undefined;
		this.isManaging = false;
		this.selectedDevice = 0;
		this.eventsListeners = {};
		this.filteredDevices = _.cloneDeepWith(devices).map((dev) => dev.id);
		this.eventEmmiterGeneralConfig();
		if (devices.length > 0) {
			this.init(devices);
			this.eventEmmiterInit();
			this.initDrawingtool();
		}
	}

	setConfigurations = (newConfig) => {
		this.config = {
			...this.config,
			...newConfig,
		};
	};

	eventEmmiterInit = () => {
		this.config.eventEmmiter.addListener('selectDevice', this.selectDevice);
		this.config.eventEmmiter.addListener('tag_select_from_table', this.selectPointOfInterest);
		this.config.eventEmmiter.addListener('endDrawingPoints', this.stopManagingPois);
		this.config.eventEmmiter.addListener('managePoints', this.isManagingPois);
		this.config.eventEmmiter.addListener('geoDrawing', this.startDrawing);
	};

	eventEmmiterGeneralConfig = () => {
		this.config.eventEmmiter.addListener('update_cluster_devices', this.updateClusterDevices);
		this.config.eventEmmiter.addListener('update_device_colors', this.updateDeviceColors);
		this.config.eventEmmiter.addListener('update_trace_color', this.updateTraceColor);
		this.config.eventEmmiter.addListener('center_on_select', this.centerOnSelect);
		this.config.eventEmmiter.addListener('center_on_update', this.centerOnUpdate);
		this.config.eventEmmiter.addListener('show_trace', this.showTrace);
		this.config.eventEmmiter.addListener('show_trace_by_speed', this.showConfigurationTraceBySpeed);
		this.config.eventEmmiter.addListener('places_poi', this.placesPoi);
	};

	loadInstance = (map, config) => {
		this.map = map;
		this.setConfigurations(config);
		Object.keys(this.devices).forEach((deviceId) => {
			let visible = !this.selectedDevice || this.selectedDevice === deviceId;
			this.devices[deviceId].load(
				map,
				{},
				{
					...this.config,
					visible: visible,
					showLabel: visible,
				}
			);
		});

		this.filteredDevices = Object.keys(this.devices).map((deviceId) => Number(deviceId));
		this.centerMap();
		if (this.config.clusterDevices) this.clusterDevices(map);
		if (
			this.config.selectfirst &&
			Object.keys(this.devices).length === 1 &&
			!this.devices[this.filteredDevices[0]].cellTower
		) {
			this.selectDevice(this.filteredDevices[0], moment().format('YYYY-MM-DD'), this.filteredDevices);
			this.config.eventEmmiter.emitEvent('deviceSelected', [this.filteredDevices[0]]);
		}
		if (this.selectedDevice) {
			this.selectDevice(this.selectedDevice);
		}
	};

	init = (devices) => {
		devices.forEach((device) => {
			this.devices[device.id] = new deviceInstance(device, this.map, {
				...this.config,
				visible: true,
				showLabel: !(this.config.selectfirst && devices.length === 1),
				selected: this.config.selectfirst && devices.length === 1,
				setDeviceListeners: this.setDeviceListeners,
				infoWindowInstance: this.infoWindowInstance,
				centerMap: this.centerMap,
			});
			this.setDeviceListeners(device.id);
		});
		this.centerMap();
		if (this.config.clusterDevices) this.clusterDevices(this.map);
		if (this.config.selectfirst && devices.length === 1 && !this.devices[this.filteredDevices[0]].cellTower) {
			this.selectDevice(this.filteredDevices[0], moment().format('YYYY-MM-DD'), this.filteredDevices);
			this.config.eventEmmiter.emitEvent('deviceSelected', [this.filteredDevices[0]]);
			if (this.config.mapName === 'main') {
				this.config.SelectDevice(this.filteredDevices[0]);
			}
		} else {
			if (this.config.SelectDevice) {
				this.config.SelectDevice(0);
			} else {
				this.config.eventEmmiter.emitEvent('selectDevice', [
					0,
					moment.utc().format('YYYY-MM-DD'),
					this.filteredDevices,
				]);
			}
		}
	};

	clusterDevices = (map) => {
		let markers = Object.keys(this.devices)
			.map((device) => this.devices[device].mapPoint)
			.filter((device) => !!device);
		this.devicesCluster = new MarkerClusterer({
			map,
			markers,
			algorithm: new SuperClusterAlgorithm({ maxZoom: 15 }),
		});
	};
	centerMap = () => {
		let bounds = new window.google.maps.LatLngBounds();

		if (this.selectedDevice > 0 && this.devices[this.selectedDevice].mapPoint) {
			this.map.panTo(this.devices[this.selectedDevice].mapPoint.getPosition());
		} else if (this.filteredDevices.length < 1) {
			bounds.extend(new window.google.maps.LatLng(40.758896, -73.98513));
			this.map.fitBounds(bounds);
			this.map.setZoom(12);
		} else {
			Object.keys(this.devices).forEach((key) => {
				if (this.devices[key].mapPoint != null && this.filteredDevices.includes(Number(key)))
					bounds.extend(this.devices[key].mapPoint.getPosition());

				if (this.devices[key]?.cellTower) bounds.union(this.devices[key]?.cellTower?.getBounds());
			});
			this.map.fitBounds(bounds);
		}
	};

	infoWindowInstance = (content, position, options) => {
		if (!window.infowindow) {
			window.infowindow = new window.google.maps.InfoWindow();
			window.infowindow.setContent(content);
			window.infowindow.setPosition(position);
		} else {
			window.infowindow.setContent(content);
			window.infowindow.setPosition(position);
		}

		if (options) window.infowindow.setOptions(options);

		window.infowindow.open(this.map);
	};

	centerOnPoint = (event, point) => {
		let bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(point.latitude, point.longitude));
		this.map.fitBounds(bounds);
		this.map.setZoom(18);
		this.cleanAllPoiPlace();
		this.drawPointPlace(point?.id);
		event?.preventDefault();
	};

	drawPointPlace = (pointId) => {
		if (this.poi && this.poi[pointId]) this.poi[pointId].showPoiWithPlace();
	};

	cleanAllPoiPlace = () => {
		Object.keys(this.poi).forEach((poiId) => {
			this.cleanPoiPlace(parseInt(poiId));
		});
	};

	cleanPoiPlace = (pointId) => {
		if (!this.poi[pointId].selected) this.poi[pointId].hidePlace();
	};

	centerMapOnDevice = (event, id) => {
		if (id in this.devices && this.selectedDevice === 0) {
			this.devices[id].centerOnMe();
		}
		event.preventDefault();
	};

	addInfoWindowClickEvent = (id) => {
		this.devices[id].mapPoint.addListener('click', () => {
			this.devices[id].showInfowindow({
				...this.devices[id].original,
				lat: this.devices[id].original.latitude,
				lng: this.devices[id].original.longitude,
			});
		});
	};

	addSelectDeviceClickEvent = (id) => {
		if (this.devices[id].mapPoint == null) return;
		const validateOnClick = () => {
			if (this.config.multipleMap || id === this.selectedDevice) {
				this.devices[id].showInfowindow({
					...this.devices[id].original,
					lat: this.devices[id].original.latitude,
					lng: this.devices[id].original.longitude,
					isDevice: true,
				});
			} else {
				if (this.config.SelectDevice) {
					this.config.SelectDevice(id);
				} else {
					this.config.eventEmmiter.emitEvent('deviceSelected', [id]);
				}
				this.selectDevice(id, moment().format('YYYY-MM-DD'), this.filteredDevices);
			}
		};

		this.devices[id].mapPoint.addListener('click', validateOnClick);
		window.google.maps.event.addListener(this.devices[id].mapPoint, 'click', validateOnClick);
	};

	updateEventsForAlert = (updateData) => {
		if (this.selectedDevice > 0) {
			this.devices[this.selectedDevice].updateEvent(updateData);
		}
	};

	setDeviceListeners = (id) => {
		/*
		si agregamos otra funcion se 
		debera chequiar a este nivel
		si el mapPoint es diferente de null
		*/
		this.addSelectDeviceClickEvent(id);
	};

	updateTraceColor = (color) => {
		this.setConfigurations({
			traceColor: color,
		});
		Object.keys(this.devices).forEach((key) => {
			if (key in this.devices) {
				this.devices[key].setConfiguration({
					traceColor: color,
				});
			}
		});
		if (this.selectedDevice > 0) {
			this.devices[this.selectedDevice].updateTraces();
		}
	};

	showTrace = (show) => {
		this.setConfigurations({
			showTrace: show,
		});
		Object.keys(this.devices).forEach((key) => {
			if (key in this.devices) {
				this.devices[key].setConfiguration({
					showTrace: show,
				});
			}
		});
		if (this.selectedDevice > 0) {
			this.devices[this.selectedDevice].updateTraces();
		}
	};

	updateClusterDevices = (clusterDevices) => {
		this.setConfigurations({
			clusterDevices,
		});
		Object.keys(this.devices).forEach((key) => {
			if (key in this.devices) {
				this.devices[key].setConfiguration({
					clusterDevices,
				});
			}
		});

		if (clusterDevices) {
			this.clusterDevices(this.map);
		} else {
			this.devicesCluster.clearMarkers();
			this.devicesCluster = undefined;
			this.ShowAllMarkers();
		}
	};

	updateDeviceColors = (colorConfig) => {
		this.setConfigurations({
			...colorConfig,
		});
		Object.keys(this.devices).forEach((key) => {
			if (key in this.devices) {
				this.devices[key].setConfiguration({
					...colorConfig,
				});
				this.devices[key].updateDeviceColor();
			}
		});
	};

	centerOnSelect = (center) => {
		this.setConfigurations({
			centerOnSelect: center,
		});
		Object.keys(this.devices).forEach((key) => {
			if (key in this.devices) {
				this.devices[key].setConfiguration({
					centerOnSelect: center,
				});
			}
		});
	};

	centerOnUpdate = (center) => {
		this.setConfigurations({
			centerOnUpdate: center,
		});
		Object.keys(this.devices).forEach((key) => {
			if (key in this.devices) {
				this.devices[key].setConfiguration({
					centerOnUpdate: center,
				});
			}
		});
	};

	showConfigurationTraceBySpeed = (newConfig) => {
		this.setConfigurations({
			speedRange: {
				...this.config.speedRange,
				...newConfig,
			},
		});
		Object.keys(this.devices).forEach((key) => {
			if (key in this.devices) {
				this.devices[key].setConfiguration({
					speedRange: {
						...this.config.speedRange,
						...newConfig,
					},
				});
			}
		});
		if (this.selectedDevice > 0) {
			this.devices[this.selectedDevice].updateColorRangeTraces();
		}
	};

	HideAllMarkers = (id = 0) => {
		Object.keys(this.devices).forEach((key) => {
			if (key !== id && key in this.devices) {
				this.devices[key].setConfiguration({
					pastDate: false,
					selected: false,
				});
				this.devices[key].hideMarker();
				if (this.devicesCluster && this.devices[key].mapPoint)
					this.devicesCluster.removeMarker(this.devices[key].mapPoint);
			}
		});
	};

	ShowAllMarkers = () => {
		this.filteredDevices.forEach((key) => {
			if (key in this.devices) {
				this.devices[key].setConfiguration({
					pastDate: false,
				});
				this.devices[key].showMarker(this.map);
				if (this.devicesCluster && this.devices[key].mapPoint)
					this.devicesCluster.addMarker(this.devices[key].mapPoint);
			}
		});
	};

	selectDevice = (id = 0, date, devices = this.filteredDevices) => {
		let deviceId = Number(id);
		if (deviceId > 0) {
			if (!date) {
				date = this.devices[deviceId].original.utcDate;
			}
			this.HideAllMarkers(deviceId);
			if (this.config.selectedTrail === selectedMapTrail.LAST) {
				if (this.selectedDevice !== 0) {
					this.devices[this.selectedDevice].hideTrace();
				}
				this.devices[deviceId].showMarker(this.map);
				this.devices[deviceId].selectDetailedHistory(date);
			} else {
				let device = this.devices[deviceId];
				device.select(this.map, date);
			}
			this.selectedDevice = deviceId;
		} else {
			this.HideAllMarkers(0);
			this.filteredDevices = devices;
			this.selectedDevice = 0;
			this.ShowAllMarkers();
		}

		if (this.config.centerOnSelect) this.centerMap();
	};

	getMapCurrentPosition = () => ({
		bounds: this.map.getBounds(),
		zoom: this.map.getZoom(),
	});

	restoreMapCurrentPosition = ({ bounds, zoom }) => {
		this.map.fitBounds(bounds);
		this.map.setZoom(zoom);
	};

	filterDevices = (devices) => {
		this.selectDevice(0, moment().format('YYYY-MM-DD'), devices);
		this.centerMap();
	};

	detailedHistory = (start, end) => {
		if (!start || !end) {
			start = moment
				.utc(this.devices[this.selectedDevice].original.utcDate)
				.startOf('days')
				.format('YYYY-MM-DD HH:mm:ss');
			end = moment
				.utc(this.devices[this.selectedDevice].original.utcDate)
				.endOf('days')
				.format('YYYY-MM-DD HH:mm:ss');
		}
		return this.devices[this.selectedDevice].detailedHistory(start, end);
	};

	updateTrace = (points) => {
		return this.devices[this.selectedDevice].UpdateTrace(points);
	};

	hideContextMenu = () => {
		let contextMenu = document.getElementById('contextMenu');
		if (contextMenu) contextMenu.style.display = 'none';
	};

	hideInputWrapper = () => {
		let inputWrapper = document.getElementById('inputWrapper');
		if (inputWrapper) inputWrapper.style.display = 'none';
	};

	showInputWrapper = () => {
		let inputWrapper = document.getElementById('inputWrapper');
		if (inputWrapper) inputWrapper.style.display = 'block';
	};

	loadLineOfInterest = (lines, mapName) => {
		lines.map((item) => {
			item = { ...item, encodedObject: item.placeEncodedObject };
			this.line[item.id] = new places(mapName);
			return this.line[item.id].addLineOfInterest(item.id, item);
		});
	};
	centerPlace = (line) => {
		this.line[line.id].centerPlace(line);
	};

	updateLineInterest = (line) => {
		if (!this.line[line.id]) return this.line[line.id].addLineOfInterest(line.id, line);
		this.line[line.id].removeAllPlace();
		return this.line[line.id].addLineOfInterest(line.id, line);
	};
	addLineOfInterest = (item, mapName) => {
		item = { ...item, encodedObject: item.placeEncodedObject };
		this.line[item.id] = new places(mapName);
		return this.line[item.id].addLineOfInterest(item.id, item);
	};

	removeAllLineOfInterest = () => {
		Object.keys(this.line).forEach((lineKey) => {
			this.line[lineKey].removeAllPlace();
		});
	};
	removeLineOfInterestById = (ids) => {
		ids.forEach((id) => {
			this.line[id].removeAllPlace();
		});
	};
	loadPointsOfInterest = (points, cxtMenu = false) => {
		let markers = points.map((point) => {
			if (point && this.poi[point.id]) this.poi[point.id].hidePlace();
			this.poi[point.id] = new poiInstance({
				map: this.map,
				point,
				config: this.config,
			});
			return this.poi[point.id].mapPoint;
		});
		this.markerCluster = new MarkerClusterer({
			map: this.map,
			markers,
			algorithm: new SuperClusterAlgorithm({ maxZoom: 15 }),
		});
		if (cxtMenu) this.contextMenuInit();

		this.showInputWrapper();
		this.eventsListeners['inputWrapper'] = {
			remove: () => {
				this.hideInputWrapper();
			},
		};
	};

	contextMenuInit = () => {
		let contextMenuFunc = (event) => {
			event.preventDefault();
		};

		let menuGrid = [
			{
				key: 'tag',
				icon: 'icon-price-tag',
				action: () => {
					this.hideContextMenu();
					this.config.eventEmmiter.emitEvent('tag_modal', []);
				},
			},
			{
				key: 'selectPoi',
				icon: 'icon-earth',
				action: () => {
					this.hideContextMenu();
					this.startDrawing();
				},
			},
		];

		document.body.addEventListener('click', this.hideContextMenu);
		window.addEventListener('contextmenu', contextMenuFunc);

		this.eventsListeners['window'] = {
			remove: () => {
				window.removeEventListener('contextmenu', contextMenuFunc);
				document.body.removeEventListener('click', this.hideContextMenu);
			},
		};

		let listener = this.map.addListener('rightclick', (event) => {
			this.hideContextMenu();
			event.preventDefault();
			event.stopPropagation();
			PoiContextMenu(document.getElementById('contextMenu'), menuGrid, this.config.messages);
			let contextMenu = document.getElementById('contextMenu');
			contextMenu.style.left = `${event.pixel.x}px`;
			contextMenu.style.top = `${event.pixel.y}px`;
			contextMenu.style.display = 'block';
		});

		this.eventsListeners['contextMenu'] = {
			remove: () => {
				this.hideContextMenu();
				window.google.maps.event.removeListener(listener);
			},
		};
	};

	startDrawing = () => {
		this.showDrawingTool();
		this.addDrawingtoolListener();
	};

	addPointOfInterest = (point) => {
		if (!this.markerCluster) return;
		this.poi[point.id] = new poiInstance({
			map: this.map,
			point,
			config: this.config,
		});

		this.markerCluster.addMarker(this.poi[point.id].mapPoint);
	};

	hideAllPointsOfInterest = () => {
		if ('contextMenu' in this.eventsListeners) this.eventsListeners['contextMenu'].remove();

		if ('window' in this.eventsListeners) this.eventsListeners['window'].remove();

		if ('inputWrapper' in this.eventsListeners) this.eventsListeners['inputWrapper'].remove();

		if ('dblclick' in this.eventsListeners) this.eventsListeners['dblclick'].remove();

		if (this.markerCluster) {
			this.markerCluster.clearMarkers();
			this.centerMap();
		}
	};

	showAllPointsOfInterest = () => {
		if (this.markerCluster) {
			this.markerCluster.setMap(this.map);
			//TODO: this is not supported by the new library
			// if (this.markerCluster.getTotalMarkers() > 0) {
			// 	this.markerCluster.fitMapToMarkers();
			// } else {
			this.centerMap();
			//}
		}
	};

	selectPointOfInterest = (pointId) => {
		if (this.poi[pointId]) {
			this.poi[pointId].setOpacity();
		}
	};

	updatePointOfInterest = (point) => {
		this.poi[point.id].updateMarker(point);
	};

	deletePointOfInterest = (points) => {
		let filteredPoints = this.poi.filter((point) => {
			if (points.indexOf(point.info.id) >= 0) {
				this.markerCluster.removeMarker(this.poi[point.info.id].mapPoint);
				this.poi[point.info.id].hide();
				return false;
			}
			return true;
		});

		this.poi = _.transform(
			filteredPoints,
			function (result, value) {
				result[value.info.id] = value;
			},
			{}
		);
	};

	filterPointOfInterest = (pointsId = [], tagCount = 0, points = []) => {
		// tagCount will make a diference when filtering points on map,
		// only when no tags selected and no points, then will show all points.

		if (!this.markerCluster && points.length) return this.loadPointsOfInterest(points);
		if (!this.markerCluster) return;
		if (pointsId.length === 0 && tagCount === 0) {
			this.markerCluster.clearMarkers();
			this.markerCluster.addMarkers(this.poi.map((point) => point.mapPoint));
		} else if (points.length) {
			this.markerCluster.clearMarkers();
			this.loadPointsOfInterest(points);
			//return this.markerCluster.fitMapToMarkers();
			return;
		} else {
			this.markerCluster.removeMarkers(
				this.poi.filter((point) => pointsId.indexOf(point.info.id) < 0).map((point) => point.mapPoint)
			);
		}
	};

	filterLineOfInterest = (points = []) => {
		// tagCount will make a diference when filtering points on map,
		// only when no tags selected and no points, then will show all points.
		if (points.length) {
			this.removeAllLineOfInterest();
			this.loadLineOfInterest(points, 'main');
		} else if (this.line) {
			this.removeAllLineOfInterest();
		}
	};

	initDrawingtool = () => {
		this.drawingtool = new window.google.maps.drawing.DrawingManager({
			drawingMode: 'polygon',
			drawingControl: true,
			drawingControlOptions: {
				position: 2,
				drawingModes: ['polygon'],
			},
		});
	};

	showDrawingTool = () => {
		if (!this.isManaging) this.config.eventEmmiter.emitEvent('managePoints', []);

		if (this.drawingtool) {
			this.drawingtool.setMap(this.map);
		}
	};

	hideDrawingTool = () => {
		if (this.drawingtool) {
			this.drawingtool.setMap(null);
		}
	};

	stopManagingPois = () => {
		this.isManaging = false;
		this.hideDrawingTool();
		this.cleanAllPoiPlace();
	};

	isManagingPois = () => {
		this.isManaging = true;
	};

	addDrawingtoolListener = () => {
		let self = this;
		window.google.maps.event.addListener(this.drawingtool, 'overlaycomplete', function (event) {
			let response = (latitude, longitude) =>
				window.google.maps.geometry.poly.containsLocation(
					new window.google.maps.LatLng(latitude, longitude),
					event.overlay
				);

			self.config.eventEmmiter.emitEvent('drawingSelect', [response]);
			self.config.eventEmmiter.emitEvent('last_select', []);
			event.overlay.setMap(null);
			self.hideDrawingTool();
		});
	};

	placesPoi = (place) => {
		if (!place.geometry) {
			// User entered the name of a Place that was not suggested and
			// pressed the Enter key, or the Place Details request failed.
			// window.alert("No details available for input: '" + place.name + "'");
			return;
		}
		// If the place has a geometry, then present it on a map.
		if (place.geometry.viewport) {
			this.map.fitBounds(place.geometry.viewport);
		} else {
			this.map.setCenter(place.geometry.location);
			this.map.setZoom(17); // Why 17? Because it looks good.
		}

		let position = place.geometry.location;
		let address = '';

		if (place.address_components) {
			address = [
				(place.address_components[0] && place.address_components[0].short_name) || '',
				(place.address_components[1] && place.address_components[1].short_name) || '',
				(place.address_components[2] && place.address_components[2].short_name) || '',
			].join(' ');
		}
		const payload = {
			address: address,
			poi: {
				action: (e) => this.config.eventEmmiter.emitEvent('addPOI', [{ ...e, latLng: position }, false]),
			},
		};

		this.config.OpenPoiInfoWindow({ position, payload });
	};

	initRouteMonitor = (options) => {
		this.HideAllMarkers(0);
		this.filteredDevices = _.cloneDeepWith([options.device])
			.filter((dev) => dev.utcDate != null)
			.map((dev) => dev.id);
		const pastDate = options.deviceDate.valueOf() < moment().startOf('days').valueOf();
		this.devices[options.device.id] = new deviceInstance(_.cloneDeepWith(options.device), this.map, {
			...this.config,
			visible: true,
			showLabel: !this.config.selectfirst,
			selected: this.config.selectfirst,
			setDeviceListeners: this.setDeviceListeners,
			infoWindowInstance: this.infoWindowInstance,
		});
		this.setDeviceListeners(options.device.id);
		this.selectDevice(
			Number(options.selectedDevice),
			options.deviceDate.format('YYYY-MM-DD'),
			_.cloneDeepWith([options.device])
		);
		this.devices[options.device.id].setConfiguration({ pastDate });
	};

	showRoute = (routeDevice) => {
		if (!this.routeService) return;
		this.routeService.displayRoute(routeDevice);
	};

	cleanRoutes = () => {
		if (!this.routeService) return;
		this.routeService.cleanRoutes();
	};

	centerOnPoi = ({ latitude, longitude }) => {
		let bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(latitude, longitude));
		this.map.fitBounds(bounds);
		this.map.setZoom(20);
	};

	addExternalEventListener = (key, func) => {
		let listener = this.map.addListener(key, func);
		this.eventsListeners[key] = {
			remove: () => {
				window.google.maps.event.removeListener(listener);
			},
		};
	};
}

export default mapIntance;

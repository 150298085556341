const Terms = () => {
	return (
		<div style={{ padding: '80px' }}>
			<section className="primary small-12 columns">
				<main role="main">
					<header className="page-header ">
						<div className="" style={{ textAlign: 'center' }}>
							<h1 className="title" data-default-text="Terms of use">
								Terms of use
							</h1>
						</div>
					</header>

					<div className="separator " aria-hidden="true"></div>

					<p style={{ textAlign: 'center' }} data-mce-style="text-align: center;">
						&nbsp;
					</p>
					<p>
						<strong>Introduction</strong>
					</p>
					<p>
						These Terms of Use are a contract (the “Terms”) between you and/or your company (collectively,
						“you”) and Optimus GPS Tracking (“Optimus,” “Company,” “us,” “we,” or “our”) that applies to
						your access to or use of our website, mobile app, services, and products (collectively, the
						“Service(s)”). If you don’t agree to these Terms, then you must not use the Services. These
						Terms incorporate by reference our Privacy Policy at
						optimustracker.com/pages/terms-of-use=and-privacy-policy and Return Policy at
						https://optimustracker.com/7344881746/policies/refund-policy.html?locale=en.
					</p>
					<p>
						<strong>Our Services</strong>
					</p>
					<p>
						Our Services include sale of GPS tracking and dashboard camera-related devices, such as for your
						vehicles and assets. We also provide a mobile app software-as-a-service platform (“Platform”),
						to exclusively support the Products, accessible to users that have an active subscription and
						minimum system requirements, such as an operating system version.
					</p>
					<p>
						You represent and warrant that you will comply with all applicable laws and these Terms in using
						the Services, including the Products and Platform. You must not use the Services for any
						unlawful or malicious purposes. You agree to be fully responsible for your legal compliance and
						obtaining any necessary consent. It may be illegal to place a tracking device on a person or
						property that is not yours or to record people or situations using a dashboard camera. You
						should consult an attorney if you have questions. Optimus will not be responsible in any way for
						how any customer uses the Services, for data collected, used, shared, retained, or not retained
						related to the Services, or for any lost property or damages of any kind during the use of the
						Services.
					</p>
					<p>
						While we have no obligation to monitor use of the Services, we reserve the right to disclose any
						information deemed necessary to satisfy any applicable law, court order, or legal process as
						outlined in these Terms and our Privacy Policy. We also have access to GPS tracking, dashboard
						camera footage, and other data as necessary to provide the Services, including technical
						support. How we use that data is outlined in our Privacy Policy.
					</p>
					<p>
						<strong>Dispute Resolution and Mandatory Arbitration</strong>
					</p>
					<p>This is an “Arbitration Agreement” waiving your right to a jury trial and class action.</p>
					<p>What is arbitration?</p>
					<p>
						Legal disputes are usually resolved in a court of law in front of a judge and, sometimes, a
						jury. Court cases are a matter of public record. Arbitration is a less formal and more private
						way of resolving disputes. Instead of going to court, the parties give their arguments and
						evidence to an arbitrator, and the arbitrator decides who “wins” the case by applying the 2 law
						in the same way that a judge would. Instead of a courtroom, arbitrations are generally held in a
						private office, such as a conference room, or by videoconference.
					</p>
					<p>What is our Arbitration Agreement?</p>
					<p>
						Under these Terms, ANY DISPUTE OR CLAIM BETWEEN YOU AND US RELATING TO YOUR USE OF THE SERVICES,
						INCLUDING ANY PRODUCT OR PLATFORM, OR OUR DISCLOSURE OF INFORMATION, THESE TERMS, THE PRIVACY
						POLICY, OR ANY OF THE RELATED PRACTICES MUST BE RESOLVED EXCLUSIVELY THROUGH BINDING
						ARBITRATION, but this does not include third-party indemnification claims. This means that we
						all agree that we will only resolve our legal disputes through arbitration and not in a court of
						law. Without this Arbitration Agreement, you would have had the right to sue in court with a
						jury trial.
					</p>
					<p>
						Our Services are intended for customers in the United States (“US”). This Arbitration Agreement
						applies to US customers and to the fullest extent permitted by applicable law. If you reside
						outside the US, you may have the right to initiate a dispute in your location of residence under
						the laws where you reside.
					</p>
					<p>
						How will the arbitration work? The arbitration must be filed and conducted through JAMS
						(www.jamsadr.com), which is a provider of arbitration services in the United States and
						internationally. JAMS’ Streamlined Rules will apply. All claims, remedies, and defenses that you
						or we have under applicable law (whether federal, state, or local) will remain available. Each
						of us will participate in choosing a neutral arbitrator using the process in the Streamlined
						Rules. Unless you waive this requirement, the final arbitration hearing (which is like a trial)
						will take place in person at the JAMS facility closest to your home. The arbitrator’s award will
						consist of a written statement explaining the arbitrator’s decision on each claim. The award
						will also include a concise written statement of the findings of fact and conclusions of law on
						which the award is based. The arbitrator must follow applicable law, and any award may be
						challenged if the arbitrator fails to do so.
					</p>
					<p>What law will the arbitrator follow?</p>
					<p>
						The laws of Florida govern these Terms. The arbitrator will apply Florida law to the claims in
						the arbitration. This Arbitration Agreement will be governed by the Federal Arbitration Act. If
						you reside outside the US, the laws in your location may grant broader rights.
					</p>
					<p>Who pays for the arbitration?</p>
					<p>
						In most cases, the only fee you will have to pay to JAMS is $250; we will pay all other
						arbitration costs. However, if the arbitrator decides that you filed your claim in bad faith and
						without any legal basis, the arbitrator can require you to pay more of the fees for the
						arbitration, other than attorneys’ fees.
					</p>
					<p>
						Regardless of who wins, you will be responsible for paying your attorneys’ fees, and we will be
						responsible for paying ours. The only exception is if the claim is brought under a law, which
						the arbitrator may apply, that specifically 3 allows a party to recover its attorneys’ fees. The
						arbitration administrators may not collect any fees if you fail to follow the Mandatory
						Pre-Arbitration Notice and the Informal Dispute Resolution Procedure outlined below.
					</p>
					<p>When will a court will get involved?</p>
					<p>
						There are things an arbitrator cannot do, like order a party to act or stop doing something—
						this is known as “equitable relief”—or rule upon certain small claims actions. Either one of us
						can go to court and seek this relief, including by filing a motion to compel the other party to
						honor the Arbitration Agreement.
					</p>
					<p>
						However, you and we agree that the only courts where we will seek equitable relief—or file any
						legal proceeding outside of arbitration—are the state and federal courts in Sheridan, Florida.
						This exception does not waive our Arbitration Agreement.
					</p>
					<p>
						A court may also get involved in fail to follow the Mandatory Pre-Arbitration Notice and the
						Informal Dispute Resolution Procedure outlined below.
					</p>
					<p>Can I file a class action?</p>
					<p>
						<strong>
							No. You and we agree that we will only file claims against each other individually and not
							as a plaintiff or class member in a representative proceeding. THIS MEANS CLASS ACTIONS ARE
							NOT ALLOWED.
						</strong>
					</p>
					<p>How do I initiate an arbitration?</p>
					<p>
						Before you commence an arbitration, you must first send us a written notice of dispute
						(“Notice”). A Notice must be sent (1) by certified mail; (2) be addressed to Optimus GPS
						Tracking, 15499 SW 12th St., Suite 509 Sunrise, Florida 33326, Attn: Support; (3) describe in
						detail the basis of your dispute with us, including the exact resolution or damages sought by
						you, and how you calculated such damages; (4) include your name, address, and email address; (5)
						be personally signed by you. The procedure described in this paragraph is called the
						Pre-Arbitration Notice Procedure.
					</p>
					<p>
						After we receive your completed Notice, you are required to engage in good faith efforts to
						resolve the dispute with us for a period of 60 days before commencing an arbitration. We will
						request an individualized telephone call or video settlement conference which both you and we
						are required to attend. You may have a lawyer present during the call or videoconference. The
						purpose of the call or video conference is to increase the likelihood of prompt and low-cost
						resolutions of your dispute.
					</p>
					<p>
						If you and we do not reach an agreement to resolve the issues in the Notice within 60 days after
						we receive the Notice (or longer if the parties agree to additional time to resolve the
						dispute), then you or we may initiate the arbitration.
					</p>
					<p>
						Do I have to send the Notice and try to resolve the dispute informally before initiating an
						arbitration?
					</p>
					<p>
						Yes. You are required to comply with the Mandatory Pre-Arbitration Notice and the Informal
						Dispute Resolution Procedure described above before you initiate an arbitration. All the
						requirements are essential so that you and we have a meaningful opportunity to resolve disputes
						informally. If you fail to follow any aspect of the Mandatory Pre-Arbitration Notice and the
						Informal Dispute Resolution Procedure, we can seek a court order preventing the arbitration. In
						addition, the JAMS arbitration administrator cannot accept, administer, assess, or demand fees
						in connection with an arbitration that has been initiated without completion of the Mandatory
						Pre-Arbitration Notice and the Informal Dispute Resolution Procedure. If the arbitration is
						already pending prior to the completion of the Mandatory Pre-Arbitration Notice and the Informal
						Dispute Resolution Procedure, the arbitration shall be administratively closed. Nothing in this
						paragraph limits the right of a party to seek damages for failing to comply with either the
						Mandatory Pre-Arbitration Notice or the Informal Dispute Resolution Procedure.
					</p>
					<p>
						You and we agree that the party initiating the arbitration must submit a certification that they
						have complied with and completed the Mandatory Pre-Arbitration Notice and the Informal Dispute
						Resolution Procedure. The certification must be personally signed by the party initiating the
						arbitration (and their attorney, if they have one).
					</p>
					<p>How do I initiating an arbitration after I complete the steps above?</p>
					<p>
						You can create an account with JAMS and submit a request to initiate arbitration at:
						https://www.jamsadr.com/submit/ or by contacting JAMS at 800.352.5267.
					</p>
					<p>
						<strong> User Accounts, Content, and Fees</strong>
					</p>
					<p>Accounts</p>
					<p>
						You must create an account (your “Account”) to access certain parts of the Services. You must
						only provide complete and accurate information in connection with your Account. This means you
						cannot set up your Account in a manner that impersonates another person or business. You cannot
						set up multiple accounts for the same person. If you violate these Terms, we may terminate your
						Account and delete your Content. It is up to you to ensure your username and password are
						secure. You agree not to share your Account login details with anyone else. If you believe your
						Account has been accessed without your permission, you should immediately notify us.
					</p>
					<p>Content</p>
					<p>
						As between Optimus and you, you own all right, title, and interest, including all intellectual
						property rights, in and to the individual data that you provide, upload, use, or communicate
						through your Account or the Services, including your tracking data and video data that is
						captured or live-streamed through products equipped with dashboard camera technology
						(“Content”). It is up to you to make sure your Content obeys these Terms and the law. You hereby
						grant to Optimus, and any parties acting on its behalf, a 5 non-exclusive, royalty-free,
						worldwide license to reproduce, distribute, and otherwise use and display your Content and
						perform all acts with respect to Content as may be reasonable for Optimus to provide the
						Services to you. This notwithstanding, Optimus may collect and compile aggregated statistics
						regarding your use of the Services and your Content, and Optimus will own all right, title, and
						interest, including all intellectual property rights, in and to any aggregated statistics.
					</p>
					<p>
						If your Content contains any reviews or comments about the Services or Optimus, you represent
						that such reviews and comments are true and accurately represent your experience. You understand
						and agree that, pursuant to these Terms, Optimus will have the right to republish all or part of
						your reviews and comments on the Services or related social media accounts, advertising, and
						other media as Optimus deems fit.
					</p>
					<p>
						You agree not to post or distribute any of the following types of Content with the Services: (a)
						infringing content, (b) illegal or obscene content, or (c) threatening or invasive content.
					</p>
					<p>Interruption of Services</p>
					<p>
						Tracking and dashcam information are provided at different intervals and with a history,
						depending on your subscription; however, we do not guarantee the accuracy or timeliness of any
						such information or any data, which may contain errors from time to time.
					</p>
					<p>
						Optimus reserves the right to interrupt the Services for planned or unplanned maintenance,
						system updates, or any other changes. Optimus will undertake efforts to notify you of any
						pre-planned outages but makes no guarantee to do so.
					</p>
					<p>
						Subject to applicable laws, Optimus may also decide to suspend or terminate the Services
						altogether. If the Services are terminated, Optimus will reasonably cooperate to enable you to
						withdraw your Content or information in accordance with applicable laws.
					</p>
					<p>
						Optimus will not be liable or responsible for any interruption of the Services. You are
						responsible for backing up your own Content and any other data.
					</p>
					<p>Product and Platform Subscription Fees</p>
					<p>
						You agree to pay all applicable fees, which are displayed during the purchase and checkout
						process, and which may include taxes, costs, charges, and service or delivery fees. All payments
						are independently processed through a third-party service provider.
					</p>
					<p>
						To the fullest extent permitted by applicable law, subscriptions automatically renew for the
						same length term unless adequately cancelled before the next renewal deadline. The fees will be
						the same as the prior term, unless we have given notice of an increase, in which case the
						increase will be effective upon the renewal. Termination, suspension, or expiration do not
						impact your obligation to pay all fees owed. You may only cancel a subscription effective at the
						end of the given term; if you cancel before the end of the subscription term, you will be
						charged the entire remaining amount of subscription fees. No refunds are made if you do not
						cancel your subscription before the payment has been made. 6
					</p>
					<p>Account Suspension and Termination</p>
					<p>
						Optimus reserves the right, in its sole discretion, to terminate or suspend your access to or
						use of the Services, including any Product or the Platform, if Optimus believes that there is a
						violation of these Terms or any applicable law relating to you or your Account, including if we
						suspect you have used the Services in a wrongful, inappropriate, or fraudulent manner. The
						suspension or termination of your Account shall not entitle you to any claims for compensation,
						damages, reimbursement, or otherwise.
					</p>
					<p>You can terminate your Account and stop using the Services at any time by:</p>
					<p>• Using the tools provided for account termination within the Platform; or</p>
					<p>• Contacting Optimus in writing at the contact details provided below.</p>
					<p>
						Suspension or termination of your Account does not necessarily delete your Account data. For
						more information and to delete your data, please see our Privacy Policy.
					</p>
					<p>
						<strong>Your Representations and Warranties</strong>
					</p>
					<p>
						By using the Services, you represent and warrant that you will comply with all applicable laws
						and these Terms. For example, state and federal regulations may restrict or regulate video and
						audio monitoring of employees within their workspaces and/or employees engaging in protected
						labor organization activities. Additionally, many states regulate attempts to intercept audio
						conversations transmitted electronically and require consent of all parties who are subject to
						audio recording.
					</p>
					<p>
						<strong>Prohibited Conduct</strong>
					</p>
					<p>
						We respect the rights of others and expect you to do the same. You are expressly prohibited from
						doing or attempting to do, or encouraging or assisting another in doing, any of the following in
						connection with the Services:
					</p>
					<p>
						• violating any applicable law, which includes any rule, regulation, or ordinance, or industry
						guideline or practice;
					</p>
					<p>• violating these Terms or any other applicable policy or guideline;</p>
					<p>
						• using the Services to harass, stalk, defame, threaten, violate the privacy, track without
						consent, or otherwise violate the legal rights of others;
					</p>
					<p>• accessing, altering, or interfering with another’s use or communications;</p>
					<p>
						• surreptitiously recording and/or broadcasting audio or video in violation of any source of
						law;
					</p>
					<p>
						• using video or audio recording devices to monitor employees engaged in protected labor
						organization activities;
					</p>
					<p>
						• providing false, misleading, or inaccurate information to Optimus or any other person in
						connection with your Account or the Services;
					</p>
					<p>
						• impersonating, or otherwise misrepresenting affiliation, connection, or association with, any
						person or entity;
					</p>
					<p>• accessing the Account of any other user without express permission;</p>
					<p>
						• modifying or changing the placement and location of any content posted through the Services;
					</p>
					<p>
						• using any engine, software, tool, agent, or other device or mechanism (such as browsers,
						spiders, robots, avatars, AI, or intelligent agents) to harvest or collect information from the
						Services for any use, including any use on another website, app, or media;
					</p>
					<p>
						• accessing content or data not intended for you, or logging into a server or account that you
						are not authorized to access;
					</p>
					<p>
						• attempting to probe, scan, or test the vulnerability of the Services, or any associated system
						or network, or breaching security or authentication measures;
					</p>
					<p>
						• interfering with the use of the Services by any other user, host, or network, including by
						submitting malware, exploiting vulnerabilities, or through a DDoS attack;
					</p>
					<p>
						• forging, modifying, or falsifying any network packet or protocol header or metadata in any
						connection with, or transmission to, the Services (for example, SMTP email headers, HTTP
						headers, or Internet Protocol packet headers);
					</p>
					<p>
						• Modifying, reverse-engineering, decompiling, disassembling, or otherwise reducing to a
						human-perceivable form any of the source code used in the Services;
					</p>
					<p>
						• removing the SIM card from a Product or using the SIM card in connection with any other
						product or service;
					</p>
					<p>• creating additional Accounts, including to promote yourself or another; or</p>
					<p>
						• paying or accepting payment from anyone for interactions on the Services, including paying for
						likes, reviews, and comments.
					</p>
					<p>
						If you have any questions or concerns, including about another user of the Services, please
						contact us at the contact information outlined below.
					</p>
					<p>
						<strong> Indemnification by You</strong>
					</p>
					<p>
						<strong>
							By using the Services you agree to defend, indemnify, and hold harmless Optimus, including
							its owners, directors, officers, affiliates, partners, agents, employees, and contractors
							(who are Third-Party Beneficiaries of this provision) from any claim, liability, loss,
							damage, cost, or expense (including suits, judgments, litigation, settlements, costs, and
							attorney’s fees of every kind and nature) directly, indirectly, or consequentially arising
							out of or related to (in fact or allegation) the following:
						</strong>
					</p>
					<p>
						<strong>
							• Your use of or access to the Services, including any data or content transmitted or
							received by you;
						</strong>
					</p>
					<p>
						<strong>
							• Your violation of these Terms, including breach of any representations; • Your violation
							of any third-party rights, including any privacy rights;
						</strong>
					</p>
					<p>
						<strong>• Your violation of any law, including any rule, regulation, or ordinance;</strong>
					</p>
					<p>
						<strong>• Your violation of any of our policies or guidelines applicable to you;</strong>
					</p>
					<p>
						<strong>• Your Content or representations; 8</strong>
					</p>
					<p>
						<strong>• Your willful misconduct; or</strong>
					</p>
					<p>
						<strong>• Any conduct or content relating to your Account.</strong>
					</p>
					<p>
						We shall promptly notify you by electronic mail (at your email address used for the Services) of
						any claim for defense or indemnification and cooperate fully (at your expense) in the defense of
						such claim or suit. If we do not hear from you promptly, we reserve the right to defend such
						claim or suit and seek full recompense from you.
					</p>
					<p>
						<strong>Intellectual Property</strong>
					</p>
					<p>Our Intellectual Property</p>
					<p>
						Our names, trademarks, copyrights, logos, websites, services, including our Products and
						Platform, and all content and other materials available through the Services, and other than
						your Content (collectively, the “Optimus IP”) are the intellectual property of Optimus and/or
						its licensors. Nothing in these Terms grants you any right, title, license, or interest in the
						Optimus IP. You shall not at any time, nor shall you assist others to, challenge Optimus’s
						right, title, or interest in, or the validity of, the Optimus IP. Optimus reserves all rights.
					</p>
					<p>Your Limited License to Use the Services</p>
					<p>
						If you comply with these Terms and applicable laws, we grant you a limited, non-exclusive,
						non-sublicensable, revocable, non-transferable license to access and use the Services for lawful
						purposes. This license does not allow you reproduce, modify, duplicate, copy, sell, resell,
						distribute, transmit, or otherwise exploit (for any commercial purpose or otherwise) any part of
						the Services, including any Content of other users, unless you first obtain our written
						permission. If you breach these Terms, your license will automatically terminate.
					</p>
					<p>Our License to Your Content</p>
					<p>
						You are always the owner of your Content; however, we require a license from you to legally
						display your Content on the Services. Without this license, we could not allow you to upload any
						Content. Accordingly, you grant to Optimus a nonexclusive, royalty-free, perpetual, irrevocable,
						and fully sublicensable right to use, reproduce, modify, adapt, publish, perform, translate,
						create derivative works from, distribute and display (1) your Content throughout the world in
						any media now existing or in the future created for purposes of providing the Services, and (2)
						your public reviews or comments relating to us to promote the Services (aside from your public
						reviews or comments, we will not use your Content in advertising without first obtaining your
						permission).
					</p>
					<p>
						As a service provider for customers using our Platform, Optimus uses your information to provide
						the Services; we will not retain, use, or disclose your personal information for other purposes
						and will not sell your data or share it for cross-context advertising purposes.
					</p>
					<p>
						Copyright Infringement and Our DMCA Policy Pursuant to the Digital Millennium Copyright Act
						("DMCA"), if you believe Content on the 9 Services violates your copyright, please immediately
						notify Optimus by emailing a DMCA takedown notice ("Infringement Notice") to
						support@optimustracker.com.
					</p>
					<p>Your Infringement Notice should include all information described below:</p>
					<p>• Identification of your copyrighted work.</p>
					<p>
						• A description of the Content you believe infringes your copyright (the “Infringing Content”).
						Your description should include enough information about the nature and location of the
						Infringing Content to allow us to locate it on the Services.
					</p>
					<p>• Your name, address, telephone number, and email address.</p>
					<p>
						• The name, address, telephone number, email address, or other information (for example,
						username) of the user who posted the Infringing Content.
					</p>
					<p>
						• A written statement by you that: o you believe in good faith that the use of the material you
						claim to infringe your copyright is not authorized by the copyright owner or by law; and o you
						declare, under penalty of perjury, that all the information contained in your Infringement
						Notice is accurate, and that you are either the copyright owner or a person authorized to act on
						their behalf.
					</p>
					<p>
						• Your signature, whether electronic or physical, as the copyright owner or a person authorized
						to act on behalf of the copyright owner.
					</p>
					<p>
						You should email your Infringement Notice with the subject line “DMCA Infringement Notice.” We
						will respond to compliant Infringement Notices by taking any action required under the DMCA.
						Please note that the DMCA requires that your Infringement Notice be submitted in good faith.
						This means you are required to evaluate whether the use of your Content on the Services is a
						“fair use,” as fair uses are allowed under the law. If you are not sure if the Infringing
						Content infringes your copyright, you should consult with an attorney. If you submit an
						Infringement Notice in bad faith, the user who posted the Infringing Content could have legal
						claims against you. Infringement Notices are not anonymous. If we act in response to your
						Infringement Notice—such as by removing the Infringing Content—we will pass on your Infringement
						Notice to the user to provide an opportunity to respond. Counter notices should be emailed and
						comply with the DMCA.
					</p>
					<p>
						<strong>Disclaimers and Limitations of Liability</strong>
					</p>
					<p>No Additional Warranties</p>
					<p>
						<strong>
							TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNLESS WE EXPRESSLY WARRANT SOMETHING IN
							WRITING TO YOU, WE DISCLAIM ANY AND ALL WARRANTIES TO YOU, INCLUDING EACH OF THE FOLLOWING
							WARRANTIES: ALL WARRANTIES ARISING BY OPERATION OF LAW OR OTHERWISE, ALL IMPLIED WARRANTIES
							OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, NON- ENCUMBRANCE,
							NONTITLE, AND ALL WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICES. WE
							DO NOT WARRANT THAT THE SERVICES WILL MEET YOUR EXPECTATIONS OR REQUIREMENTS. WE DO NOT
							WARRANT THAT YOUR ACCESS TO THE SERVICES WILL BE 10 UNINTERRUPTED OR THAT THE SERVICES WILL
							FUNCTION ERROR-FREE.
						</strong>
					</p>
					<p>
						<strong>
							If you purchase a Product from us, it may come with its own warranty and disclaimers. In
							such event, the Product warranty and disclaimers will apply to your use of the Product. If
							the Product does not have its own warranty and disclaimer, then the warranties and
							disclaimers in these Terms shall apply to your use of the Product.
						</strong>
					</p>
					<p>Assumption of Risks</p>
					<p>
						The use of our Services is at your own risk and should be based on your own due diligence. You
						should not use the Services as a replacement for supervision; the Services are not substitutes
						for responsible monitoring of your property or dependents. You assume all risk of potential
						security or privacy incidents. Mobile devices, computers, tablets, technology, and the internet
						may not be secure at all times. Optimus does not guarantee the security of your Content or any
						other data or communications. You should not rely on Optimus to store or backup your data; you
						are responsible for your Content and your conduct.
					</p>
					<p>
						You hereby consent to Optimus disclosing location, dashcam, and related information to you or
						any person or entity using your username/password, or pursuant to our Privacy Policy, about the
						person, vehicle, or other asset that is tracked or recorded in connection with the Services.
					</p>
					<p>Limitations of Liability</p>
					<p>
						This section limits the types of claims you can bring against us. These limitations help us
						reduce the risks associated with providing the Services so that we can provide it.
					</p>
					<p>
						<strong>
							TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, OPTIMUS (INCLUDING THE THIRDPARTY
							BENEFICIARIES) WILL NOT BE LIABLE TO YOU FOR ANY CONSEQUENTIAL DAMAGES, LOST PROFITS, LOST
							CONTENT OR OTHER DATA, COSTS OF PROCUREMENT OF SUBSTITUTE SERVICES, OR FAILURE TO MEET ANY
							DUTY, INCLUDING GOOD FAITH OR REASONABLE CARE. THESE LIMITATIONS APPLY REGARDLESS OF THE
							FORESEEABILITY OF SUCH DAMAGES. THE LOCATION INFORMATION PROVIDED WITH THE SERVICES IS AN
							APPROXIMATE LOCATION OF THE PRODUCT. OPTIMUS DOES NOT REPRESENT, WARRANT, OR GUARANTEE THE
							RELIABILITY, ACCURACY, COMPLETENESS, OR TIMELINESS OF ANY LOCATION DATA PROVIDED VIA THE
							SERVICES OR THAT THE SERVICES SHALL BE ERROR FREE OR UNINTERRUPTED. In no event will Optimus
							be liable to you, or any party related to you or your Account for any lost data,
							unavailability of or delay in Services, or damage, including loss of profits or other
							pecuniary loss, whether under a theory of contract, warranty, tort (including negligence),
							products liability, or otherwise, even if Optimus has had notice.
						</strong>
					</p>
					<p>
						<strong>
							You understand that the Products and Platform use GPS (global positioning system), video
							live streaming and other wireless services. The Services may be impacted by GPS and wireless
							coverage in different areas, weather, and third-party planned or 11 unplanned outages. You
							are also responsible for updating any batteries or reporting Product damages.
						</strong>
					</p>
					<p>
						We will not be liable for any failure to perform any of our obligations if the failure results
						from a cause beyond our reasonable control, including electronic or communications failure,
						pandemics, or unforeseeable force majeure acts beyond our control.
					</p>
					<p>Interpretation</p>
					<p>
						The disclaimers, exclusions, and limitations of liability under the Terms shall not apply to the
						extent prohibited by applicable law. Some jurisdictions do not allow certain disclaimers or
						limitations of liability, such as the exclusion or limitation of incidental or consequential
						damages; therefore, the above limitations or exclusions may not apply to you. Specific
						jurisdictions may grant you additional rights, depending on your location.
					</p>
					<p>
						<strong>No Responsibility for Third Parties</strong>
					</p>
					<p>
						Third parties are any person or business other than you or us, in other words, someone who is
						not a party to these Terms. We cannot control third parties, including other users of our
						Services or our service providers. Under the Communications Decency Act, service providers, such
						as Optimus, are not liable for information originating with a third-party user of the Services.
						The Services may also contain use of third-party platforms or services, such as to provide map
						services. We do not endorse any third-party or its content or conduct. Third parties are solely
						responsible for their own content and conduct. We do not review all third-party content and do
						not guarantee that it is accurate, complete, or useful. We are not responsible for your reliance
						on any third party or its content/conduct.
					</p>
					<p>
						<strong>Permissions</strong>
					</p>
					<p>
						By enabling the Platform’s GPS tracking features for your Products, you agree and consent to the
						collection, transmission, and use of your location data and related history by Optimus and its
						service providers to provide and improve the Services. You can disable the tracking features by
						making changes in or terminating your Account, deactivating your Product, or contacting us.
					</p>
					<p>
						When you use the Services, you may also be given the opportunity to turn on or off certain
						permissions relating to your device or accounts, including to receive updates related to the
						Services. For example, you may grant permission to send notifications. For apps, the permissions
						settings is within the general device settings and is not controlled by us.
					</p>
					<p>
						By using the Services, you consent to receive communications from us, such as text messages,
						emails, and push notifications. We will provide you with an opportunity to opt into such
						messages if an applicable. You can opt out of communications by:
					</p>
					<p>• For emails, click on the “unsubscribe” link in the email and follow any directions.</p>
					<p>• For text messages, respond STOP.</p>
					<p>• For mobile push notifications, change your app permissions in your device settings.</p>
					<p>
						For text messages, standard messaging rates will apply. Texts, calls, and prerecorded messages
						may be generated by automatic dialing systems. By using our Platform, you consent to receive
						applicable automatic software updates.
					</p>
					<p>
						By using the Services, you consent to receive communications electronically at the email address
						provided in connection with the Services or linked with your Account. You agree that these
						electronic notices satisfy any legal requirements for notice in writing.
					</p>
					<p>
						<strong>Other Policies and Agreements</strong>
					</p>
					<p>
						Our Return Policy at https://optimustracker.com/7344881746/policies/refundpolicy.html?locale=en,
						which is a part of these Terms and incorporated by reference, outlines our 30-day return policy
						for Products, subject to certain requirements, and how you can initiate a return or refund.
						Products may also come with separate warranty and disclaimer terms. Optimus is not liable for
						packages lost or damaged during shipment.
					</p>
					<p>
						Our Privacy Policy at optimustracker.com/pages/terms-of-use=and-privacy-policy, which is a part
						of these Terms and incorporated by reference, outlines how we collect, use, and disclose
						information and how you can assert your privacy rights. In addition to the Privacy Policy, there
						may be additional policies, incorporated into these Terms by reference, that govern your use of
						the Services if provided and applicable to you.
					</p>
					<p>
						<strong>Disclosures for Legal Requests</strong>
					</p>
					<p>
						We may disclose your information as outlined in our Privacy Policy and as required by applicable
						laws. Different laws apply to government and other legal processes and requests. For example,
						the Fourth Amendment to the US Constitution and the Electronic Communications Privacy Act limit
						the government’s ability to obtain certain user information from a provider; and different laws
						and standards regulate international requests and emergency situations, such as to prevent death
						or serious physical harm. Our response depends on the type of request and data at issue. For
						example, a subpoena may be required to obtain user identification (name and IP address). A court
						order or search warrant may be required in criminal cases or if seeking tracking or other data.
						Note that we may be prohibited from providing notice to the affected user or prevented by
						emergency situations. We may also disclose your information with your consent. As set forth in
						our Privacy Policy, all law enforcement requests we receive must adhere to due process of law
						and be subject to judicial oversight. Law enforcement requests can contact us at
						support@optimustracker.com.
					</p>
					<p>
						<strong> Changes to Terms</strong>
					</p>
					<p>
						Technology and the applicable laws change frequently. We may need to make changes to these Terms
						and related policies and agreements from time to time, including to keep up with changes in the
						law. If we make a change material to your use of the Services, we will notify you so you can
						decide whether you want to continue using the Services after the 13 change takes effect. Your
						continued use of the Services after the change constitutes your consent to the updated Terms and
						policies/agreements. It is up to you to keep the email address associated with the Services up
						to date so that you don’t miss any notifications.
					</p>
					<p>
						<strong>Miscellaneous Provisions</strong>
					</p>
					<p>Eligibility</p>
					<p>
						You represent and warrant that you are at least 18 years old (or the age of majority in your
						jurisdiction, if older than 18) and lawfully qualified to enter into legal contracts.
					</p>
					<p>
						If you are using the Services on behalf of a company, you represent and warrant that you are
						authorized to act on behalf of that company, including by agreeing to these Terms.
					</p>
					<p>
						These Terms do not create any agency, partnership, joint venture, or employment relationship,
						and neither party has any authority to bind the other in any respect.
					</p>
					<p>Assignment</p>
					<p>
						You cannot assign, transfer, or sublicense these Terms without first obtaining our express
						consent. On the other hand, we may assign, transfer, or delegate any of our rights and
						obligations without consent, including if there is a sale or transfer of our business.
					</p>
					<p>No Waiver</p>
					<p>
						A waiver by either you or Optimus of any term or condition of these Terms, or any breach, in any
						one instance, will not waive that term or condition or any later breach.
					</p>
					<p>Survival</p>
					<p>
						The provisions of these Terms which, by their nature, should survive termination, shall survive
						such termination, including the sections on Dispute Resolution and Mandatory Arbitration,
						Prohibited Conduct, Indemnification by You, Intellectual Property (except your limited license),
						Disclaimers and Limitations of Liability, and Miscellaneous Provisions.
					</p>
					<p>Severability</p>
					<p>
						These Terms are severable. If any provision of these Terms is declared invalid, void, or
						unenforceable, then that provision is severable from these Terms and shall not affect the
						validity and enforceability of the remaining provisions.
					</p>
					<p>
						<strong>Contact </strong>
					</p>
					<p>If you have any questions or concerns, please contact us at:</p>
					<p>Optimus GPS Tracking</p>
					<p>15499 SW 12th St., Suite 509 Sunrise, Florida 33326</p>
					<p>sales@optimustracker.com</p>
					<p>support@optimustracker.com</p>
					<p>(855) 893-0707</p>
					<p>&nbsp;</p>
					<p>
						<strong>Last Updated: January 6, 2025</strong>
					</p>
				</main>
			</section>
		</div>
	);
};

export default Terms;

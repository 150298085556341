import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { translate } from '@optimusgps/optimus-intl';
import { Checkbox, Form, FormInstance, Input, InputNumber, Typography } from 'antd';
import { DeviceModelProfileDto, ModelProfileDto } from '@/features/modelProfile/modelProfile.dto';
import AdvancedFilter from '@/OptimusRoutes/components/filter/AdvancedFilter';
import { Device } from '@/OptimusRoutes/entities/device.entity';
import useAdvancedFilter from '@/OptimusRoutes/hooks/useAdvancedFilter.hook';
import useFetchData from '@/OptimusRoutes/hooks/useFetchData.hook';
import { EntityFilter, Where } from '@/OptimusRoutes/interfaces';
import { AppliedFilter, FilterData, FilterDataSelector } from '@/OptimusRoutes/types/filterComponent.types';
import { CommandsToBeSend } from '../commandsToBeSend.dto';
import DevicesCommandsToBeSendService from '../devicesCommandsToBeSend.service';
import { FilterOptions } from './CommandsToBeSendFilter';

type Props = {
	form: FormInstance;
	models: DeviceModelProfileDto[];
};

type SearchFilterValues = Pick<Partial<CommandsToBeSend>, 'command'> & {
	modelId?: string;
	modelProfile?: Pick<ModelProfileDto, 'deviceModelId'> & {
		deviceModelId: string;
	};
	dataPort?: string;
	firmwareVersion?: string;
};

const { Item } = Form;
const { TextArea } = Input;
const { Text } = Typography;

const CreateMultipleCommandsForm = ({ form, models }: Props) => {
	const intl = useIntl();

	const [checkBoxStatus, setCheckBoxStatus] = useState(false);

	const modelOptions = models.map<FilterDataSelector>(({ id, description }) => ({
		key: id,
		value: id,
		label: description,
	}));

	const formatSearchFilter = (value: SearchFilterValues | string): EntityFilter<SearchFilterValues> => {
		if (typeof value === 'string') {
			return {};
		}

		let where: Where<SearchFilterValues> = {};

		if (value.modelId) {
			where = {
				...where,
				modelProfile: {
					deviceModelId: value.modelId,
				},
			};
		}

		if (value.dataPort) {
			where.dataPort = value.dataPort;
		}

		if (value.firmwareVersion) {
			where.firmwareVersion = { value: value.firmwareVersion, op: 'JSON', property: 'firmware' };
		}

		form.setFieldValue('advancedFilter', { where });

		return {
			where,
		};
	};

	const { search, total, loading } = useFetchData<Device, SearchFilterValues>({
		service: DevicesCommandsToBeSendService,
		formatSearchFilter,
	});

	form.setFieldsValue({ totalDevicesAffected: total });

	const { clearFilters, setAppliedFilters, appliedFilters } = useAdvancedFilter({ search });

	const onChange = (filtersValues: AppliedFilter[]): void => {
		setAppliedFilters(filtersValues);
		if (filtersValues.length === 0) {
			form.setFields([
				{
					name: 'advancedFilter',
					errors: [intl.formatMessage(translate('filterIsRequired'))],
				},
			]);

			setCheckBoxStatus(false);
			form.setFieldValue('applyToLastEnabled', false);
			form.setFieldValue('applyToLastCount', undefined);
		} else {
			form.setFields([
				{
					name: 'advancedFilter',
					errors: [],
				},
			]);
		}
	};

	const onClearFilters = (): void => {
		clearFilters();
		form.setFieldValue('advancedFilter', undefined);
	};

	const filters: FilterData[] = [
		{
			id: FilterOptions.MODEL,
			type: 'DROPDOWN',
			filterwhere: 'modelId',
			intlLabelCode: 'model',
			placeholder: intl.formatMessage(translate('modelPlaceholder')),
			options: modelOptions,
		},
		{
			id: FilterOptions.PORT,
			type: 'TEXT',
			filterwhere: 'dataPort',
			intlLabelCode: 'dataPort',
			placeholder: intl.formatMessage(translate('port')),
		},
		{
			id: FilterOptions.FIRMWARE,
			type: 'TEXT',
			filterwhere: 'firmwareVersion',
			intlLabelCode: 'firmwareVersion',
			placeholder: intl.formatMessage(translate('firmwareVersion')),
		},
	];

	return (
		<Form form={form} layout="vertical">
			<Item name="totalDevicesAffected" hidden>
				<InputNumber value={total} />
			</Item>
			<Item
				name={'advancedFilter'}
				label={intl.formatMessage(translate('filter'))}
				rules={[{ required: true, message: intl.formatMessage(translate('filterIsRequired')) }]}
			>
				<AdvancedFilter
					filters={filters}
					onChange={onChange}
					onClearFilters={onClearFilters}
					key="advanced-filter"
					placeholder={intl.formatMessage(translate('advancedFilter'))}
				/>
				{loading && <LoadingOutlined style={{ marginTop: 10 }} />}
				{appliedFilters.length !== 0 && !loading && (
					<div style={{ marginTop: 10 }}>
						<Text color="#000" italic>
							<FormattedMessage {...translate('devicesAffectecByFilter')} values={{ total }} />
						</Text>
					</div>
				)}
			</Item>
			<Item>
				<Item name="applyToLastEnabled" valuePropName="checked" noStyle>
					<Checkbox
						disabled={(appliedFilters.length === 0 && !loading) || (!loading && total === 0)}
						checked={checkBoxStatus}
						onChange={(e) => {
							setCheckBoxStatus(e.target.checked);
						}}
					>
						<FormattedMessage {...translate('applyToTheLast')} />
					</Checkbox>
				</Item>
				<Item name="applyToLastCount" noStyle>
					<InputNumber min={1} max={total} disabled={!checkBoxStatus} />
				</Item>
			</Item>
			<Item
				label={intl.formatMessage(translate('command'))}
				name="command"
				rules={[{ required: true, message: intl.formatMessage(translate('pleaseEnterACommand')) }]}
			>
				<TextArea rows={6} />
			</Item>
		</Form>
	);
};

export default CreateMultipleCommandsForm;

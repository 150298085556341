import axios from 'axios';
import { UpdateVehicleInfoDto, VehicleInfoDto } from '@/features/deviceInformation/deviceInformation.dto';
import { Result } from '@/OptimusRoutes/interfaces';

type Service = {
	get: (deviceId: number) => Promise<Result<VehicleInfoDto>>;
	update: (deviceId: number, deviceInfo: UpdateVehicleInfoDto) => Promise<Result<VehicleInfoDto>>;
};

const get = async (deviceId: number): Promise<Result<VehicleInfoDto>> => {
	const { data } = await axios.get<Result<VehicleInfoDto>>(`/v2/devices/${deviceId}/vehicle`);
	return data;
};

const update = async (deviceId: number, deviceInfo: UpdateVehicleInfoDto): Promise<Result<VehicleInfoDto>> => {
	const { data } = await axios.patch<Result<VehicleInfoDto>>(`/v2/devices/${deviceId}/vehicle`, deviceInfo);
	return data;
};

// TO-DO convert to class service
const DeviceInformationService: Service = {
	get,
	update,
};

export default DeviceInformationService;

import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import moment from 'moment';
import { INTEREST_TYPE, PLACES_TYPE } from '../../constants/global';
import unitSystem from '../../features/unitSystems';
import PolyUtil from '../PolyUtil';

class place {
	constructor({ name, map, color }) {
		this._listeners = [];
		if (name) this._name = name;
		else this._name = '';

		if (map) this._map = map;
		else this._map = null;

		if (color) this._color = color;

		this.overlay = null;
		this.label = new MarkerWithLabel({
			draggable: false,
			raiseOnDrag: true,
			map: this._map,
			labelContent: this._name,
			labelClass: 'places-label',
			icon: '..',
		});
	}

	get bounds() {
		if (this.overlay) return this.overlay.getBounds();
		return null;
	}

	get Overlay() {
		return this.overlay;
	}

	get Name() {
		return this._name;
	}

	setName(value) {
		this._name = value;
	}

	get Map() {
		return this._map;
	}

	setMap(map) {
		this._map = map;
		if (this.overlay) {
			this.overlay.setMap(this._map);
			this.label.setMap(this._map);
		}
	}

	get Color() {
		return this._color;
	}

	setColor(color) {
		this._color = color;
		if (this.overlay) this.overlay.setColor(this._color);
	}

	setOptions(options) {
		if (options.name) this._name = options.name;

		if (options.map) this._map = options.map;

		if (options.color) this._color = options.color;
	}

	addListeners() {}

	removeListeners() {
		var self = this;
		self._listeners.forEach((listener) => {
			window.google.maps.event.removeListener(listener);
		});
	}
}

export class circlePlace extends place {
	constructor({ encodedObject, radius, color, map, name, fitBounds = true }) {
		super({ name, map, color });

		var initialValues = {
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillOpacity: 0.35,
		};
		var points = window.google.maps.geometry.encoding.decodePath(encodedObject);
		if (points.length) {
			this._center = points[0];
			initialValues.center = this._center;
		}
		if (color) {
			this._color = color;
			initialValues.strokeColor = this._color;
			initialValues.fillColor = this._color;
		}

		if (radius) {
			this._radius = radius;
			initialValues.radius = this._radius;
		}

		if (map) {
			this._map = map;
			initialValues.map = this._map;
		}
		this.overlay = new window.google.maps.Circle(initialValues);
		this.label.setOptions({
			labelContent: this._name,
			position: this._center,
			map: this._map,
		});

		if (this._map && fitBounds) this._map.fitBounds(this.overlay.getBounds());
		this.addListeners();
	}

	addListeners() {
		var self = this;
		var centerOnLabelClick = window.google.maps.event.addListener(self.label, 'click', function () {
			self._map.fitBounds(self.overlay.getBounds());
		});

		self._listeners.push(centerOnLabelClick);
	}

	removeListeners() {
		var self = this;
		self._listeners.forEach((listener) => {
			window.google.maps.event.removeListener(listener);
		});
	}

	setCenter(center) {
		this._center = center;
		this.overlay.setCenter(this._center);
		this.label.setOptions({ position: this._center });
	}

	setRadius(radius) {
		this._radius = radius;
		this.overlay.setRadius(this._radius);
	}

	setOptions(options) {
		const { fitBounds = true } = options;
		super.setOptions(options);

		if (options.encodedObject) {
			var points = window.google.maps.geometry.encoding.decodePath(options.encodedObject);
			if (points.length) {
				this._center = points[0];
			}
		}

		if (options.radius) {
			this._radius = options.radius;
		}

		const overlayOptions = {
			radius: this._radius,
			center: this._center,
			strokeColor: this._color,
			fillColor: this._color,
			map: this._map,
		};
		if (options.strokeWeight) overlayOptions.strokeWeight = options.strokeWeight;

		this.overlay.setOptions(overlayOptions);
		this.label.setOptions({
			labelContent: this._name,
			position: this._center,
			map: this._map,
		});

		if (this._map && fitBounds) this._map.fitBounds(this.overlay.getBounds());
	}
}

export class polygonPlace extends place {
	constructor({ encodedObject, color, map, name, fitBounds = true }) {
		super({ name, map, color });

		var initialValues = {
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillOpacity: 0.35,
			geodesic: true,
		};
		var points = window.google.maps.geometry.encoding.decodePath(encodedObject);
		if (points.length) {
			this._paths = points;
			initialValues.paths = this._paths;
		}
		if (color) {
			this._color = color;
			initialValues.strokeColor = this._color;
			initialValues.fillColor = this._color;
		}

		if (map) {
			this._map = map;
			initialValues.map = this._map;
		}
		this.overlay = new window.google.maps.Polygon(initialValues);
		var bounds = new window.google.maps.LatLngBounds();
		this._paths.forEach((point) => {
			bounds.extend(point);
		});

		this.label.setOptions({
			labelContent: this._name,
			position: bounds.getCenter(),
			map: this._map,
		});

		if (this._map && fitBounds) this._map.fitBounds(bounds);
	}

	get bounds() {
		if (this.overlay) {
			var bounds = new window.google.maps.LatLngBounds();
			this._paths.forEach((point) => {
				bounds.extend(point);
			});
			return bounds;
		}
		return null;
	}

	addListeners() {
		var self = this;
		var centerOnLabelClick = window.google.maps.event.addListener(self.label, 'click', function () {
			var bounds = new window.google.maps.LatLngBounds();
			self._paths.forEach((point) => {
				bounds.extend(point);
			});
			self._map.fitBounds(bounds);
		});

		self._listeners.push(centerOnLabelClick);
	}

	setPath(points) {
		if (points.length) {
			this._paths = points;
		}
		if (this.overlay) this.overlay.setPaths(this._paths);
	}

	setOptions(options) {
		const { fitBounds = true } = options;
		super.setOptions(options);

		if (options.encodedObject) {
			var points = window.google.maps.geometry.encoding.decodePath(options.encodedObject);
			if (points.length) {
				this._paths = points;
			}
		}

		this.overlay.setOptions({
			paths: this._paths,
			strokeColor: this._color,
			fillColor: this._color,
			map: this._map,
		});

		var bounds = new window.google.maps.LatLngBounds();
		this._paths.forEach((point) => {
			bounds.extend(point);
		});

		this.label.setOptions({
			labelContent: this._name,
			position: bounds.getCenter(),
			map: this._map,
		});

		if (this._map && fitBounds) this._map.fitBounds(bounds);
	}
}

export class polylinePlace extends place {
	constructor({ encodedObject, color, map, name, trace, lineOfInterst, mapName, fitBounds = true }) {
		super({ name, map, color });

		var initialValues = {
			strokeOpacity: 0.8,
			strokeWeight: 2,
			geodesic: true,
		};

		if (encodedObject) {
			var points = window.google.maps.geometry.encoding.decodePath(encodedObject);
			if (points.length) {
				this._path = points;
				initialValues.path = this._path;
			}
		}

		if (color) {
			this._color = color;
			initialValues.strokeColor = this._color;
		}

		if (map) {
			this._map = map;
			initialValues.map = this._map;
		}
		if (trace && trace.length) {
			this._path = trace;
			initialValues.path = this._path;
		}
		this.overlay = new window.google.maps.Polyline(initialValues);
		var bounds = new window.google.maps.LatLngBounds();
		this._path.forEach((point) => {
			bounds.extend(point);
		});

		if (this.label) {
			this.label.setOptions({
				labelContent: this._name,
				position: bounds.getCenter(),
				map: this._map,
			});
		}
		if (lineOfInterst) this._lineOfInterst = lineOfInterst;

		if (this._map && !this._lineOfInterst && fitBounds) this._map.fitBounds(bounds);
		if (lineOfInterst && mapName !== 'main') this.setOptions({});
	}

	getEncodedObject() {
		var self = this;
		return new Promise((resolve, reject) => {
			if (!self.overlay) reject(new Error('overlay cannot be accessed!'));
			var encodedObject = window.google.maps.geometry.encoding.encodePath(self.overlay.getPath());

			resolve(encodedObject);
		});
	}

	get bounds() {
		if (this.overlay) {
			var bounds = new window.google.maps.LatLngBounds();
			this._path.forEach((point) => {
				bounds.extend(point);
			});
			return bounds;
		}
		return null;
	}

	addListeners() {
		var self = this;
		var centerOnLabelClick = window.google.maps.event.addListener(self.label, 'click', function () {
			var bounds = new window.google.maps.LatLngBounds();
			self._path.forEach((point) => {
				bounds.extend(point);
			});
			self._map.fitBounds(bounds);
		});

		self._listeners.push(centerOnLabelClick);
	}

	setOptions(options) {
		const { fitBounds = true } = options;
		super.setOptions(options);

		if (options.encodedObject) {
			var points = window.google.maps.geometry.encoding.decodePath(options.encodedObject);
			if (points.length) {
				this._path = points;
			}
		}

		this.overlay.setOptions({
			path: this._path,
			strokeColor: this._color,
			fillColor: this._color,
			map: this._map,
		});

		var bounds = new window.google.maps.LatLngBounds();
		this._path.forEach((point) => {
			bounds.extend(point);
		});

		if (options.label) {
			this.label.setMap(this._map);
			this.label.setOptions({
				labelContent: this._name,
				position: bounds.getCenter(),
				map: this._map,
			});
		} else {
			this.label.setMap(null);
		}

		if (this._map && !this._lineOfInterst && fitBounds) this._map.fitBounds(bounds);
	}
}

export class rectanglePlace extends place {
	constructor({ encodedObject, color, map, name, fitBounds = true }) {
		super({ name, map, color });

		var initialValues = {
			strokeOpacity: 0.8,
			fillOpacity: 0.35,
			strokeWeight: 2,
			geodesic: true,
		};
		var points = window.google.maps.geometry.encoding.decodePath(encodedObject);

		if (points.length) {
			this._bounds = {
				north: points[0].lat(),
				south: points[1].lat(),
				east: points[0].lng(),
				west: points[1].lng(),
			};
			initialValues.bounds = this._bounds;
		}

		if (color) {
			this._color = color;
			initialValues.fillColor = this._color;
			initialValues.strokeColor = this._color;
		}

		if (map) {
			this._map = map;
			initialValues.map = this._map;
		}

		this.overlay = new window.google.maps.Rectangle(initialValues);

		this.label.setOptions({
			labelContent: this._name,
			position: this.overlay.getBounds().getCenter(),
			map: this._map,
		});

		if (this._map && fitBounds) this._map.fitBounds(this.overlay.getBounds());
	}

	addListeners() {
		var self = this;
		var centerOnLabelClick = window.google.maps.event.addListener(self.label, 'click', function () {
			self._map.fitBounds(self.overlay.getBounds());
		});

		self._listeners.push(centerOnLabelClick);
	}

	setOptions(options) {
		const { fitBounds = true } = options;
		super.setOptions(options);

		if (options.encodedObject) {
			var points = window.google.maps.geometry.encoding.decodePath(options.encodedObject);
			if (points.length) {
				this._bounds = {
					north: points[0].lat(),
					south: points[1].lat(),
					east: points[0].lng(),
					west: points[1].lng(),
				};
			}
		}

		this.overlay.setOptions({
			bounds: this._bounds,
			strokeColor: this._color,
			fillColor: this._color,
			map: this._map,
		});

		this.label.setOptions({
			labelContent: this._name,
			position: this.overlay.getBounds().getCenter(),
			map: this._map,
		});

		if (this._map && fitBounds) this._map.fitBounds(this.overlay.getBounds());
	}
}

class places {
	constructor(mapName) {
		this.mapName = mapName;
		this.listeners = [];
		this.overlay = null;
		this.color = '#0000ff';
		this.overlayType = 'circle';
		this.places = {};
		this.label = {};
		this.margin = {};
		this.duration = {};
		this.id = 0;
		this.encodedObject = {};
		this._overlayTypes = {
			[PLACES_TYPE.CIRCLE]: 'circle',
			[PLACES_TYPE.POLYGON]: 'polygon',
			[PLACES_TYPE.POLYLINE]: 'polyline',
			[PLACES_TYPE.RECTANGLE]: 'rectangle',
			[PLACES_TYPE.TEMPORAL]: 'temporal',
			[PLACES_TYPE.MARKER]: 'marker',
		};
		this.PolyUtil = new PolyUtil();
		this.onOverlayComplete = null;
		this.lineIsVisited = false;
		this.config = undefined;
		this.visitByEvent = undefined;
		this.inVisit = undefined;
		this.outVisit = undefined;
		this.radiusLabel = undefined;
	}

	addPlaceByMap = (id, place, map) => {
		if (id in this.places) {
			this.places[id].setOptions({
				...place,
				map,
				editable: false,
				draggable: false,
			});
			this.margin[id] = place.margin;
		} else if (place.type !== undefined) {
			place.type = place.type === INTEREST_TYPE.LINE ? 2 : place.type;
			switch (place.type) {
				case 2:
					var polyline = new polylinePlace({
						...place,
						lineOfInterst: true,
						map,
					});
					this.places[id] = polyline;
					this.margin[id] = place.margin;
					this.duration[id] = place.duration;
					this.encodedObject[id] = place.encodedObject;
					this.label[id] = false;
					this.id = id;
					this.lineIsVisited = place.lineIsVisited;
					break;
				default:
					break;
			}
			this.places[id].addListeners();
		}
	};
	addLineOfInterest = (id, place) => {
		var self = this;
		var map = window[self.mapName].map;
		if (id in this.places) {
			this.places[id].setOptions({
				...place,
				map,
				editable: false,
				draggable: false,
			});
		} else if (place.type !== undefined) {
			place.type = place.type === INTEREST_TYPE.LINE ? 2 : place.type;
			switch (place.type) {
				case 2:
					var polyline = new polylinePlace({
						...place,
						lineOfInterst: true,
						map,
						mapName: self.mapName,
					});
					this.places[id] = polyline;
					break;
				default:
					break;
			}
			this.places[id].addListeners();
		}
	};

	addPlace = (id, place, options) => {
		var self = this;
		var map = window[self.mapName].map;
		if (id in this.places) {
			this.places[id].setOptions({
				...place,
				map,
				editable: false,
				draggable: false,
			});
		} else if (place.type !== undefined) {
			place.type = place.type === INTEREST_TYPE.LINE ? 2 : place.type;
			var placeParams = { ...options, ...place, map };
			switch (place.type) {
				case PLACES_TYPE.CIRCLE:
				case PLACES_TYPE.TEMPORAL:
					var circle = new circlePlace(placeParams);
					this.places[id] = circle;
					break;
				case PLACES_TYPE.POLYGON:
					var polygon = new polygonPlace(placeParams);
					this.places[id] = polygon;
					break;
				case PLACES_TYPE.POLYLINE:
					var polyline = new polylinePlace(placeParams);
					this.places[id] = polyline;
					break;
				case PLACES_TYPE.RECTANGLE:
					var rectangle = new rectanglePlace(placeParams);
					this.places[id] = rectangle;
					break;
				default:
					break;
			}
			this.places[id].addListeners();
		}
	};

	removeAllPlace = () => {
		Object.keys(this.places).forEach((place) => {
			this.removePlace(place);
		});
	};

	removePlace = (id) => {
		if (this.places.hasOwnProperty(id)) {
			this.places[id].removeListeners();
			this.places[id].setMap(null);
			delete this.places[id];
		}
	};

	getEncodedObject() {
		var self = this;
		return new Promise((resolve, reject) => {
			if (!self.overlay) reject(new Error('overlay cannot be accessed!'));
			var encodedObject;
			switch (this.getOverlayType()) {
				case PLACES_TYPE.CIRCLE:
					encodedObject = window.google.maps.geometry.encoding.encodePath([self.overlay.getCenter()]);
					break;
				case PLACES_TYPE.POLYGON:
				case PLACES_TYPE.POLYLINE:
					encodedObject = window.google.maps.geometry.encoding.encodePath(self.overlay.getPath());
					break;
				case PLACES_TYPE.RECTANGLE:
					encodedObject = window.google.maps.geometry.encoding.encodePath([
						self.overlay.getBounds().getNorthEast(),
						self.overlay.getBounds().getSouthWest(),
					]);
					break;
				default:
					break;
			}

			resolve(encodedObject);
		});
	}

	getCircleEncodedObject({ latitude, longitude }) {
		return new Promise((resolve, reject) => {
			if (!latitude && !longitude) reject(new Error('Latitude and Longitude cannot be empty'));
			try {
				var center = new window.google.maps.LatLng(latitude, longitude);
				var encodedObject = window.google.maps.geometry.encoding.encodePath([center]);
				resolve(encodedObject);
			} catch (error) {
				reject(error);
			}
		});
	}

	getOverlayRadius() {
		if (this.getOverlayType() === 0) return this.overlay.getRadius();
		return 0;
	}

	getOverlay() {
		return this.overlay;
	}

	getOverlayType() {
		switch (this.overlayType) {
			case 'circle':
				return PLACES_TYPE.CIRCLE;
			case 'polygon':
				return PLACES_TYPE.POLYGON;
			case 'polyline':
				return PLACES_TYPE.POLYLINE;
			case 'rectangle':
				return PLACES_TYPE.RECTANGLE;
			case 'temporal':
				return PLACES_TYPE.TEMPORAL;
			default:
				return;
		}
	}

	setControlsVisibility(value) {
		var self = this;
		if (value) {
			self.oldControls = [];
			window[self.mapName].map.controls[window.google.maps.ControlPosition.TOP_CENTER].forEach(() => {
				var control = window[self.mapName].map.controls[window.google.maps.ControlPosition.TOP_CENTER].pop();
				self.oldControls.push(control);
			});
		} else if (self.oldControls) {
			self.oldControls.reverse().forEach((control) => {
				window[self.mapName].map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(control);
			});
		}
		self.drawingManager.setOptions({ drawingControl: value });
	}

	checkIfExist(drawingModes) {
		if (!drawingModes) drawingModes = ['circle', 'polygon', 'polyline', 'rectangle'];
		var self = this;
		return new Promise((resolve, rejects) => {
			try {
				if (!self.drawingManager) {
					self.drawingManager = new window.google.maps.drawing.DrawingManager({
						drawingMode: window.google.maps.drawing.OverlayType.CIRCLE,
						drawingControl: false,
						drawingControlOptions: {
							position: window.google.maps.ControlPosition.TOP_CENTER,
							drawingModes: drawingModes,
						},
						circleOptions: {
							editable: true,
							draggable: true,
							fillColor: self.color,
							fillOpacity: 0.3,
							strokeWeight: 2,
							strokeColor: self.color,
							strokeOpacity: 0.7,
						},
						polygonOptions: {
							editable: true,
							draggable: true,
							fillColor: self.color,
							fillOpacity: 0.3,
							strokeWeight: 2,
							strokeColor: self.color,
							strokeOpacity: 0.7,
						},
						polylineOptions: {
							editable: true,
							draggable: true,
							fillColor: self.color,
							fillOpacity: 0.3,
							strokeWeight: 2,
							strokeColor: self.color,
							strokeOpacity: 0.7,
						},
						rectangleOptions: {
							editable: true,
							draggable: true,
							fillColor: self.color,
							fillOpacity: 0.3,
							strokeWeight: 2,
							strokeColor: self.color,
							strokeOpacity: 0.7,
						},
					});
				}
				resolve(self.drawingManager);
			} catch (e) {
				rejects(e);
			}
		});
	}

	setColor(color) {
		var self = this;
		self.color = color;
		if (self.drawingManager) {
			self.drawingManager.setOptions({
				circleOptions: {
					editable: true,
					draggable: true,
					fillColor: self.color,
					fillOpacity: 0.3,
					strokeWeight: 2,
					strokeColor: self.color,
					strokeOpacity: 0.7,
				},
				polygonOptions: {
					editable: true,
					draggable: true,
					fillColor: self.color,
					fillOpacity: 0.3,
					strokeWeight: 2,
					strokeColor: self.color,
					strokeOpacity: 0.7,
				},
				polylineOptions: {
					editable: true,
					draggable: true,
					fillColor: self.color,
					fillOpacity: 0.3,
					strokeWeight: 2,
					strokeColor: self.color,
					strokeOpacity: 0.7,
				},
				rectangleOptions: {
					editable: true,
					draggable: true,
					fillColor: self.color,
					fillOpacity: 0.3,
					strokeWeight: 2,
					strokeColor: self.color,
					strokeOpacity: 0.7,
				},
			});
		}

		if (self.overlay) {
			self.overlay.setOptions({
				fillColor: self.color,
				fillOpacity: 0.3,
				strokeWeight: 2,
				strokeColor: self.color,
				strokeOpacity: 0.7,
			});
		}
	}
	checkIfPolyLineExist(drawingModes) {
		if (!drawingModes) drawingModes = ['polyline'];
		var self = this;
		return new Promise((resolve, rejects) => {
			try {
				if (!self.drawingManager) {
					self.drawingManager = new window.google.maps.drawing.DrawingManager({
						drawingMode: window.google.maps.drawing.OverlayType.POLYLINE,
						drawingControl: false,
						drawingControlOptions: {
							position: window.google.maps.ControlPosition.TOP_CENTER,
							drawingModes: drawingModes,
						},
						polylineOptions: {
							editable: true,
							draggable: true,
							fillColor: self.color,
							fillOpacity: 0.3,
							strokeWeight: 6,
							strokeColor: self.color,
							strokeOpacity: 0.7,
						},
					});
				}
				resolve(self.drawingManager);
			} catch (e) {
				rejects(e);
			}
		});
	}

	createLabel(self, labelContent) {
		return new MarkerWithLabel({
			draggable: false,
			raiseOnDrag: true,
			map: window[self.mapName].map,
			position: self.overlay.getBounds().getCenter(),
			labelContent: unitSystem.distance.toString(unitSystem.distance.revertFromMeters(labelContent)),
			labelClass: 'places-label',
			icon: '..',
		});
	}

	enableDrawing() {
		var self = this;

		return self
			.checkIfExist()
			.then((exist) => {
				if (!exist) this.createDrawingManager();
				return;
			})
			.then(() => {
				self.removeOverlay();
				self.setColor('#0000ff');
				self.setControlsVisibility(true);
				self.drawingManager.setOptions({
					drawingMode: 'circle',
					map: window[self.mapName].map,
				});
				var overlaycomplete = window.google.maps.event.addListener(
					self.drawingManager,
					'overlaycomplete',
					function (event) {
						self.setOverlay(event.overlay);
						self.overlayType = event.type;

						switch (event.type) {
							case 'polygon':
							case 'polyline':
								self.overlay.setOptions({
									geodesic: true,
								});
								break;
							case 'circle':
								self.radiusLabel = self.createLabel(self, self.overlay.getRadius());

								var updateRadius = function () {
									if (self.radiusLabel) self.radiusLabel.setMap(null);
									self.radiusLabel = self.createLabel(self, self.overlay.getRadius());
								};

								var displayRadius = window.google.maps.event.addListener(
									self.overlay,
									'radius_changed',
									updateRadius
								);

								var updateLabelRadiusPosition = window.google.maps.event.addListener(
									self.overlay,
									'center_changed',
									updateRadius
								);

								var dragRadiusPosition = window.google.maps.event.addListener(
									self.overlay,
									'drag',
									updateRadius
								);

								self.listeners.push(displayRadius);
								self.listeners.push(updateLabelRadiusPosition);
								self.listeners.push(dragRadiusPosition);

								break;
							default:
								break;
						}

						self.overlay.setOptions({
							draggable: true,
							editable: true,
						});

						self.drawingManager.setOptions({
							drawingMode: null,
							drawingControl: false,
						});

						if (self.onOverlayComplete && typeof self.onOverlayComplete == 'function')
							self.onOverlayComplete({
								overlay: self.overlay,
								type: self.getOverlayType(),
							});
					}
				);

				self.listeners.push(overlaycomplete);

				var rightclick = window.google.maps.event.addListener(
					window[self.mapName].map,
					'rightclick',
					function (event) {
						if (event.vertex != null && self.getPath().getLength() > 3) {
							self.getPath().removeAt(event.vertex);
						}
					}
				);

				self.listeners.push(rightclick);
			});
	}

	disableDrawing() {
		var self = this;
		return self.checkIfExist().then(() => {
			self.setControlsVisibility(false);
			self.removeOverlay();
			self.overlay = null;
			self.drawingManager.setMap(null);
			self.listeners.forEach((listener) => {
				window.google.maps.event.removeListener(listener);
			});
			self.listeners = [];
			if (self.radiusLabel) self.radiusLabel.setMap(null);
			return;
		});
	}

	enableDrawingPolyline() {
		var self = this;

		return self
			.checkIfPolyLineExist()
			.then((exist) => {
				if (!exist) this.createDrawingManager();
				return;
			})
			.then(() => {
				self.removeOverlay();
				self.setColor('#0000ff');
				self.setControlsVisibility(true);
				self.drawingManager.setOptions({
					drawingMode: 'polyline',
					map: window[self.mapName].map,
				});
				var overlaycomplete = window.google.maps.event.addListener(
					self.drawingManager,
					'overlaycomplete',
					function (event) {
						self.setOverlay(event.overlay);
						self.overlayType = event.type;

						switch (event.type) {
							case 'polyline':
								self.overlay.setOptions({
									geodesic: true,
								});
								break;
							default:
								break;
						}

						self.overlay.setOptions({
							draggable: true,
							editable: true,
						});

						self.drawingManager.setOptions({
							drawingMode: null,
							drawingControl: false,
						});

						if (self.onOverlayComplete && typeof self.onOverlayComplete == 'function')
							self.onOverlayComplete({
								overlay: self.overlay,
								type: self.getOverlayType(),
							});
					}
				);

				self.listeners.push(overlaycomplete);

				var rightclick = window.google.maps.event.addListener(
					window[self.mapName].map,
					'rightclick',
					function (event) {
						if (event.vertex != null && self.getPath().getLength() > 3) {
							self.getPath().removeAt(event.vertex);
						}
					}
				);

				self.listeners.push(rightclick);
			});
	}

	setupDrawingManagerPOI(drawingModes) {
		if (!drawingModes) drawingModes = ['marker'];
		return new Promise((resolve, rejects) => {
			try {
				if (!this.drawingManager) {
					this.drawingManager = new window.google.maps.drawing.DrawingManager({
						drawingMode: window.google.maps.drawing.OverlayType.MARKER,
						drawingControl: false,
						drawingControlOptions: {
							position: window.google.maps.ControlPosition.TOP_CENTER,
							drawingModes: drawingModes,
						},
					});
				}
				resolve(this.drawingManager);
			} catch (error) {
				rejects(error);
			}
		});
	}

	async enableDrawingPointOfInterest() {
		var self = this;
		const drawingManagerPOI = await this.setupDrawingManagerPOI();
		if (!drawingManagerPOI) {
			this.createDrawingManager();
		}
		this.removeOverlay();
		this.setColor('#0000ff');
		this.setControlsVisibility(true);
		this.drawingManager.setOptions({
			drawingMode: 'marker',
			map: window[this.mapName].map,
		});
		const overlaycomplete = window.google.maps.event.addListener(
			self.drawingManager,
			'overlaycomplete',
			function (event) {
				self.setOverlay(event.overlay);
				self.overlayType = event.type;
				switch (event.type) {
					case 'marker':
						self.overlay.setOptions({
							geodesic: true,
						});
						break;
					default:
						break;
				}

				self.overlay.setOptions({
					draggable: true,
					editable: true,
				});

				self.drawingManager.setOptions({
					drawingMode: null,
					drawingControl: false,
				});

				if (self.onOverlayComplete && typeof self.onOverlayComplete == 'function')
					self.onOverlayComplete({
						overlay: self.overlay,
						type: self.getOverlayType(),
					});
			}
		);
		self.listeners.push(overlaycomplete);
	}

	removeOverlay() {
		if (this.overlay != null) {
			this.overlay.setMap(null);
			this.overlay = null;
		}
	}

	setOverlay(overlay) {
		this.overlay = overlay;
	}

	editPlace(place) {
		this.addPlace(place.id, place);
		this.overlayType = this._overlayTypes[place.type];
		this.places[place.id].setOptions({ map: null });
		this.overlay = this.places[place.id].Overlay;
		this.overlay.setOptions({ editable: true, draggable: true });
		window[this.mapName].map.fitBounds(this.places[place.id].bounds);
	}

	removeLable() {
		Object.keys(this.places).forEach((key) => {
			var label = !this.label[key];
			this.places[key].setOptions({ label });
			this.label[key] = label;
		});
	}

	centerPlace = (place) => {
		window[this.mapName].map.fitBounds(this.places[place.id].bounds);
	};

	isInside = (polyPoints, point, geodesic, tolerance) =>
		this.PolyUtil.LocationIndexOnEdgeOrPath(point, polyPoints, false, geodesic, tolerance);

	isPointInMargin = (point, cb) => {
		Object.keys(this.places).map((key) => {
			var encodedObject = this.encodedObject[key];
			return cb(
				this.isInside(this.getPolylinePoint(encodedObject), point, true, this.margin[key]),
				encodedObject
			);
		});
	};

	getPolylinePoint = (encodedObject) => window.google.maps.geometry.encoding.decodePath(encodedObject);

	analizeVisit = (trace, config) => {
		this.config = config;
		this.isPointInMargin(trace, (isInside, encodedObject) => {
			if (isInside !== -1) {
				trace.isInLine = true;
				if (!this.visitByEvent) {
					//si esta dentro del punto y no se detecto evento
					if (trace.events.includes(2) || trace.events.includes(9) || trace.events.includes(46)) {
						// si hay un evento 2, 9, 46 detecta visita inmediata
						//sumar visitas
						if (!this.lineIsVisited) this.config.eventEmmiter.emitEvent('line_is_visited', [this.id]);
						this.lineIsVisited = true;
						// reset flags
						this.inVisit = undefined;
						this.outVisit = undefined;
						this.visitByEvent = true;
					} else if (!this.inVisit)
						// si esta adentro pero es el primer punto adentro
						this.inVisit = trace;
				}
			} else if (isInside === -1 && this.visitByEvent) {
				//si no esta adentro del punto y tiene la flag de visita por evento reiniciar la flag
				this.visitByEvent = false;
			} else if (this.inVisit) {
				// si no esta adentro y tenia una visita
				this.outVisit = trace;
			}

			if (this.inVisit && this.outVisit) {
				// si despues de una entrada hay una salida

				let duration = moment.utc(this.outVisit.utcDate).diff(moment.utc(this.inVisit.utcDate), 'seconds');
				if (duration >= this.duration[this.id]) {
					if (!this.lineIsVisited) this.config.eventEmmiter.emitEvent('line_is_visited', [this.id]);
					this.lineIsVisited = true;
				}
				this.inVisit = undefined;
				this.outVisit = undefined;
				this.visitByEvent = false;
			}
			const options = {
				encodedObject,
				color: this.lineIsVisited ? '#1890ff' : '#FF0000',
				strokeWeight: 9,
			};
			this.places[this.id].setOptions(options);
		});
	};
}

export default places;

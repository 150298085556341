import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DownloadOutlined } from '@ant-design/icons';
import { translate } from '@optimusgps/optimus-intl';
import { Button, Col, Divider } from 'antd';
import PropTypes from 'prop-types';

const Buttons = ({ isExport, loading, handleCancel, exportButton }) => {
	const mainButtons = isExport ? (
		<Col span={24} style={{ textAlign: 'right' }}>
			<Divider style={{ marginTop: '20px', marginBottom: '10px' }} />
			<Button key="cancel" onClick={() => handleCancel(false)} style={{ marginRight: '5px' }}>
				<FormattedMessage {...translate('cancel')} />
			</Button>
			,
			<Button key="submit" type="primary" htmlType="submit" loading={loading}>
				<DownloadOutlined />
				<FormattedMessage {...translate('download')} />
			</Button>
		</Col>
	) : (
		<>
			<Button
				type="primary"
				htmlType="submit"
				loading={loading}
				data-testid="generateReport-btn"
				style={{ marginRight: '20px' }}
			>
				<FormattedMessage {...translate('generateReport')} />
			</Button>
			{exportButton}
		</>
	);

	return mainButtons;
};
Buttons.defaultProps = {
	exportButton: null,
};
Buttons.propTypes = {
	loading: PropTypes.bool.isRequired,
	isExport: PropTypes.bool,
	handleCancel: PropTypes.func,
};

export default Buttons;

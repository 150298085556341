import axios from 'axios';
import MapService from '@/features/map/map.service';
import { selectedMapTrail } from '../../constants/global';
import { getMapInstance } from '../../utilities/mapUtilities';
import { UpdateCameraInfo } from '../camera/actions';
import { UpdateCommandsInfo } from '../commands/actions';
import { GetDriverInfo, GetExtraInfo, GetVehicleInfo } from '../deviceInformation/actions';
import { RequestVideoLoading } from '../multimedia/actions';
import { LoadingTrace, OpenMultimediaByTrackingHistoryId, UpdateDeviceEvent } from '../temporal/actions';
import {
	END_FETCH_MAP_DEVICES,
	FETCH_MAP_DEVICES,
	FETCH_SELECTED_DEVICE,
	INIT_FETCH_MAP_DEVICES,
	TOGGLE_REFRESH,
	UPDATE_MAP_DEVICES,
} from './actionTypes';

export const GetDevices = (cancelToken) => async (dispatch) => {
	try {
		dispatch({ type: INIT_FETCH_MAP_DEVICES });
		const response = await MapService.getMapDevices(cancelToken);
		let devices = [];
		if (response) devices = response;
		dispatch({ type: FETCH_MAP_DEVICES, devices: devices });
		return Promise.resolve(devices);
	} catch (error) {
		return Promise.reject(error);
	} finally {
		dispatch({ type: END_FETCH_MAP_DEVICES });
	}
};
export const GetDevicesLastReportAction = async (deviceIds) => {
	const filter = {
		where: {
			deviceId: {
				op: 'in',
				value: deviceIds,
			},
		},
	};
	const filterString = JSON.stringify(filter);
	const { data } = await axios.get(`/v2/devices/last-report?filter=${encodeURIComponent(filterString)}`);

	return data?.data[0] ?? null;
};
export const GetNearestUnit = async ({ latitude, longitude }) => {
	try {
		const params = {
			latitude,
			longitude,
		};
		const response = await axios.get(`/TrackingHistories/nearestUnit`, { params });
		return response.data;
	} catch (error) {
		return [];
	}
};

export const ToggleRefresh = () => (dispatch, getState) => {
	let state = getState();
	let refresh = state.map.refresh;
	dispatch({ type: TOGGLE_REFRESH, refresh: !refresh });
};

export const MasterOfMarkers =
	({ map, mapName, eventEmmiter, openInfoWindow, openPoiInfoWindow }) =>
	(dispatch, getState) => {
		let state = getState();
		let messages = state.language.messages;
		let locale = state.language.code;
		let userId = state.auth.user.id;
		let isImpersonating = state.impersonate.isImpersonating;
		let roles = state.auth.roles;
		let impersonatorRoles = state.impersonate?.auth?.roles;

		let otherUnits = state.map.otherUnits;
		let centerOnSelect = state.configuration.centerOnSelect;
		let centerOnUpdate = state.configuration.centerOnUpdate;
		let traceColor = state.configuration.traceColor;
		let showTrace = state.configuration.showTrace;
		let movementColor = state.configuration.movementColor;
		let parkingColor = state.configuration.parkingColor;
		let idleColor = state.configuration.idleColor;
		let traceBySpeedIsActive = state.configuration.traceBySpeedIsActive;
		let speedRangesConfiguration = state.configuration.speedRangesConfiguration;
		let clusterDevices = state.configuration.clusterDevices;

		let options = {
			messages,
			showOthers: otherUnits,
			dispatch: dispatch,
			locale: locale,
			mapName: mapName,
			centerOnSelect: centerOnSelect,
			centerOnUpdate: centerOnUpdate,
			showTrace: showTrace,
			traceColor: traceColor,
			movementColor: movementColor,
			parkingColor: parkingColor,
			idleColor: idleColor,
			selectfirst: true,
			isMainMap: true,
			selectedTrail: selectedMapTrail.All,
			eventEmmiter: eventEmmiter,
			roles: roles,
			impersonatorRoles,
			userId: userId,
			clusterDevices,
			speedRange: {
				isActive: traceBySpeedIsActive,
				configuration: speedRangesConfiguration,
			},
			isImpersonating,

			SelectDevice: (id) => dispatch(SelectDevice(id)),
			UpdateDevice: (device) => dispatch(UpdateDevice(device)),
			UpdateDeviceEvent: () => dispatch(UpdateDeviceEvent()),
			LoadingTrace: () => dispatch(LoadingTrace()),
			GetVehicleInfo: (id) => dispatch(GetVehicleInfo(id)),
			GetDriverInfo: (id) => dispatch(GetDriverInfo(id)),
			GetExtraInfo: (id) => dispatch(GetExtraInfo(id)),
			OpenMultimediaByTrackingHistoryId: (config) => dispatch(OpenMultimediaByTrackingHistoryId(config)),
			StropRequestVideoLoading: () => dispatch(RequestVideoLoading()),
			OpenInfoWindow: (params) => openInfoWindow(params),
			OpenPoiInfoWindow: (params) => openPoiInfoWindow(params),
			GetCameraInfo: (camera) => dispatch(UpdateCameraInfo(camera)),
			GetCommandsInfo: (commands) => dispatch(UpdateCommandsInfo(commands)),
		};
		dispatch({ type: INIT_FETCH_MAP_DEVICES });
		MapService.getMapDevices()
			.then((devices) => {
				dispatch({
					type: FETCH_MAP_DEVICES,
					devices: devices,
				});

				return devices;
			})
			.then(loadCellTowerData)
			.then(({ cellTowerData, devices }) => {
				dispatch({ type: END_FETCH_MAP_DEVICES });
				options.selectfirst = devices.length === 1;
				options.cellTowerData = cellTowerData;
				getMapInstance(map, devices, options);
			});
	};

const loadCellTowerData = async (devices) => {
	try {
		const cellTowerData = [];
		devices.forEach(({ id, signal, longitude, latitude }) => {
			if (!signal && !longitude && !latitude) {
				cellTowerData.push(id);
			}
		});
		const dataCellTower = await GetCellTower(cellTowerData);
		if (dataCellTower.length) {
			const geolocationData = await PostGeolocation(dataCellTower);
			return { cellTowerData: geolocationData, devices };
		}
		return { cellTowerData: [], devices };
	} catch (error) {
		return { cellTowerData: [], devices };
	}
};

export const MultipleMapMasterOfMarkers =
	({ map, mapName, selectedDevices, eventEmmiter, openInfoWindow, openPoiInfoWindow }) =>
	(dispatch, getState) => {
		let state = getState();
		let messages = state.language.messages;
		let locale = state.language.code;
		let devices = state.map.devices;
		let roles = state.auth.roles;
		let userId = state.auth.user.id;
		let isImpersonating = state.impersonate.isImpersonating;

		let otherUnits = state.map.otherUnits;
		let centerOnSelect = state.configuration.centerOnSelect;
		let centerOnUpdate = state.configuration.centerOnUpdate;
		let traceColor = state.configuration.traceColor;
		let showTrace = state.configuration.showTrace;
		let movementColor = state.configuration.movementColor;
		let parkingColor = state.configuration.parkingColor;
		let idleColor = state.configuration.idleColor;
		let traceBySpeedIsActive = state.configuration.traceBySpeedIsActive;
		let speedRangesConfiguration = state.configuration.speedRangesConfiguration;
		let clusterDevices = state.configuration.clusterDevices;

		let options = {
			messages,
			showOthers: otherUnits,
			dispatch: dispatch,
			locale: locale,
			mapName: mapName,
			centerOnSelect: centerOnSelect,
			centerOnUpdate: centerOnUpdate,
			showTrace: showTrace,
			traceColor: traceColor,
			movementColor: movementColor,
			parkingColor: parkingColor,
			idleColor: idleColor,
			selectfirst: selectedDevices.length === 1,
			selectedTrail: selectedMapTrail.LAST,
			forceReload: true,
			multipleMap: true,
			eventEmmiter: eventEmmiter,
			roles: roles,
			userId: userId,
			clusterDevices,
			speedRange: {
				isActive: traceBySpeedIsActive,
				configuration: speedRangesConfiguration,
			},
			isImpersonating,

			SelectDevice: (id) => dispatch(SelectDevice(id)),
			UpdateDeviceEvent: () => dispatch(UpdateDeviceEvent()),
			LoadingTrace: () => {},
			GetVehicleInfo: (id) => dispatch(GetVehicleInfo(id)),
			GetDriverInfo: (id) => dispatch(GetDriverInfo(id)),
			GetExtraInfo: (id) => dispatch(GetExtraInfo(id)),
			OpenInfoWindow: (params) => openInfoWindow(params),
			OpenPoiInfoWindow: (params) => openPoiInfoWindow(params),
			GetCameraInfo: (camera) => dispatch(UpdateCameraInfo(camera)),
			GetCommandsInfo: (commands) => dispatch(UpdateCommandsInfo(commands)),
		};
		getMapInstance(
			map,
			devices.filter((device) => selectedDevices.indexOf(device.id) > -1),
			options
		);
	};

export const RouteMonitorMasterOfMarkers =
	({ map, mapName, eventEmmiter, openInfoWindow, openPoiInfoWindow }) =>
	(dispatch, getState) => {
		let state = getState();
		let messages = state.language.messages;
		let locale = state.language.code;
		let roles = state.auth.roles;
		let isImpersonating = state.impersonate.isImpersonating;

		let clientId = state.auth.user.clientId;
		let otherUnits = state.map.otherUnits;
		let centerOnSelect = state.configuration.centerOnSelect;
		let centerOnUpdate = state.configuration.centerOnUpdate;
		let traceColor = state.configuration.traceColor;
		let showTrace = state.configuration.showTrace;
		let movementColor = state.configuration.movementColor;
		let parkingColor = state.configuration.parkingColor;
		let idleColor = state.configuration.idleColor;
		let traceBySpeedIsActive = state.configuration.traceBySpeedIsActive;
		let speedRangesConfiguration = state.configuration.speedRangesConfiguration;
		let clusterDevices = state.configuration.clusterDevices;

		let options = {
			messages,
			showOthers: otherUnits,
			dispatch: dispatch,
			locale: locale,
			mapName: mapName,
			centerOnSelect: centerOnSelect,
			centerOnUpdate: centerOnUpdate,
			showTrace: showTrace,
			traceColor: traceColor,
			movementColor: movementColor,
			parkingColor: parkingColor,
			idleColor: idleColor,
			selectfirst: true,
			selectedTrail: selectedMapTrail.All,
			forceReload: true,
			routeMonitor: true,
			eventEmmiter: eventEmmiter,
			roles: roles,
			clientId: clientId,
			clusterDevices,
			speedRange: {
				isActive: traceBySpeedIsActive,
				configuration: speedRangesConfiguration,
			},
			isImpersonating,

			SelectDevice: (id) => dispatch(SelectDevice(id)),
			UpdateDeviceEvent: () => dispatch(UpdateDeviceEvent()),
			LoadingTrace: () => dispatch(LoadingTrace()),
			GetVehicleInfo: (id) => dispatch(GetVehicleInfo(id)),
			GetDriverInfo: (id) => dispatch(GetDriverInfo(id)),
			GetExtraInfo: (id) => dispatch(GetExtraInfo(id)),
			OpenInfoWindow: (params) => openInfoWindow(params),
			OpenPoiInfoWindow: (params) => openPoiInfoWindow(params),
			GetCameraInfo: (camera) => dispatch(UpdateCameraInfo(camera)),
			GetCommandsInfo: (commands) => dispatch(UpdateCommandsInfo(commands)),
		};
		getMapInstance(map, [], options);
	};

export const SelectDevice = (id) => {
	return {
		type: FETCH_SELECTED_DEVICE,
		selectedDevice: id,
	};
};

export const UpdateDevice = (newDevice) => (dispatch, getState) => {
	const {
		map: { devices },
	} = getState();
	dispatch({
		type: UPDATE_MAP_DEVICES,
		devices: devices.map((device) => (newDevice.id === device.id ? newDevice : device)),
	});
};

export const GetDashIndicators = async (deviceId) => {
	const response = await axios.get(`/DashIndicators/${deviceId}`);
	return response.data;
};

export const GetCellTower = async (devices) => {
	const data = {
		params: { devices },
	};
	const response = await axios.get('/CellTowerTriangulations', data);
	return response.data;
};

export const PostGeolocation = async (devices) => {
	const response = await axios.post('/CellTowerTriangulations/Geolocation', {
		devices,
	});
	return response.data;
};

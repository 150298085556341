import compile from 'string-template/compile';
import unitSystem from '../unitSystems';

export const MapButton = (controlDiv, map, label, controlPosition, action) => {
	let buttonUI = document.createElement('div');
	buttonUI.style.backgroundColor = '#fff';
	buttonUI.style.border = '2px solid #fff';
	buttonUI.style.borderRadius = '2px';
	buttonUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
	buttonUI.style.cursor = 'pointer';
	buttonUI.style.textAlign = 'center';
	buttonUI.id = 'buttonUI';
	controlDiv.appendChild(buttonUI);

	// Set CSS for the control interior
	let buttonText = document.createElement('div');
	buttonText.id = 'buttonText';
	buttonText.innerHTML = label;
	buttonText.style.color = 'rgb(86, 86, 86)';
	buttonText.style.fontFamily = 'Roboto,Arial,sans-serif';
	buttonText.style.fontSize = '15px';
	buttonText.style.padding = '2px 8px';
	buttonText.style.fontWeight = 500;

	buttonUI.appendChild(buttonText);

	buttonUI.addEventListener('click', function () {
		action();
	});

	controlDiv.index = 1;
	controlDiv.style['padding-top'] = '10px';

	map.controls[controlPosition].push(controlDiv);
};

export const TrafficButton = (controlDiv, map, label, controlPosition) => {
	let buttonUI = document.createElement('div');
	buttonUI.style.backgroundColor = '#fff';
	buttonUI.style.border = '2px solid #fff';
	buttonUI.style.borderRadius = '2px';
	buttonUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
	buttonUI.style.cursor = 'pointer';
	buttonUI.style.textAlign = 'center';
	buttonUI.style.display = 'flex';
	buttonUI.style.flexDirection = 'row';
	buttonUI.id = 'buttonUI';

	controlDiv.appendChild(buttonUI);

	// Set CSS for the control interior
	let buttonText = document.createElement('div');
	buttonText.id = 'buttonText';
	buttonText.style.color = 'rgb(86, 86, 86)';
	buttonText.style.fontFamily = 'Roboto,Arial,sans-serif';
	buttonText.style.fontSize = '15px';
	buttonText.style.padding = '2px 8px';
	buttonText.style.fontWeight = 500;

	buttonUI.appendChild(buttonText);
	let MAP = map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

	let trafficComponent = map.props.children.filter((child) => {
		if (child && child.key && child.key === 'KmlLayer') {
			return child;
		}
		return false;
	})[0];

	let trafficLayer = new window.google.maps.TrafficLayer();

	if (trafficComponent.props.options.active) {
		trafficLayer.setMap(MAP);
		buttonText.innerHTML = label;
		buttonUI.style.backgroundColor = 'rgb(25 144 255)';
		buttonUI.style.border = '2px solid rgb(25 144 255)';
		buttonText.style.color = '#fff';
	} else {
		buttonUI.style.backgroundColor = '#fff';
		buttonUI.style.border = '2px solid #fff';
		buttonText.style.color = 'rgb(86, 86, 86)';
		buttonText.innerHTML = label;
	}

	buttonUI.addEventListener('click', function () {
		if (typeof trafficLayer.getMap() == 'undefined' || trafficLayer.getMap() === null) {
			trafficLayer.setMap(MAP);
			buttonText.innerHTML = label;
			buttonUI.style.backgroundColor = 'rgb(25 144 255)';
			buttonUI.style.border = '2px solid rgb(25 144 255)';
			buttonText.style.color = '#fff';
		} else {
			trafficLayer.setMap(null);
			buttonUI.style.backgroundColor = '#fff';
			buttonUI.style.border = '2px solid #fff';
			buttonText.style.color = 'rgb(86, 86, 86)';
			buttonText.innerHTML = label;
		}
	});

	controlDiv.index = 1;
	controlDiv.style['padding-top'] = '10px';
	MAP.controls[controlPosition].push(controlDiv);
};

export const MapSettingsButton = (controlDiv, map, label, controlPosition, eventEmmiter) => {
	const buttonUI = document.createElement('div');
	buttonUI.style.backgroundColor = '#fff';
	buttonUI.style.border = '2px solid #fff';
	buttonUI.style.borderRadius = '2px';
	buttonUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
	buttonUI.style.cursor = 'pointer';
	buttonUI.style.textAlign = 'center';
	buttonUI.style.display = 'flex';
	buttonUI.style.flexDirection = 'row';
	buttonUI.id = 'buttonUI';

	controlDiv.appendChild(buttonUI);

	// Set CSS for the control interior
	const buttonText = document.createElement('div');
	buttonText.id = 'buttonText';
	buttonText.innerHTML = label;
	buttonText.style.color = 'rgb(86, 86, 86)';
	buttonText.style.fontFamily = 'Roboto,Arial,sans-serif';
	buttonText.style.fontSize = '15px';
	buttonText.style.padding = '2px 8px';
	buttonText.style.fontWeight = 500;

	buttonUI.appendChild(buttonText);

	buttonUI.addEventListener('click', function () {
		eventEmmiter.emitEvent('openMapSettings', []);
	});

	const MAP = map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

	controlDiv.index = 1;
	controlDiv.style['padding-top'] = '10px';
	controlDiv.style['margin'] = '0px 5px';
	MAP.controls[controlPosition].push(controlDiv);
};

export const RulerButton = (controlDiv, map, label, controlPosition) => {
	let RulerControl = (labelDiv) => {
		let me = {
			pathCoordinates: [],
			mapOnClickListener: null,
			mapOnRightClickListener: null,
			path: null,
		};

		me.initializePathDrawing = () => {
			me.path = new window.google.maps.Polyline({
				path: me.pathCoordinates,
				strokeColor: '#000000',
				strokeOpacity: 1.0,
				strokeWeight: 2,
				editable: true,
				draggable: true,
			});

			window.google.maps.event.addListener(me.path, 'dragend');
			window.google.maps.event.addListener(me.path.getPath(), 'insert_at', me.getDistance);
			window.google.maps.event.addListener(me.path.getPath(), 'remove_at', me.getDistance);
			window.google.maps.event.addListener(me.path.getPath(), 'set_at', me.getDistance);

			me.mapOnClickListener = window.google.maps.event.addListener(map, 'click', me.addPoint);
			me.mapOnRightClickListener = window.google.maps.event.addListener(map, 'rightclick', me.removePoint);
			me.path.setMap(map);
			me.pathCoordinates = me.path.getPath();

			labelDiv.innerHTML = unitSystem.distance.toString(0);
		};

		me.removePoint = () => {
			me.pathCoordinates = me.path.getPath();
			if (me.pathCoordinates.length) me.pathCoordinates.pop();
		};

		me.addPoint = (event) => {
			me.pathCoordinates = me.path.getPath();

			me.pathCoordinates.push(event.latLng);
		};

		me.destroypathDrawing = () => {
			window.google.maps.event.removeListener(me.mapOnClickListener);
			window.google.maps.event.removeListener(me.mapOnRightClickListener);
			me.pathCoordinates = [];
			me.path.setMap(null);

			labelDiv.innerHTML = label;
			// map.controls[window.google.maps.ControlPosition.TOP_CENTER].removeAt(controlIndex);
		};
		//This will overwrite the current path with the new one
		me.getDistance = () => {
			let pathLength = me.path.getPath().getLength();
			let fullDistance = 0;
			if (pathLength > 1) {
				for (let i = 0; i < pathLength - 1; i++) {
					let firstPoint = me.path.getPath().getAt(i);
					let secondPoint = me.path.getPath().getAt(i + 1);
					let distanceBetweenPoints = me.distanceInKm(
						firstPoint.lat(),
						firstPoint.lng(),
						secondPoint.lat(),
						secondPoint.lng()
					);
					fullDistance += distanceBetweenPoints;
				}
			} else {
				fullDistance = 0;
			}

			labelDiv.innerHTML = unitSystem.distance.toString(fullDistance);
		};

		me.distanceInKm = (lat1, lon1, lat2, lon2) => {
			let R = 6371; // km (change this constant to get miles)
			let dLat = ((lat2 - lat1) * Math.PI) / 180;
			let dLon = ((lon2 - lon1) * Math.PI) / 180;
			let a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos((lat1 * Math.PI) / 180) *
					Math.cos((lat2 * Math.PI) / 180) *
					Math.sin(dLon / 2) *
					Math.sin(dLon / 2);
			let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			let d = R * c;
			return d;
		};

		return me;
	};

	let buttonUI = document.createElement('div');
	buttonUI.style.backgroundColor = '#fff';
	buttonUI.style.border = '2px solid #fff';
	buttonUI.style.borderRadius = '2px';
	buttonUI.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
	buttonUI.style.cursor = 'pointer';
	buttonUI.style.textAlign = 'center';
	buttonUI.style.display = 'flex';
	buttonUI.style.flexDirection = 'row';
	buttonUI.id = 'buttonUI';

	controlDiv.appendChild(buttonUI);

	// Set CSS for the control interior
	let buttonText = document.createElement('div');
	buttonText.id = 'buttonText';
	buttonText.innerHTML = label;
	buttonText.style.color = 'rgb(86, 86, 86)';
	buttonText.style.fontFamily = 'Roboto,Arial,sans-serif';
	buttonText.style.fontSize = '15px';
	buttonText.style.padding = '2px 8px';
	buttonText.style.fontWeight = 500;

	buttonUI.appendChild(buttonText);
	let ruler = RulerControl(buttonText);
	let drawing = false;
	buttonUI.addEventListener('click', function () {
		if (!drawing) {
			ruler.initializePathDrawing();
			drawing = true;
			buttonUI.style.backgroundColor = 'rgb(25 144 255)';
			buttonUI.style.border = '2px solid rgb(25 144 255)';
			buttonText.style.color = '#fff';
		} else {
			ruler.destroypathDrawing();
			drawing = false;
			buttonUI.style.backgroundColor = '#fff';
			buttonUI.style.border = '2px solid #fff';
			buttonText.style.color = 'rgb(86, 86, 86)';
		}
	});

	controlDiv.index = 1;
	controlDiv.style['padding-top'] = '10px';
	controlDiv.style['margin'] = '0px 5px';

	map.controls[controlPosition].push(controlDiv);
};

export const PoiContextMenu = (controlDiv, menu, messages) => {
	let ulUI = document.createElement('ul');
	ulUI.classList.add('poiContextMenu');

	let arrowUI = document.createElement('span');
	arrowUI.classList.add('arrow-up');

	menu.forEach((item) => {
		let iconUI = document.createElement('span');
		iconUI.classList.add('contextMenuIcon', item.icon);

		let labelUI = document.createElement('span');
		labelUI.classList.add('label');
		if (item.key in messages) {
			let labelCompiler = compile(messages[item.key]);
			labelUI.appendChild(document.createTextNode(labelCompiler({})));
		} else {
			labelUI.appendChild(document.createTextNode(`[${item.key}]`));
		}
		let liUI = document.createElement('li');
		liUI.classList.add('poiContextItem');
		liUI.addEventListener('click', item.action);
		liUI.appendChild(iconUI);
		liUI.appendChild(labelUI);

		ulUI.appendChild(liUI);
	});

	while (controlDiv.hasChildNodes()) {
		controlDiv.removeChild(controlDiv.firstChild);
	}

	controlDiv.appendChild(arrowUI);
	controlDiv.appendChild(ulUI);
};

export const PlacesSearch = (
	controlDiv,
	map,
	{ placeholder, title, feedback },
	controlPosition,
	eventEmmiter,
	GetDevices,
	mapName
) => {
	const searchUI = document.createElement('input');
	searchUI.setAttribute('type', 'search');
	searchUI.placeholder = placeholder;
	searchUI.classList.add('input');
	searchUI.style.marginLeft = '10px';
	searchUI.style.marginTop = '10px';
	searchUI.style.padding = '5px 10px';
	searchUI.style.background = 'white';

	controlDiv.appendChild(searchUI);

	const nearestdevices = document.createElement('div');
	nearestdevices.id = 'nearestDevices';
	controlDiv.appendChild(nearestdevices);

	const autocomplete = new window.google.maps.places.Autocomplete(searchUI);

	autocomplete.bindTo('bounds', map);

	// Set the data fields to return when the user selects a place.
	autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);

	autocomplete.addListener('place_changed', () => {
		var place = autocomplete.getPlace();
		if (mapName !== 'planner') {
			const { lat, lng } = place.geometry.location;

			NearestDeviceRender({
				GetDevices,
				nearestdevices,
				latitude: lat(),
				longitude: lng(),
				eventEmmiter,
				title,
				feedback,
			});
		}
		eventEmmiter.emitEvent('places_poi', [place]);
	});

	controlDiv.index = 1;
	controlDiv.classList.add('inputWrapper');
	controlDiv.id = 'inputWrapper';

	map.controls[controlPosition].push(controlDiv);
};

export const NearestDeviceRender = ({
	GetDevices,
	nearestdevices,
	latitude,
	longitude,
	eventEmmiter,
	title,
	feedback,
}) => {
	const spinSpan = document.createElement('div');
	spinSpan.classList.add('loader');
	nearestdevices.append(spinSpan);
	nearestdevices.classList.add('show');
	nearestdevices.innerHTML = '';
	nearestdevices.append(spinSpan);

	GetDevices({ latitude, longitude }).then((devices) => {
		nearestdevices.innerHTML = '';
		const titleDiv = document.createElement('div');
		titleDiv.innerHTML = title;
		titleDiv.classList.add('title');
		const closeSpam = document.createElement('span');
		closeSpam.classList.add('icon-close', 'closeSpam');
		titleDiv.append(closeSpam);
		closeSpam.addEventListener('click', () => {
			nearestdevices.classList.remove('show');
		});

		nearestdevices.append(titleDiv);
		if (devices.length) {
			devices.forEach((device) => {
				const { description } = device;
				let deviceDiv = document.createElement('div');
				deviceDiv.classList.add('deviceOption');
				deviceDiv.append(document.createTextNode(description));
				deviceDiv.addEventListener('click', () => {
					nearestdevices.classList.remove('show');
					eventEmmiter.emitEvent('share_nearest', [device]);
				});
				const shareSpan = document.createElement('span');
				shareSpan.classList.add('icon-redo2', 'shareIcon');
				deviceDiv.append(shareSpan);

				nearestdevices.append(deviceDiv);
			});
		} else {
			let noDevicesDiv = document.createElement('div');
			noDevicesDiv.classList.add('noDevicesDiv');
			noDevicesDiv.append(document.createTextNode(feedback));
			nearestdevices.append(noDevicesDiv);
		}
	});
};

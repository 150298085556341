import React from 'react';
import { useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Form, FormInstance, Input, Select } from 'antd';

type Props = {
	form: FormInstance;
};

const { Item } = Form;
const { TextArea } = Input;

const CreateCommandsForm = ({ form }: Props) => {
	const intl = useIntl();
	return (
		<Form form={form} layout="vertical">
			<Item
				label={intl.formatMessage(translate('pin'))}
				name="pins"
				rules={[{ required: true, message: intl.formatMessage(translate('YouMustEnterAtLeastOneId')) }]}
			>
				<Select placeholder={intl.formatMessage(translate('pin'))} mode="tags" tokenSeparators={[',']} />
			</Item>

			<Item
				label={intl.formatMessage(translate('command'))}
				name="command"
				rules={[{ required: true, message: intl.formatMessage(translate('pleaseEnterACommand')) }]}
			>
				<TextArea rows={6} />
			</Item>
		</Form>
	);
};

export default CreateCommandsForm;

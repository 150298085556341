import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Descriptions, message, Modal, Skeleton, Tag, Typography } from 'antd';
import { CommandToBeSendInfoDto } from '@/features/commandsToBeSend/commandsToBeSend.dto';
import CommandsToBeSendService from '@/features/commandsToBeSend/commandsToBeSend.service';
import { CommandToBeSendStatus } from '@/features/commandsToBeSend/commandToBeSend.enum';
import unitSystem from '@/features/unitSystems';
import { UTC_FORMAT } from '@/OptimusRoutes/constants';
import { getIntlEventMessageDescriptor } from '@/utilities';
import { formatDate, isValidDateTime } from '@/utilities/dateUtils';

type Props = {
	commandId?: number;
	pin?: string;
	open: boolean;
	onClose: () => void;
};

const CommandDeviceInfoModal = ({ open, pin, commandId, onClose }: Props) => {
	const intl = useIntl();

	const [loading, setLoading] = useState<boolean>(false);
	const [commandInfo, setCommandInfo] = useState<CommandToBeSendInfoDto>();

	const fetchData = async () => {
		try {
			setLoading(true);
			if (!commandId) {
				return;
			}
			const { success, error } = await CommandsToBeSendService.getCommandInfo(commandId);
			if (success && !error) {
				setCommandInfo(success);
			}
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (commandId) {
			fetchData();
		}
	}, [commandId]);

	const renderInfoValue = (value?: CommandToBeSendInfoDto[keyof CommandToBeSendInfoDto]): string | undefined => {
		if (!value) {
			return;
		}

		if (isValidDateTime(value as Date, UTC_FORMAT)) {
			return formatDate(unitSystem.dateTimeFormat, commandInfo?.createdAt, true);
		}
		return value as string;
	};

	let StatusTag = null;
	let Firmware = null;
	let LastEvent = null;

	if (commandInfo?.status) {
		let color = 'success';
		if (commandInfo.status === CommandToBeSendStatus.PENDING) {
			color = 'processing';
		} else if (commandInfo.status === CommandToBeSendStatus.WAITINGFORACK) {
			color = 'warning';
		}
		StatusTag = (
			<Tag color={color}>
				<FormattedMessage {...translate(`COMMAND_${commandInfo.status}`)} />
			</Tag>
		);
	}
	if (commandInfo?.device?.deviceLastReport?.firmwareVersion) {
		Firmware = <pre>{JSON.stringify(commandInfo.device.deviceLastReport.firmwareVersion, null, 4)}</pre>;
	}

	if (commandInfo?.device?.deviceLastReport?.event?.id) {
		LastEvent = (
			<FormattedMessage {...getIntlEventMessageDescriptor(commandInfo?.device?.deviceLastReport?.event?.id)} />
		);
	}

	return (
		<Modal open={open} title={pin} footer={null} onCancel={onClose}>
			<Skeleton loading={loading} active>
				<Descriptions bordered size="small" layout="horizontal" column={1}>
					<Descriptions.Item label={intl.formatMessage(translate('description'))}>
						{renderInfoValue(commandInfo?.device?.description)}
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('model'))}>
						{renderInfoValue(commandInfo?.device?.deviceModel?.description)}
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('client'))}>
						{renderInfoValue(commandInfo?.device?.client?.name)}
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('port'))}>
						{renderInfoValue(commandInfo?.device?.dataPort)}
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('command'))}>
						<Typography.Text
							copyable={{
								tooltips: [
									intl.formatMessage(translate('copyToClipboard')),
									intl.formatMessage(translate('successCopy')),
								],
							}}
							code
						>
							{renderInfoValue(commandInfo?.command)}
						</Typography.Text>
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('status'))}>{StatusTag}</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('createdOn'))}>
						{renderInfoValue(commandInfo?.createdAt)}
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('sent'))}>
						{renderInfoValue(commandInfo?.sentAt)}
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('lastReport'))}>
						{renderInfoValue(commandInfo?.device?.deviceLastReport?.utcDate)}
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('lastEvent'))}>
						{LastEvent}
					</Descriptions.Item>
					<Descriptions.Item label={intl.formatMessage(translate('firmwareVersion'))}>
						{Firmware}
					</Descriptions.Item>
				</Descriptions>
			</Skeleton>
		</Modal>
	);
};

export default CommandDeviceInfoModal;

import { ActionType, CleanReducerAction, IAction, LogOutAction } from '../../types/actionType';
import { CommandDto } from '../modelProfile/modelProfile.dto';

interface SetCommandsInfoAction extends IAction<CommandDto[]> {
	type: ActionType.COMMANDS_FETCH_INFO;
}

export type Action = SetCommandsInfoAction | LogOutAction | CleanReducerAction;

export const UpdateCommandsInfo = (data: CommandDto[]): SetCommandsInfoAction => ({
	type: ActionType.COMMANDS_FETCH_INFO,
	payload: data,
});

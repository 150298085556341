import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { RouteUser } from '../entities/routeUser.entity';
import { RouteUserToken } from '../entities/routeUserToken.entity';
import { ApiResult, Filter, IService, OtherParams, Result } from '../interfaces';
import {
	ClientSubscriptionUsageResponse,
	CreateRouteUser,
	DeviceIcon,
	DeviceTimeZone,
	OtpRequest,
	OtpResponse,
	PhoneVerificationRequest,
	PhoneVerificationResponse,
	RouteUserDevice,
} from '../types/routeUser.types';
import { ServiceError } from '../types/service.types';

const ICON_DEVICES = 1;
const BASE_URL = `/v2/route-users`;

export type CellularDevices = RouteUser &
	Omit<RouteUserToken, 'refreshToken' | 'fcmToken' | 'uniqueId' | 'createdAt' | 'updatedAt'>;

type RouteUserServiceType = IService<RouteUser> & {
	phoneVerification: (user: PhoneVerificationRequest) => Promise<PhoneVerificationResponse>;
	getOtp: (otp: OtpRequest) => Promise<OtpResponse>;
	removeTask: (id: number, taskId: number) => Promise<void>;
	getAllDriversByDate: ({ skip, take, filter }: Filter<RouteUser>, date: string) => Promise<ApiResult<RouteUser[]>>;
	getAllCellularDevices: (
		filter: Filter<RouteUser>,
		filterByStatus?: OtherParams
	) => Promise<ApiResult<CellularDevices[]>>;
	getIcons: () => Promise<DeviceIcon[]>;
	getTimezones: () => Promise<Result<DeviceTimeZone[]>>;
	getRouteUserSessionToken: (id: number) => Promise<RouteUserToken | ServiceError>;
	getRouteSubscriptionUsage: () => Promise<ApiResult<Result<ClientSubscriptionUsageResponse>>>;
	updateSubscriptionQuantity: (quantity: number) => Promise<ApiResult<Result<void>>>;
	getDriversTasksProgress: (
		localDate: string,
		timeZone: string,
		name?: string,
		skip?: number,
		take?: number
	) => Promise<any>;
};

const get = async ({ skip, take, filter = {} }: Filter<RouteUser>): Promise<ApiResult<RouteUser[]>> => {
	const request = { skip, take, filter: JSON.stringify(filter) };
	const response: AxiosResponse<ApiResult<RouteUser[]>> = await axios.get(`${BASE_URL}?${qs.stringify(request)}`);

	return response.data;
};

const getRouteUserSessionToken = async (id: number): Promise<RouteUserToken | ServiceError> => {
	const response: AxiosResponse<RouteUserToken> = await axios.get(`${BASE_URL}/session/${id}`);
	return response.data;
};

const getAllDriversByDate = async (
	{ skip, take, filter = {} }: Filter<RouteUser>,
	date: string
): Promise<ApiResult<RouteUser[]>> => {
	const request = { skip, take, filter: JSON.stringify(filter) };
	const response: AxiosResponse<ApiResult<RouteUser[]>> = await axios.get(
		`${BASE_URL}/${date}/tasks-count?${qs.stringify(request)}`
	);

	return response.data;
};

const getById = async (id: number): Promise<RouteUser> => {
	const response: AxiosResponse<RouteUser> = await axios.get(`${BASE_URL}/${id}`);

	return response.data;
};

const create = async (user: CreateRouteUser): Promise<RouteUser> => {
	const response = await axios.post<RouteUser>(BASE_URL, user);

	return response.data;
};

const update = async (update: Partial<RouteUser & RouteUserDevice>): Promise<RouteUser> => {
	const { id, ...body } = update;
	const response = await axios.patch<RouteUser>(`${BASE_URL}/${id}`, body);

	return response.data;
};

const remove = async (id: number): Promise<void> => await axios.delete(`${BASE_URL}/${id}`);

const removeTask = async (id: number, taskId: number): Promise<void> =>
	await axios.delete(`${BASE_URL}/${id}/task/${taskId}`);

const phoneVerification = async (user: PhoneVerificationRequest): Promise<PhoneVerificationResponse> => {
	const response: AxiosResponse<PhoneVerificationResponse> = await axios.post(`${BASE_URL}/phone-exist`, user);
	return response.data;
};

const getIcons = async (): Promise<DeviceIcon[]> => {
	const response: AxiosResponse<DeviceIcon[]> = await axios.get(
		`/Icons?filter=${encodeURIComponent(JSON.stringify({ where: { type: ICON_DEVICES } }))}`
	);
	return response.data;
};

const getTimezones = async (): Promise<Result<DeviceTimeZone[]>> => {
	const { data } = await axios.get<Result<DeviceTimeZone[]>>('/v2/time-zones');
	return data;
};

const getOtp = async (otp: OtpRequest): Promise<OtpResponse> => {
	const response: AxiosResponse<OtpResponse> = await axios.post(`/v2/auth/otp/admin`, otp);
	return response.data;
};

const getAllCellularDevices = async (
	{ skip, take, filter = {} }: Filter<RouteUser>,
	statusType?: OtherParams
): Promise<ApiResult<CellularDevices[]>> => {
	const request = { skip, take, filter: JSON.stringify(filter) };
	const response: AxiosResponse<ApiResult<CellularDevices[]>> = await axios.get(
		`${BASE_URL}/cellular-devices?${qs.stringify(request)}&filterByStatus=${statusType?.filterByStatus}`
	);
	return response.data;
};

const getRouteSubscriptionUsage = async (): Promise<ApiResult<Result<ClientSubscriptionUsageResponse>>> => {
	return await axios.get(`/v2/stripe/routes/subscription-usage`);
};

const updateSubscriptionQuantity = async (quantity: number): Promise<ApiResult<Result<void>>> => {
	return await axios.post(`/v2/stripe/routes/update-subscription-quantity`, { quantity });
};

const getDriversTasksProgress = async (
	date: string,
	timeZone: string,
	name = '',
	skip: number | string = '',
	take: number | string = ''
) => {
	const queries = { date, timeZone, name, skip, take };
	const response = await axios.get(`${BASE_URL}/tasks-progress?${qs.stringify(queries)}`);

	return response.data.success;
};

const RouteUserService: RouteUserServiceType = {
	get,
	getById,
	create,
	update,
	delete: remove,
	phoneVerification,
	getOtp,
	removeTask,
	getAllDriversByDate,
	getAllCellularDevices,
	getIcons,
	getTimezones,
	getRouteUserSessionToken,
	getRouteSubscriptionUsage,
	updateSubscriptionQuantity,
	getDriversTasksProgress,
};

export default RouteUserService;

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@optimusgps/optimus-intl';
import { Form, Grid, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import GroupComponent from '../../../components/GroupComponent';
import { GetDevices } from '../../map/actions';

const { useBreakpoint } = Grid;

const DevicesFormItem = ({ deviceFilter }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [selectedDevices, setSelectedDevices] = useState([]);
	const devices = useSelector((state) => state.map.devices.filter(deviceFilter));
	const groups = useSelector((state) => state.group.groups);
	const [tree, setTree] = useState([]);
	const [loading, setLoading] = useState(false);

	const screens = useBreakpoint();
	let width = '360px';
	if (!screens.md) width = '100%';

	useEffect(() => {
		const source = axios.CancelToken.source();
		(async () => {
			try {
				setLoading(true);
				await dispatch(GetDevices(source.token));
			} catch (error) {
				message.error(intl.formatMessage(translate('commonError')));
			} finally {
				setLoading(false);
			}
		})();
		return () => {
			source.cancel();
		};
	}, []);

	useEffect(() => {
		let reduceTree = groups.reduce((result, group) => {
			result[group.id] = group.deviceIds;
			return result;
		}, []);
		setTree(reduceTree);
	}, [groups, loading]);

	const onChange = useCallback((devices) => {
		const selected = devices.map((deviceId) => deviceId.toString());
		setSelectedDevices(selected);
	}, []);

	return (
		<Form.Item
			name="devices"
			label={intl.formatMessage(translate('devices'))}
			rules={[
				{
					required: true,
					message: intl.formatMessage(translate('isRequired')),
				},
			]}
			data-fieldid="devices-formItem"
		>
			<GroupComponent
				groups={groups}
				items={devices}
				tree={tree}
				disableNode={(item) => item.utcDate == null}
				selected={selectedDevices}
				onChange={onChange}
				showAllNode={true}
				showNoGroupNode={true}
				width={width}
				isDisabled={loading}
			/>
		</Form.Item>
	);
};

DevicesFormItem.defaultProps = {
	deviceFilter: () => true,
};

DevicesFormItem.propTypes = {
	deviceFilter: PropTypes.func,
};

export default DevicesFormItem;

import axios from 'axios';
import { INTEREST_TYPE } from '../../constants/global';
// import {  } from './actionTypes';

export const GetPointsRouteMonitor = ({ id, clientId, day, cancelToken }) => {
	let params = {
		clientId,
		day,
	};

	return axios.get(`/TrackingHistories/${id}/RouteMonitor`, { params }, cancelToken).then((response) => {
		return response.data;
	});
};

export const GetLineRouteMonitor = ({ id, clientId, date, day, utcOffsetMinutes, cancelToken }) => {
	let params = {
		clientId,
		day,
		date,
		utcOffsetMinutes,
	};

	return axios
		.post(`/TrackingHistories/${id}/lineOfInterest/RouteMonitor`, { ...params }, cancelToken)
		.then((response) => {
			return response.data.map((line) => {
				return { ...line, type: INTEREST_TYPE.LINE };
			});
		});
};

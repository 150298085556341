import axios from 'axios';

export const getTraceById = (id, date, utcOffsetMinutes, source) => {
	let options = {
		start: date,
		utcOffsetMinutes: utcOffsetMinutes,
	};

	return axios.post(`/TrackingHistories/${id}/track/`, options, source);
};

export const getEventsById = (id, date, utcOffsetMinutes, accountId, source) => {
	let options = {
		start: date,
		utcOffsetMinutes,
		accountId,
	};

	return axios.post(`/TrackingHistories/${id}/events/`, options, source);
};

export const getDetaileTracebyId = ({ id, startDate, endDate, utcOffsetMinutes, source }) => {
	let options = {
		start: startDate,
		end: endDate,
		utcOffsetMinutes: utcOffsetMinutes,
	};
	return axios.post(`/TrackingHistories/${id}/trail/`, options, source);
};

export const getEventsBetweenDates = (id, startDate, endDate, accountId, source) => {
	let options = {
		startDate,
		endDate,
		accountId,
	};
	return axios.post(`/TrackingHistories/${id}/trail/Events`, options, source);
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import { translate } from '@optimusgps/optimus-intl';
import { Button, Dropdown, Row, Typography } from 'antd';
import { removeDuplicates } from '../../../utilities/util';
import { AppliedFilter, FilterData } from '../../types/filterComponent.types';
import FilterOptions from './FilterOptions';

const { Text } = Typography;

type FilterDropdown = {
	key: number;
	label: JSX.Element;
	filterwhere: string;
};

type Props = {
	filters: FilterData[];
	onChange: (appliedFilters: AppliedFilter[]) => void;
	onClearFilters: () => void;
	placeholder?: string;
	initialFilters?: AppliedFilter[];
	className?: string;
};

const AdvancedFilter = ({
	filters,
	onChange,
	placeholder = '',
	onClearFilters,
	initialFilters = [],
	className,
}: Props) => {
	const intl = useIntl();
	const [pillsList, setPillsList] = useState<AppliedFilter[]>(initialFilters);
	const [disabledFilterOptions, setDisabledFilterOptions] = useState<FilterDropdown[]>([]);

	const [openDropdown, setOpenDropdown] = useState(false);
	const [dropdownItems, setDropdownItems] = useState<FilterDropdown[]>([]);

	const [selectedFilter, setSelectedFilter] = useState<FilterData>();
	const [showSelectedFilter, setShowSelectedFilter] = useState(false);

	const onFilterSelect = (filter: FilterData) => {
		setSelectedFilter(filter);
		setOpenDropdown(false);
		setShowSelectedFilter(true);
	};

	const formatDoropdownList = (): FilterDropdown[] =>
		filters
			.map((filter) => ({
				key: filter.id,
				label: (
					<div onClick={() => onFilterSelect(filter)}>
						{intl.formatMessage(translate(filter.intlLabelCode))}
					</div>
				),
				filterwhere: filter.filterwhere,
			}))
			.sort((filterA, filterB) => filterA.key - filterB.key);

	const onAddPill = (filter?: AppliedFilter) => {
		if (!filter) {
			setSelectedFilter(undefined);
			return;
		}
		setPillsList([...pillsList, filter]);
		if (selectedFilter) {
			setShowSelectedFilter(false);

			const [disableSelectedFilter] = dropdownItems?.filter(
				(item) => item?.filterwhere === selectedFilter?.filterwhere
			);
			const disabledFiltersUniqueIds = removeDuplicates([...disabledFilterOptions, disableSelectedFilter], 'key');
			setDisabledFilterOptions(disabledFiltersUniqueIds);

			const availableOptions = dropdownItems
				.filter((item) => item.filterwhere !== selectedFilter.filterwhere)
				.sort((filterA, filterB) => filterA.key - filterB.key);
			setDropdownItems(availableOptions);
		}
	};

	const onRemovePill = (id: number) => {
		const updateAppliedFilters = pillsList.filter((filter) => filter.id !== id);

		setPillsList(updateAppliedFilters);
		onChange(updateAppliedFilters);

		const [restoreDropdownFilter] = disabledFilterOptions.filter((filter) => filter.key === id);
		setDropdownItems((prev) =>
			[restoreDropdownFilter, ...prev].sort((filterA, filterB) => filterA.key - filterB.key)
		);
	};

	const onRemoveAllFilters = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.stopPropagation();
		if (pillsList.length === 0) {
			return;
		}
		setPillsList([]);
		setSelectedFilter(undefined);
		const restoreDropdownItems = [...disabledFilterOptions, ...dropdownItems].sort(
			(filterA, filterB) => filterA.key - filterB.key
		);
		const dropdownItemsUniqueKeys: FilterDropdown[] = removeDuplicates(restoreDropdownItems, 'key').filter(
			(definedFilter: FilterDropdown[]) => definedFilter
		);
		setDropdownItems(dropdownItemsUniqueKeys);
		onClearFilters();
	};

	const Pills = useMemo(
		() =>
			pillsList.map((pill) => {
				let selectedLabel = pill.value;
				if (pill.type === 'DROPDOWN') {
					if (pill.intlSelectedDropdown) {
						selectedLabel = intl.formatMessage(translate(pill.intlSelectedDropdown));
					} else if (pill.label) {
						selectedLabel = pill.label;
					}
				}
				return (
					<div className="filter-input-pill" key={pill.id}>
						<div className="filter-input-closed-pill">
							<div className="filter-input-label">
								<FormattedMessage {...translate(pill?.intlLabelCode)} />:
							</div>
							<div className="filter-input-value">
								<Text style={{ maxWidth: '100%' }} ellipsis={{ tooltip: selectedLabel }}>
									{selectedLabel}
								</Text>
							</div>
						</div>
						<div id="filter-pill-remove">
							<Button
								type="text"
								icon={<CloseCircleFilled />}
								className="filter-input-pill-action"
								size="small"
								onClick={() => onRemovePill(pill.id)}
							/>
						</div>
					</div>
				);
			}),
		[pillsList, intl]
	);
	const SelectedFilterInput = useCallback(
		() =>
			selectedFilter &&
			showSelectedFilter && (
				<div className="filter-input-child-input">
					<span>{intl.formatMessage(translate(selectedFilter.intlLabelCode))}: </span>
					<div className="filter-input-child-input-div">
						<FilterOptions filter={selectedFilter} onApply={onAddPill} appliedFilters={pillsList} />
					</div>
				</div>
			),
		[selectedFilter, showSelectedFilter, onAddPill, pillsList, dropdownItems]
	);

	const onOpenDropdown = (open: boolean) => {
		if (SelectedFilterInput()) {
			return;
		}
		setOpenDropdown(open);
	};

	useEffect(() => {
		const initialFiltersApplied = filters.filter((firstFilter) =>
			initialFilters.some((secondFilter) => secondFilter.id === firstFilter.id)
		);

		const disableInitialFilters: FilterDropdown[] = initialFiltersApplied.map((filter) => ({
			key: filter.id,
			label: (
				<div onClick={() => onFilterSelect(filter)}>{intl.formatMessage(translate(filter.intlLabelCode))}</div>
			),
			filterwhere: filter.filterwhere,
		}));
		setDisabledFilterOptions(disableInitialFilters);

		const dropdownItemsList = formatDoropdownList()
			.filter((firstFilter) => !initialFilters.some((secondFilter) => secondFilter.id === firstFilter.key))
			.filter(({ key }) => !disabledFilterOptions.some((disabledFilter) => disabledFilter.key === key));
		setDropdownItems(dropdownItemsList);
	}, [intl]);

	useEffect(() => {
		if (!selectedFilter) {
			return;
		}
		onChange(pillsList);
	}, [pillsList, onChange, dropdownItems]);

	return (
		<Row className={`filter-input-menu ${className}`}>
			<div className="filter-input-dropdown">
				<Dropdown
					menu={{ items: dropdownItems }}
					onOpenChange={onOpenDropdown}
					open={openDropdown}
					trigger={['click']}
				>
					<div className="filter-input">
						<div className="filter-input-pills" onMouseEnter={() => setOpenDropdown(false)}>
							{Pills}
							{pillsList.length === 0 && !showSelectedFilter && (
								<div className="filter-input-placeholder">{placeholder}</div>
							)}
							{SelectedFilterInput()}
						</div>
						<div className="filter-input-right">
							<span className="filter-input-filter-count">({pillsList.length})</span>
							<Button
								onClick={(event) => onRemoveAllFilters(event)}
								type="text"
								icon={<CloseOutlined />}
								size="small"
							/>
						</div>
					</div>
				</Dropdown>
			</div>
		</Row>
	);
};

export default AdvancedFilter;

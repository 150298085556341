import React from 'react';
import { Form, Grid } from 'antd';
import DevicesFormItem from '@/features/reports/Common/DevicesFormItem';
import DayJsRangeDateFormitem from '@/features/reports/Common/RangeDateFormItem';
import Buttons from '../Common/Buttons';

type Props<T> = {
	onSubmit: (values: T) => Promise<void>;
	loading: boolean;
	isExport?: boolean;
	handleCancel?: () => void;
	exportButton?: React.ReactNode | null;
};

const MileagePerStateOptions = <T,>({ onSubmit, loading, isExport, handleCancel, exportButton }: Props<T>) => {
	const screens = Grid.useBreakpoint();
	const layout = !screens.md || isExport ? 'vertical' : 'inline';

	return (
		<Form onFinish={onSubmit} layout={layout}>
			<DevicesFormItem />
			<DayJsRangeDateFormitem />
			<Buttons loading={loading} isExport={isExport} handleCancel={handleCancel} exportButton={exportButton} />
		</Form>
	);
};

export default MileagePerStateOptions;

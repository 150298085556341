import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form/Form';

const { Item } = Form;

type Props = {
	form: FormInstance;
	onSubmit: () => void;
	isLoading: boolean;
};

export const SmsForm = ({ form, isLoading, onSubmit }: Props) => {
	const intl = useIntl();

	const initialValues = {
		email: '',
		phoneNumber: '',
	};

	return (
		<Form className="sms-form" layout={'inline'} initialValues={initialValues} form={form}>
			<Item
				className="sms-form-items"
				name="email"
				label={intl.formatMessage(translate('email'))}
				rules={[
					{
						required: true,
						message: intl.formatMessage(translate('emailRequired')),
					},
					{
						type: 'email',
						message: 'Invalid format',
					},
				]}
			>
				<Input placeholder={intl.formatMessage(translate('email'))} />
			</Item>
			<Item className="sms-form-items" name="phoneNumber" label={intl.formatMessage(translate('phoneNumber'))}>
				<Input placeholder={intl.formatMessage(translate('phoneNumber'))} />
			</Item>
			<Button className="btn-form" key="ok" type="primary" onClick={onSubmit} loading={isLoading}>
				<FormattedMessage {...translate('searchPlaceholder')} />
			</Button>
		</Form>
	);
};

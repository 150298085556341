import axios from 'axios';
import { Result } from '@/OptimusRoutes/interfaces';
import {
	CheckPhoneStatusQueryDto,
	CheckPhoneStatusResponseDto,
	UpdatePhoneStatusBodyDto,
	UpdatePhoneStatusResponseDto,
} from './diagnostics.dto';

type Service = {
	getPhoneStatus: ({ email, phoneNumber }: CheckPhoneStatusQueryDto) => Promise<Result<CheckPhoneStatusResponseDto>>;
	updatePhoneStatus: ({
		phoneNumber,
		platform,
		clientId,
	}: UpdatePhoneStatusBodyDto) => Promise<Result<UpdatePhoneStatusResponseDto>>;
};

const getPhoneStatus = async ({
	email,
	phoneNumber,
}: CheckPhoneStatusQueryDto): Promise<Result<CheckPhoneStatusResponseDto>> => {
	let params: CheckPhoneStatusQueryDto = {
		email,
	};

	if (phoneNumber) {
		params = {
			...params,
			phoneNumber,
		};
	}
	const { data } = await axios.get(`/v2/diagnostics/phone-status/`, {
		params,
	});

	return data;
};

const updatePhoneStatus = async ({
	phoneNumber,
	platform,
	clientId,
}: UpdatePhoneStatusBodyDto): Promise<Result<UpdatePhoneStatusResponseDto>> => {
	const { data } = await axios.patch(`/v2/diagnostics/phone-status/`, {
		phoneNumber,
		platform,
		clientId,
	});

	return data;
};

const DiagnosticsService: Service = {
	getPhoneStatus,
	updatePhoneStatus,
};

export default DiagnosticsService;

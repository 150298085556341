import mapIntance from './map/map';

export const getMapInstance = (map, devices, options) => {
	if (options.mapName in window && !options.forceReload) {
		window[options.mapName].loadInstance(map, options);
	} else {
		window[options.mapName] = new mapIntance(map, devices, options);
	}
};

export const SetMapDevices = (mapName, devices) => {
	if (mapName in window) {
		window[mapName].cleanInstance();
		window[mapName].init(devices);
	}
};

import { DeviceDto } from '@/features/devices/device.dto';
import { GroupDto } from '@/features/groups/group.dto';
import { UserDto } from '@/features/users/users.dto';
import { ActionType, CleanReducerAction, IAction, LogOutAction } from '@/types/actionType';

interface FetchGroupsAction extends IAction<GroupDto[]> {
	type: ActionType.GROUPS_FETCH_INFO;
}

interface AddGroupAction extends IAction<GroupDto> {
	type: ActionType.GROUPS_ADD;
}

interface EditGroupAction extends IAction<GroupDto[]> {
	type: ActionType.GROUPS_EDIT;
}

interface DeleteGroupAction extends IAction<GroupDto[]> {
	type: ActionType.GROUPS_DELETE;
}

interface UnshareGroupAction extends IAction<GroupDto[]> {
	type: ActionType.GROUPS_UN_SHARE;
}

interface FetchGroupDevicesAction extends IAction<DeviceDto[]> {
	type: ActionType.GROUPS_FETCH_DEVICES;
}

interface FetchGroupAccountsAction extends IAction<UserDto[]> {
	type: ActionType.GROUPS_FETCH_ACCOUNTS;
}

export type Action =
	| AddGroupAction
	| DeleteGroupAction
	| EditGroupAction
	| FetchGroupsAction
	| UnshareGroupAction
	| FetchGroupDevicesAction
	| FetchGroupAccountsAction
	| CleanReducerAction
	| LogOutAction;

const updateGroupListById = (edited: GroupDto, groups: GroupDto[]): GroupDto[] =>
	groups.map<GroupDto>((group) => (edited.id === group.id ? edited : group));

const removeGroupListById = (groupId: number, groups: GroupDto[]): GroupDto[] =>
	groups.filter(({ id }) => groupId !== id);

export const FetchGroups = (groups: GroupDto[]): FetchGroupsAction => ({
	type: ActionType.GROUPS_FETCH_INFO,
	payload: groups,
});

export const AddGroup = (group: GroupDto): AddGroupAction => ({
	type: ActionType.GROUPS_ADD,
	payload: group,
});

export const EditGroup = (edited: GroupDto, groups: GroupDto[]): EditGroupAction => ({
	type: ActionType.GROUPS_EDIT,
	payload: updateGroupListById(edited, groups),
});

export const DeleteGroup = (groupId: number, groups: GroupDto[]): DeleteGroupAction => ({
	type: ActionType.GROUPS_DELETE,
	payload: removeGroupListById(groupId, groups),
});

export const UnshareGroup = (groupId: number, groups: GroupDto[]): UnshareGroupAction => ({
	type: ActionType.GROUPS_UN_SHARE,
	payload: removeGroupListById(groupId, groups),
});

export const FetchGroupDevices = (devices: DeviceDto[]): FetchGroupDevicesAction => ({
	type: ActionType.GROUPS_FETCH_DEVICES,
	payload: devices,
});

export const FetchGroupAccounts = (accounts: UserDto[]): FetchGroupAccountsAction => ({
	type: ActionType.GROUPS_FETCH_ACCOUNTS,
	payload: accounts,
});

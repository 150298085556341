import React from 'react';
import { useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import unitSystem from '../../unitSystems';

const { RangePicker } = DatePicker;

const RangeDateFormitem = ({ onChange }) => {
	const intl = useIntl();

	const ranges = {
		[`${intl.formatMessage(translate('today'))}`]: [moment().startOf('day'), moment().endOf('day')],
		[`${intl.formatMessage(translate('lastWeek'))}`]: [
			moment().add(-1, 'week').startOf('week'),
			moment().add(-1, 'week').endOf('week'),
		],
		[`${intl.formatMessage(translate('lastMonth'))}`]: [
			moment().subtract(1, 'month').startOf('month'),
			moment().subtract(1, 'month').endOf('month'),
		],
		[`${intl.formatMessage(translate('thisMonth'))}`]: [moment().startOf('month'), moment().endOf('day')],
	};

	return (
		<Form.Item
			name="dateRange"
			label={intl.formatMessage(translate('dateRange'))}
			initialValue={[
				moment('00:00:00', unitSystem.configuration.time),
				moment('23:59:59', unitSystem.configuration.time),
			]}
			rules={[
				{
					required: true,
					message: intl.formatMessage(translate('selectRangeDate')),
				},
			]}
		>
			<RangePicker
				ranges={ranges}
				disabledDate={(current) => {
					return current && current > moment().endOf('day');
				}}
				showTime={true}
				format={unitSystem.getCompleteDateTimeFormat()}
				data-testid="rangeDate_picker"
				onChange={onChange}
			/>
		</Form.Item>
	);
};

RangeDateFormitem.defaultProps = {
	onChange: () => {},
};

RangeDateFormitem.propTypes = {
	onChange: PropTypes.func,
};

export default RangeDateFormitem;

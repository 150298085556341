import { MarkerWithLabel } from '@googlemaps/markerwithlabel';

class cellTower {
	constructor({ deviceCellTower, map, config }) {
		this.deviceCellTower = deviceCellTower;
		this.map = map;
		this.place = null;
		this.marker = null;
		this.config = config;
	}

	load = (map) => {
		this.map = map;
		this.setMap(map);
	};

	show = () => {
		this.marker = this._drawMarkerLabel();
		this.place = this._drawArea();
	};

	hide = () => {
		this.setMap(null);
	};

	setMap = (map) => {
		this.marker.setMap(map);
		this.place.setMap(map);
	};

	getBounds = () => this.place.getBounds();

	showInfowindow = () => {
		const { latitude, longitude } = this.deviceCellTower;
		const infowindow = new window.google.maps.InfoWindow();
		const position = new window.google.maps.LatLng(latitude, longitude);
		infowindow.setContent(this.config.messages.gpsSignalFeedback);
		infowindow.setPosition(position);
		infowindow.open(this.map);
	};

	_drawMarkerLabel = () => {
		const { latitude, longitude, description } = this.deviceCellTower;
		const marker = new MarkerWithLabel({
			position: {
				lat: latitude,
				lng: longitude,
			},
			options: {
				visible: this.config.visible,
				optimized: false,
				clickable: false,
				animation: 2,
			},
			labelVisible: this.config.visible,
			labelContent: `${description} ${this.config.messages.noGpsSignal}`,
			labelAnchor: new window.google.maps.Point(0, 10),
			labelClass: 'cell-tower-label',
			map: this.map,
		});
		marker.addListener('click', () => {
			this.showInfowindow();
		});
		return marker;
	};

	_drawArea = () => {
		const { accuracy, latitude, longitude } = this.deviceCellTower;
		const circle = new window.google.maps.Circle({
			strokeColor: '#FF0000',
			strokeOpacity: 0.7,
			strokeWeight: 2,
			fillColor: '#ff00004d',
			fillOpacity: 0.35,
			map: this.map,
			center: {
				lat: latitude,
				lng: longitude,
			},
			radius: accuracy,
		});
		return circle;
	};
}

export default cellTower;

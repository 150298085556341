import axios from 'axios';
import qs from 'qs';
import { FETCH_SIMS, FETCH_SIMS_COUNT } from './actionTypes';
import {
	AddSimProps,
	DeleteSimsProps,
	EditSimProps,
	GetSimsProps,
	SimsResponse,
	UpdateSimStatusProps,
} from './sim.types';

interface ISimService {
	getSims(props: GetSimsProps): Promise<Result<SimsResponse>>;
	editSim(props: EditSimProps): Promise<Result<SimsResponse>>;
	deleteSims(props: DeleteSimsProps): Promise<Result<SimsResponse>>;
	addSim(props: AddSimProps): Promise<Result<SimsResponse>>;
	updateSimStatus(props: UpdateSimStatusProps): Promise<Result<SimsResponse>>;
}

// todo: replace this with 'Result' at OptimusRoutes/Interfaces/index.ts
type Result<T> = {
	success?: T;
	error?: Error;
};

type Error = {
	code: number;
	message?: string;
	details?: unknown;
};
class Service implements ISimService {
	//TODO: remove dispatch from here
	async getSims({ search = '', page = 1, pageSize = 15, dispatch }: GetSimsProps): Promise<Result<SimsResponse>> {
		if (search !== '') search = '%' + search + '%';
		const request = { skip: (page - 1) * pageSize, take: pageSize, search };
		const { data: response } = await axios.get<Result<SimsResponse>>(`/v2/sims?${qs.stringify(request)}`);

		if (response?.success) {
			const { data, count } = response.success;
			dispatch({ type: FETCH_SIMS, payload: data });
			dispatch({ type: FETCH_SIMS_COUNT, count });
		}
		return response;
	}

	async editSim({ id, line, serial, carrierId }: EditSimProps) {
		const data = { line, serial, carrierId };
		const { data: response } = await axios.patch<Result<SimsResponse>>(`/v2/sims/${id}`, data);
		return response;
	}

	async deleteSims({ ids }: DeleteSimsProps) {
		const options = { data: { ids } };
		const { data } = await axios.delete<Result<SimsResponse>>(`/v2/sims`, options);
		return data;
	}

	async addSim({ line, serial, carrierId }: AddSimProps) {
		const data = { line, serial, carrierId: +carrierId };
		const { data: response } = await axios.post<Result<SimsResponse>>(`/v2/sims`, data);
		return response;
	}

	async updateSimStatus({ line, status }: UpdateSimStatusProps) {
		const options = {
			line,
			status,
		};
		const { data } = await axios.patch<Result<SimsResponse>>(`/v2/sims/${line}/status`, options);
		return data;
	}
}

const SimService = new Service();

export default SimService;

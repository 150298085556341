class MathUtil {
	EarthRadius = 6371009;
	Clamp = function (x, low, high) {
		return x < low ? low : x > high ? high : x;
	};
	ToRadians = function (val) {
		return (Math.PI / 180) * val;
	};
	Mod = function (x, m) {
		return ((x % m) + m) % m;
	};
	Wrap = function (n, min, max) {
		return n >= min && n < max ? n : this.Mod(n - min, max - min) + min;
	};
	Mercator = function (lat) {
		return Math.log(Math.tan(lat * 0.5 + Math.PI / 4));
	};
	InverseMercator = function (y) {
		return 2 * Math.atan(Math.Exp(y)) - Math.PI / 2;
	};
	Hav = function (x) {
		var sinHalf = Math.sin(x * 0.5);
		return sinHalf * sinHalf;
	};
	ArcHav = function (x) {
		return 2 * Math.asin(Math.sqrt(x));
	};
	//  Given h==hav(x), returns sin(abs(x)).
	SinFromHav = function (h) {
		return 2 * Math.sqrt(h * (1 - h));
	};
	//  Returns hav(asin(x)).
	HavFromSin = function (x) {
		var x2 = x * x;
		return (x2 / (1 + Math.sqrt(1 - x2))) * 0.5;
	};
	//  Returns sin(arcHav(x) + arcHav(y)).
	SinSumFromHav = function (x, y) {
		var a = Math.sqrt(x * (1 - x));
		var b = Math.sqrt(y * (1 - y));
		return 2 * (a + b - 2 * (a * y + b * x));
	};
	HavDistance = function (lat1, lat2, dLng) {
		return this.Hav(lat1 - lat2) + this.Hav(dLng) * (Math.cos(lat1) * Math.cos(lat2));
	};
	ToDegrees = function (radians) {
		return (180 / Math.PI) * radians;
	};
}
export default MathUtil;

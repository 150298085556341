import axios from 'axios';
import qs from 'qs';
import { Device } from '@/OptimusRoutes/entities/device.entity';
import { ApiResult, Filter, IService, Result } from '../../OptimusRoutes/interfaces';

type Service = Pick<IService<Device>, 'get'>;

const get = async ({ filter = {} }: Filter<Device>): Promise<ApiResult<Device[]>> => {
	const params = { filter: JSON.stringify(filter) };
	const { data: result } = await axios.get<Result<number>>(
		`/v2/commands/to-be-send/devices/count?${qs.stringify(params)}`
	);

	if (!result?.error && result.success) {
		return {
			data: [],
			count: result.success,
		};
	}

	return {
		count: 0,
		data: [],
	};
};

const DevicesCommandsToBeSendService: Service = {
	get,
};

export default DevicesCommandsToBeSendService;

import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { translate } from '@optimusgps/optimus-intl';
import {
	Button,
	Checkbox,
	Form,
	message,
	Modal,
	PaginationProps,
	Table,
	TableColumnsType,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import { CommandsToBeSend, CreateCommandToBeSendDto } from '@/features/commandsToBeSend/commandsToBeSend.dto';
import CommandsToBeSendService from '@/features/commandsToBeSend/commandsToBeSend.service';
import { CommandToBeSendStatus } from '@/features/commandsToBeSend/commandToBeSend.enum';
import CommandDeviceInfoModal from '@/features/commandsToBeSend/components/CommandDeviceInfoModal';
import CommandsToBeSendFilter from '@/features/commandsToBeSend/components/CommandsToBeSendFilter';
import TableSelectionToolbar, { CheckSelectedStatus } from '@/features/common-components/TableSelectionToolbar';
import { DeviceDto } from '@/features/devices/device.dto';
import { DeviceModelProfileDto, ModelProfileDto } from '@/features/modelProfile/modelProfile.dto';
import ModelProfileService from '@/features/modelProfile/modelProfile.service';
import unitSystem from '@/features/unitSystems';
import { useAppSelector } from '@/OptimusRoutes/hooks/redux.hooks';
import useFetchData from '@/OptimusRoutes/hooks/useFetchData.hook';
import { EntityFilter, Where } from '@/OptimusRoutes/interfaces';
import { ActionsKeys, TableActions } from '@/OptimusRoutes/types/actions.types';
import { RootState } from '@/store/configureStore';
import { formatDate, utcStartAndEndOfDay } from '@/utilities/dateUtils';
import { slashToHyphenFormat } from '@/utilities/util';
import CreateCommandsModal from './components/CreateCommandsModal';

type SearchFilterValues = Pick<Partial<CommandsToBeSend>, 'command' | 'status' | 'createdAt'> & {
	pin?: string;
	modelId?: string;
	device?: Pick<Partial<DeviceDto>, 'pin'> & {
		modelProfile: Pick<ModelProfileDto, 'deviceModelId'>;
	};
};

type ModalData = {
	open: boolean;
	commandId?: number;
	pin?: string;
};

const FORM_NAME = 'commandsToBeSendForm.';

const CommandsToBeSendPage = () => {
	const intl = useIntl();
	const systemConfig = useAppSelector((state: RootState) => state.auth.user.unitSystemConfig);
	const [form] = Form.useForm();
	const [createCommandsForm] = Form.useForm();
	const [createMultipleCommandsForm] = Form.useForm();

	const [modelsLoading, setModelsLoading] = useState<boolean>(false);
	const [models, setModels] = useState<DeviceModelProfileDto[]>([]);
	const [modalData, setModalData] = useState<ModalData>({
		open: false,
	});
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [checkedStatus, setCheckedStatus] = useState<CheckSelectedStatus>('NONE');

	const dateFormat = slashToHyphenFormat(systemConfig);

	const formatSearchFilter = (value: SearchFilterValues | string): EntityFilter<SearchFilterValues> => {
		if (typeof value === 'string') {
			return {};
		}
		let where: Where<SearchFilterValues> = {};

		if (value.modelId) {
			where = {
				device: {
					modelProfile: {
						deviceModelId: value.modelId,
					},
				},
			};
		}

		if (value.pin) {
			if ('device' in where) {
				where.device = {
					...(where.device as DeviceDto),
					pin: value.pin,
				};
			} else {
				where = {
					device: {
						pin: value.pin,
					},
				};
			}
		}

		if (value.command) {
			where.command = { value: value.command, op: 'like' };
		}

		if (value.createdAt) {
			const { startOfDate, endOfDate } = utcStartAndEndOfDay(value.createdAt, dateFormat);
			where.createdAt = { value: { from: startOfDate, to: endOfDate }, op: 'between' };
		}

		if (value.status) {
			where.status = value.status;
		}
		return {
			where,
		};
	};

	const { data, deleteById, deleteBatch, search, loading, total, updatePagination, pagination } = useFetchData<
		CommandsToBeSend,
		SearchFilterValues
	>({
		service: CommandsToBeSendService,
		formatSearchFilter,
	});

	useEffect(() => {
		setCheckedStatus('NONE');
	}, [total, pagination.page]);

	const actions: TableActions[] = useMemo(
		() => [
			{
				key: 'DELETE',
				icon: <DeleteFilled />,
				label: intl.formatMessage(translate('delete')),
				tooltip: intl.formatMessage(translate('delete')),
			},
		],
		[intl]
	);
	const handleMenuClick = (key: ActionsKeys, task: CommandsToBeSend) => {
		switch (key) {
			case 'DELETE':
				Modal.confirm({
					title: intl.formatMessage(translate('deleteConfirm')),
					icon: <ExclamationCircleOutlined />,
					okText: intl.formatMessage(translate('yes')),
					cancelText: intl.formatMessage(translate('no')),
					onOk: async () => {
						await deleteById(task.id);
					},
				});
				break;
		}
	};
	const ActionButtons = useMemo(
		() => (record: CommandsToBeSend) =>
			actions.map((item) => (
				<Tooltip title={item.tooltip} key={item.key} className={`button-${item.key}`}>
					<Button type="text" icon={item.icon} onClick={() => handleMenuClick(item.key, record)} />
				</Tooltip>
			)),
		[]
	);

	const showDeviceInfo = (commandId?: number, pin?: string) => {
		setModalData({
			open: !!commandId,
			commandId,
			pin,
		});
	};

	const onCheckboxChange = async () => {
		const allFormItems = await form?.getFieldsValue();
		const checkboxArray = Object.values(allFormItems);

		let allTrue = true;
		let allFalsy = true;

		let allChecked: CheckSelectedStatus = 'ALL';

		checkboxArray.forEach((checked) => {
			if (!checked) {
				allTrue = false;
			}
			if (checked !== false && checked !== undefined) {
				allFalsy = false;
			}
		});

		if (allTrue) {
			allChecked = 'ALL';
		} else if (allFalsy) {
			allChecked = 'NONE';
		} else {
			allChecked = 'SOME';
		}
		setCheckedStatus(allChecked);
	};

	const columns: TableColumnsType<CommandsToBeSend> = [
		{
			dataIndex: 'checkbox',
			key: 'checkbox',
			width: 30,
			render: (_, { id }) => (
				<Form.Item valuePropName="checked" name={`${FORM_NAME}${id}`} className="task-checkbox">
					<Checkbox onChange={onCheckboxChange} />
				</Form.Item>
			),
		},
		{
			title: intl.formatMessage(translate('pin')),
			dataIndex: ['device', 'pin'],
			key: 'pin',
			render: (pin: string, { id }: CommandsToBeSend) => {
				return (
					<Button type="link" onClick={() => showDeviceInfo(id, pin)}>
						{pin}
					</Button>
				);
			},
		},
		{
			title: intl.formatMessage(translate('model')),
			dataIndex: ['device', 'modelProfile', 'deviceModel', 'description'],
			key: 'model',
		},
		{
			title: intl.formatMessage(translate('command')),
			dataIndex: 'command',
			key: 'command',
			width: 250,
			render: (command: string) => {
				return (
					<Typography.Text style={{ width: 200 }} ellipsis={{ tooltip: command }}>
						{command}
					</Typography.Text>
				);
			},
		},
		{
			title: intl.formatMessage(translate('status')),
			dataIndex: 'status',
			key: 'status',
			render: (status: string) => {
				let color = 'success';
				if (status === CommandToBeSendStatus.PENDING) {
					color = 'processing';
				} else if (status === CommandToBeSendStatus.WAITINGFORACK) {
					color = 'warning';
				}
				return (
					<Tag color={color}>
						<FormattedMessage {...translate(`COMMAND_${status}`)} />
					</Tag>
				);
			},
		},
		{
			title: intl.formatMessage(translate('date')),
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt: Date) => {
				const createdAtValue = createdAt
					? formatDate(unitSystem.dateTimeFormat, createdAt, true)
					: intl.formatMessage(translate('NA'));
				return <div>{createdAtValue}</div>;
			},
		},
		{
			title: '',
			key: 'action',
			className: 'flex',
			render: (record: CommandsToBeSend) => <div className="task-page-actions">{ActionButtons(record)}</div>,
		},
	];

	const onPaginationChange: PaginationProps['onChange'] = (page, size) => {
		if (pagination.page !== page || pagination.size !== size) {
			updatePagination({ page, size });
		}
	};
	const fetchDeviceModels = async () => {
		try {
			setModelsLoading(true);
			const { data } = await ModelProfileService.getDeviceModels({ take: 0 });
			if (data) {
				setModels(data);
			}
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setModelsLoading(false);
		}
	};

	const onOpenCreateModal = () => {
		setShowCreateModal(true);
	};

	const onCloseCreateModal = () => {
		setShowCreateModal(false);
	};

	const onSave = async ({
		pins,
		command,
		isMultiple = false,
		applyToLast = 0,
		filter = undefined,
	}: CreateCommandToBeSendDto) => {
		const { data } = await CommandsToBeSendService.createCommands({
			pins,
			command,
			isMultiple,
			applyToLast,
			filter,
		});

		if (data.error) {
			message.error(intl.formatMessage(translate('commonError')));
			return;
		}

		message.success(intl.formatMessage(translate('commandsToBeSendAddedSuccessfully')));
		onCloseCreateModal();
		createCommandsForm.resetFields();
		createMultipleCommandsForm.resetFields();
		await search();
	};

	useEffect(() => {
		fetchDeviceModels();
	}, []);

	return (
		<div className="view">
			<div className="commands-to-be-send">
				<div className="commands-to-be-send-panel">
					<CommandsToBeSendFilter<CommandsToBeSend> search={search} models={models} loading={modelsLoading} />
					<Button type="primary" onClick={onOpenCreateModal}>
						<FormattedMessage {...translate(`create`)} />
					</Button>
				</div>
				<TableSelectionToolbar
					formRef={form}
					checkedStatus={checkedStatus}
					displayedItems={data.length}
					loading={loading}
					itemName={intl.formatMessage(translate('commands'))}
					total={total}
					page={pagination.page}
					formName={FORM_NAME}
					onCheckAll={(value) => setCheckedStatus(value ? 'ALL' : 'NONE')}
					onDelete={deleteBatch}
				/>
				<Form form={form}>
					<Table<CommandsToBeSend>
						pagination={{
							total,
							showSizeChanger: true,
							showTotal: (total) => intl.formatMessage(translate('tableTotal'), { value: total }),
							pageSizeOptions: [5, 10, 15],
							onChange: onPaginationChange,
							current: pagination.page,
							pageSize: pagination.size,
						}}
						scroll={{ x: 'auto' }}
						columns={columns}
						dataSource={data}
						loading={loading || modelsLoading}
						rowKey={({ id }) => id.toString()}
					/>
				</Form>
				<CommandDeviceInfoModal
					commandId={modalData.commandId}
					open={modalData.open}
					onClose={() => showDeviceInfo()}
					pin={modalData.pin}
				/>
				<CreateCommandsModal
					createCommandsForm={createCommandsForm}
					title={intl.formatMessage(translate('newCommandToBeSend'))}
					isOpen={showCreateModal}
					onCancel={onCloseCreateModal}
					onSave={onSave}
					models={models}
					createMultipleCommandsForm={createMultipleCommandsForm}
				/>
			</div>
		</div>
	);
};

export default CommandsToBeSendPage;

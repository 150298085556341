import axios from 'axios';
import { AlertDevice } from './alert.dto';

type AlertServiceType = {
	nofifyAlertUpdate: (devices: AlertDevice[]) => Promise<void>;
	updateDigitalInput: (deviceId: number) => Promise<void>;
};

const nofifyAlertUpdate = async (devices: AlertDevice[]): Promise<void> =>
	await axios.post(`/v2/alert/notify-update`, { devices });

const updateDigitalInput = async (deviceId: number): Promise<void> =>
	await axios.post(`/v2/alert/notify-update-digital-input`, { deviceId });

const AlertService: AlertServiceType = {
	nofifyAlertUpdate,
	updateDigitalInput,
};

export default AlertService;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { message, Modal, Tabs } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { TabsProps } from 'antd/lib/tabs/index';
import { DeviceModelProfileDto } from '@/features/modelProfile/modelProfile.dto';
import { CreateCommandToBeSendDto } from '../commandsToBeSend.dto';
import { CreateCommandsFormType, CreateMultipleCommandsFormType } from '../types';
import CreateCommandsForm from './CreateCommandsForm';
import CreateMultipleCommandsForm from './CreateMultipleCommandsForm';

type Props = {
	title: string;
	isOpen: boolean;
	createCommandsForm: FormInstance;
	createMultipleCommandsForm: FormInstance;
	models: DeviceModelProfileDto[];
	onCancel: () => void;
	onSave: (commands: CreateCommandToBeSendDto) => Promise<void>;
};

const CreateCommandsModal = ({
	title,
	isOpen,
	onCancel,
	onSave,
	createCommandsForm,
	models,
	createMultipleCommandsForm,
}: Props) => {
	const intl = useIntl();

	const [loading, setLoading] = useState(false);
	const [activeKey, setActiveKey] = useState('1');

	const saveCommandsById = async () => {
		await createCommandsForm.validateFields();
		const values = createCommandsForm.getFieldsValue() as CreateCommandsFormType;
		const { pins, command } = values;
		await onSave({ pins, command });
	};

	const saveMultipleCommands = async () => {
		await createMultipleCommandsForm.validateFields();
		const values = createMultipleCommandsForm.getFieldsValue() as CreateMultipleCommandsFormType;
		const { totalDevicesAffected, command, advancedFilter, applyToLastEnabled, applyToLastCount } = values;
		let applyFilterToLast = 0;

		if (totalDevicesAffected === 0) {
			message.error(intl.formatMessage(translate('atLeastOneDeviceShouldBeAffectedbyFilter')));
			return;
		}

		if (applyToLastEnabled && applyToLastCount) {
			applyFilterToLast = applyToLastCount;
		}

		await onSave({
			pins: [],
			command,
			isMultiple: true,
			applyToLast: applyFilterToLast,
			filter: advancedFilter,
		});
	};

	const onOk = async () => {
		try {
			setLoading(true);

			if (activeKey === '1') {
				await saveCommandsById();
			} else {
				await saveMultipleCommands();
			}
		} catch (error) {
			message.error(intl.formatMessage(translate('commonError')));
		} finally {
			setLoading(false);
		}
	};

	const handleOnCancel = () => {
		createCommandsForm.resetFields();
		createMultipleCommandsForm.resetFields();
		onCancel();
	};

	const onChangeTab = (activeKey: string): void => {
		setActiveKey(activeKey);
	};

	const tabs: TabsProps = {
		items: [
			{
				key: '1',
				label: intl.formatMessage(translate('pin')),
				children: <CreateCommandsForm form={createCommandsForm} />,
			},
			{
				key: '2',
				label: intl.formatMessage(translate('multiple')),
				children: <CreateMultipleCommandsForm models={models} form={createMultipleCommandsForm} />,
			},
		],
	};

	return (
		<Modal
			title={title}
			open={isOpen}
			centered
			onCancel={handleOnCancel}
			maskClosable={false}
			cancelButtonProps={{ hidden: true }}
			okText={intl.formatMessage(translate('save'))}
			onOk={onOk}
			confirmLoading={loading}
			destroyOnClose={true}
		>
			<Tabs type="card" items={tabs.items} onChange={onChangeTab} />
		</Modal>
	);
};

export default CreateCommandsModal;

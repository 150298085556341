import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { CloseCircleFilled } from '@ant-design/icons';
import { translate } from '@optimusgps/optimus-intl';
import { AutoComplete, Button, Input, InputRef, Select, Typography } from 'antd';
import { RootState } from '../../../store/configureStore';
import { slashToHyphenFormat } from '../../../utilities/util';
import { useAppSelector } from '../../hooks/redux.hooks';
import { AppliedFilter, FilterData } from '../../types/filterComponent.types';
import DatePicker from '../dayjs/DatePicker';

const NO_ICON = <></>;

type Props = {
	filter: FilterData;
	onApply: (filter?: AppliedFilter) => void;
	appliedFilters: AppliedFilter[];
};

const FilterOptions = ({ filter, onApply, appliedFilters }: Props) => {
	const intl = useIntl();
	const [selectedValue, setSelectedValue] = useState<string | number>('');
	const [valueLabel, setvalueLabel] = useState<Record<string, string | undefined>>();
	const inputRef = useRef<InputRef>(null);

	const systemConfig = useAppSelector((state: RootState) => state.auth.user.unitSystemConfig);
	const dateFormat = slashToHyphenFormat(systemConfig);

	const onRemoveFilter = useCallback(() => {
		onApply(undefined);
	}, [onApply]);

	const selectOptions = useMemo(() => {
		return filter.options?.map((filterItem) => ({
			key: filterItem.key,
			label: filterItem.label,
			value: filterItem.value,
			intlSelectedDropdown: filterItem?.intlSelectedDropdown,
		}));
	}, [filter.options]);

	const renderFilterComponent = () => {
		switch (filter.type) {
			case 'TEXT':
				return (
					<Input
						onPressEnter={(e) => setSelectedValue(e.currentTarget.value)}
						placeholder={filter.placeholder}
						size="small"
						ref={inputRef}
						bordered={false}
					/>
				);
			case 'AUTOCOMPLETE':
				return (
					<AutoComplete
						value={selectedValue}
						onChange={(value) => setSelectedValue(value)}
						dataSource={filter.options?.map((option) => option.value)}
						size="small"
						bordered={false}
					/>
				);
			case 'DATE':
				return (
					<DatePicker
						onChange={(date, dateString) => setSelectedValue(dateString)}
						size="small"
						bordered={false}
						suffixIcon={NO_ICON}
						format={dateFormat}
					/>
				);
			case 'DROPDOWN':
				return (
					<Select
						options={selectOptions}
						bordered={false}
						onSelect={(
							value: string | number,
							option: { label: React.ReactNode; intlSelectedDropdown?: string }
						) => {
							setSelectedValue(value);
							const label = option.label?.toString() || '';
							setvalueLabel({
								label,
								intlSelectedDropdown: option.intlSelectedDropdown,
							});
						}}
						className="select"
						size="small"
						placeholder={
							<Typography.Text ellipsis type="secondary">
								{intl.formatMessage(translate('selectValue'))}
							</Typography.Text>
						}
					/>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current!.focus({
				cursor: 'end',
			});
		}
	}, [inputRef]);

	useEffect(() => {
		if ((typeof selectedValue === 'number' || selectedValue.trim() !== '') && filter?.filterwhere) {
			const currentAppliedFilter: AppliedFilter = {
				id: filter.id,
				type: filter.type,
				filterwhere: filter?.filterwhere,
				intlLabelCode: filter?.intlLabelCode,
				intlSelectedDropdown: valueLabel?.intlSelectedDropdown,
				value: selectedValue,
				label: valueLabel?.label,
			};

			const filterAlreadyApplied = appliedFilters.some(
				(appliedFilterItem) => appliedFilterItem.id === currentAppliedFilter.id
			);

			if (!filterAlreadyApplied) {
				onApply(currentAppliedFilter);
				setSelectedValue('');
			}
		}
	}, [selectedValue]);

	return (
		<div className="filter-options-container">
			<div className="filter-options-container-type">
				{renderFilterComponent()}
				<Button
					type="text"
					icon={<CloseCircleFilled />}
					className="filter-input-pill-action"
					size="small"
					onClick={onRemoveFilter}
				/>
			</div>
		</div>
	);
};

export default FilterOptions;

import axios from 'axios';
import { ApiResult, Result } from '../../OptimusRoutes/interfaces';
import { CommandDto } from '../modelProfile/modelProfile.dto';
import { CameraDto } from './camera.dto';

type CameraService = {
	get: (deviceId: number) => Promise<Result<ApiResult<CameraDto[]>>>;
	create: (deviceId: number, body: Partial<CameraDto>) => Promise<Result<Partial<CameraDto>>>;
	update: (deviceId: number, cameraId: number, body: Partial<CameraDto>) => Promise<Result<Partial<CameraDto>>>;
	delete: (deviceId: number, cameraId: number) => Promise<void>;
	getCommands: (deviceId: number) => Promise<Result<CommandDto[]>>;
};

const get = async (deviceId: number): Promise<Result<ApiResult<CameraDto[]>>> => {
	const { data } = await axios.get<Result<ApiResult<CameraDto[]>>>(`/v2/devices/${deviceId}/camera`);
	return data;
};

const create = async (deviceId: number, body: Partial<CameraDto>): Promise<Result<Partial<CameraDto>>> => {
	const { data } = await axios.post<Result<Partial<CameraDto>>>(`/v2/devices/${deviceId}/camera`, body);
	return data;
};

const update = async (
	deviceId: number,
	cameraId: number,
	body: Partial<CameraDto>
): Promise<Result<Partial<CameraDto>>> => {
	const { data } = await axios.patch<Result<Partial<CameraDto>>>(`/v2/devices/${deviceId}/camera/${cameraId}`, body);
	return data;
};

const remove = async (deviceId: number, cameraId: number): Promise<void> =>
	await axios.delete(`/v2/devices/${deviceId}/camera/${cameraId}`);

const getCommands = async (deviceId: number): Promise<Result<CommandDto[]>> => {
	const { data } = await axios.get<Result<CommandDto[]>>(`/v2/devices/${deviceId}/multimedia-commands`);
	return data;
};

const Service: CameraService = {
	get,
	create,
	update,
	delete: remove,
	getCommands,
};

export default Service;

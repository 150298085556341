import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import async, { parallel, queue, reflectAll } from 'async';
import axios from 'axios';
import _, { forEach } from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import {
	getDetaileTracebyId,
	getEventsBetweenDates,
	getEventsById,
	getTraceById,
} from '../../actions/TrackingHistoryAction';
import {
	DIGITAL_INPUTS,
	EVENT_ENUM,
	MAX_ZINDEX,
	MULTIMEDIA_TYPE,
	ROLES,
	selectedMapTrail,
} from '../../constants/global';
import CameraService from '../../features/camera/camera.service';
import MultimediaService from '../../features/multimedia/Multimedia.service';
import mqtt, { routingKeys } from '../../features/rabbitmq';
import { GetLineRouteMonitor, GetPointsRouteMonitor } from '../../features/routeMonitor/actions';
import {
	getEventsBetweenDatesExternal,
	getEventsByIdExternal,
	getTraceByIdExternal,
	getTrailHistoryExternal,
} from '../../features/shareDevicesExternal/actions';
import unitSystem from '../../features/unitSystems';
import icon from '../../lib/icon';
import { distanceInMeters, isVehicleMoving, retryCall, validatePoliceSiren } from '../../utilities/util';
import { isSameDay } from '../dateUtils';
import { shouldDisplayMultimediaIcons } from '..';
import cellTowerIntance from './cellTower';
import places from './places';
import poiInstance from './poi';

class devices {
	constructor(device, map, config) {
		this.config = {
			pastDate: false,
			...config,
			utcOffsetMinutes: device.utcOffsetMinutes,
			tanksConfig: device.tanksConfig,
			thermometerConfig: device.thermometersConfig,
			speedRange: {
				...config.speedRange,
				previousConfig: undefined,
				previousSegment: [],
			},
		};
		moment.locale(config.locale);
		this.dbTrace = {
			trace: [],
			traceLines: [],
			positions: [],
			events: [],
			mediaEvents: [],
		};
		this.rabTrace = {
			trace: [],
			traceLines: [],
			positions: [],
		};
		this.showTraceInWeeklyMap = false;
		this.photoHash = {};
		this.poi = {};
		this.line = {};
		this.events = [];
		this.mediaEvents = [];
		this.lastEvents = [];
		this.original = device;
		this.originalTrace = [];
		this.map = map;
		this.socket = null;
		this.deviceInfo = {};
		this.cellTower = null;
		this.commands = [];
		if (device.trackingHistoryId != null) {
			this.mapPoint = this.createMarkerWithLabel(device);
			this.draw();
		} else {
			this.mapPoint = null;
			this.initCellTower();
		}
		this.source = undefined;
		this.init(device);
	}

	init = (device) => {
		this.initRabbitSubscription(device.pin);
		if (!this.config.isMainMap) {
			this.getDeviceInfo();
		}
		if (this.config.eventEmmiter) {
			this.config.eventEmmiter.addListener(`eventUpdate`, this.updateEvent);
			this.config.eventEmmiter.addListener(`toggle_label`, this.removeLabels);
			this.config.eventEmmiter.addListener(`toggle_trace`, this.showTrace);
		}
	};

	initCellTower = () => {
		const deviceCellTower = this.config.cellTowerData.find(({ deviceId }) => deviceId === this.original.id);
		if (deviceCellTower) {
			this.cellTower = new cellTowerIntance({ deviceCellTower, map: this.map, config: this.config });
			this.cellTower.show();
		}
	};

	showTrace = () => {
		this.showTraceInWeeklyMap = !this.showTraceInWeeklyMap;
		this.updateTraces();
	};

	removeLabels = () => {
		Object.keys(this.line).forEach((key) => {
			this.line[key].removeLable();
		});
	};

	setCancelToken = () =>
		new axios.CancelToken((c) => {
			this.source = c;
		});

	loadDbTraceRetry = (selected, date, getEvents, getTrace, retryCount = 3) => {
		if (retryCount > 0) {
			if (this.source !== undefined) this.source();
			if (selected) {
				let tasks = [
					(callback) => {
						getEvents(
							this.original.id,
							date,
							this.config.utcOffsetMinutes,
							this.config.userId,
							this.setCancelToken()
						)
							.then((events) => callback(null, events))
							.catch(callback);
					},
					(callback) => {
						getTrace(this.original.id, date, this.config.utcOffsetMinutes, this.setCancelToken())
							.then((tracking) => callback(null, tracking))
							.catch(callback);
					},
					(callback) => {
						if (
							shouldDisplayMultimediaIcons({
								isImpersonating: this.config.isImpersonating,
								shared: this.original.shared,
								roles: this.config.roles,
								impersonatorRoles: this.config.impersonatorRoles,
							})
						) {
							const startOfDay = moment(date).format('YYYY-MM-DD 00:00:00');
							const endOfDay = moment(date).format('YYYY-MM-DD 23:59:59');
							MultimediaService.getEvents(this.original.id, startOfDay, endOfDay, this.setCancelToken())
								.then(({ success, error }) => callback(error, success))
								.catch(callback);
						} else {
							callback(null, []);
						}
					},
				];

				parallel(reflectAll(tasks))
					.then((response) => {
						let [eventsResponse, trackingResponse, mediaEventsResponse] = response;
						if (trackingResponse.error && eventsResponse.error) {
							throw new Error('No trace or event.');
						}

						let traking = trackingResponse.error
							? { data: { tracking: { trace: [], events: null } } }
							: trackingResponse.value;
						let events = eventsResponse.error ? { data: [] } : eventsResponse.value;
						let mediaEvents = mediaEventsResponse.error ? { data: [] } : mediaEventsResponse.value;
						if (this.dbTrace.traceLines.length) {
							this.dbTrace.traceLines.forEach((polyLine) => polyLine.setMap(this.map));
						}

						if (this.dbTrace.trace.length > 0) {
							this.dbTrace.trace.forEach((prop) => {
								prop.setMap(null);
							});
						}

						if (this.events.length > 0) {
							this.events.forEach((prop) => {
								prop.setMap(null);
							});
						}

						if (this.mediaEvents.length > 0) {
							this.mediaEvents.forEach((prop) => {
								prop.setMap(null);
							});
						}

						if (this.rabTrace.traceLines.length) {
							this.rabTrace.traceLines.forEach((polyLine) => polyLine.setMap(this.map));
						}

						if (this.rabTrace.trace.length > 0) {
							this.rabTrace.trace.forEach((prop) => {
								prop.setMap(null);
							});
						}

						forEach(this.poi, (poi) => {
							poi.hide();
						});
						this.dbTrace.trace.length = 0;
						this.rabTrace.trace.length = 0;
						this.dbTrace.positions.length = 0;
						this.rabTrace.positions.length = 0;
						this.events.length = 0;
						this.lastEvents.length = 0;
						this.dbTrace.events.length = 0;
						this.dbTrace.mediaEvents.length = 0;
						this.poi = {};

						traking.data.tracking.events = [...events.data];
						traking.data.tracking.mediaEvents = [...mediaEvents];
						return traking;
					})
					.then((response) => {
						let bounds = new window.google.maps.LatLngBounds();
						let lastDbReport = response.data.tracking.trace.pop();
						let events = _.groupBy(response.data.tracking.events, 'trackingHistoryId');
						let mediaEvents = _.groupBy(response.data.tracking.mediaEvents, 'trackingHistoryId');
						let tanksLastReport = [];
						let thermometersLastReport = [];
						let thermometersLastReportForMapControler = {};
						let lastObdInfoTrakingHistoryId;
						response.data.tracking.trace.forEach((t, index) => {
							if (t.hasOBD) lastObdInfoTrakingHistoryId = t.id;
							if (t.tanks.length) {
								tanksLastReport = t.tanks;
							}
							if (t.thermometers) {
								thermometersLastReport = t.thermometers;
								Object.keys(t.thermometers).forEach((thermometer) => {
									thermometersLastReportForMapControler[thermometer] = t.thermometers[thermometer];
								});
							}
							let azimuth = t.speed > 0 ? t.azimuth : this.original.azimuth;
							let newMapPoint = null;
							if (t.id in events) {
								this.addEvents({
									...t,
									trackingHistoryId: t.id,
									events: events[t.id],
								});
							}

							if (t.id in mediaEvents) {
								const multimediaVideos = mediaEvents[t.id].filter(
									({ type }) => type === MULTIMEDIA_TYPE.VIDEO
								);
								const multimediaPhotos = mediaEvents[t.id].filter(
									({ type }) => type === MULTIMEDIA_TYPE.PHOTO
								);
								if (multimediaVideos.length) {
									this.addMediaEvents({
										...t,
										trackingHistoryId: t.id,
										mediaEvents: multimediaVideos,
									});
								}
								if (multimediaPhotos.length) {
									this.addEvents({
										...t,
										trackingHistoryId: t.id,
										events: multimediaPhotos.map(({ eventId, trackingHistoryId, type }) => ({
											id: eventId,
											trackingHistoryId,
											data: null,
											isAccountEvent: 0,
											type,
										})),
									});
								}
							}

							this.original.azimuth = azimuth;
							if (index === 0) {
								newMapPoint = this.createMapPoint(
									{
										trackingHistoryId: t.id,
										azimuth,
										utcDate: t.utcDate,
										icon: icon.svgToIcon({
											id: 'startPoint',
											azimuth: 0,
											color: '#2f9cff',
										}),
										lng: t.longitude,
										lat: t.latitude,
										batteryVoltage: t.batteryVoltage,
										signal: t.signal,
										speed: t.speed,
										rpm: t.rpm,
										battery: t.battery,
										photoId: t.photoId,
										tanks: t.tanks,
										thermometersConfig: this.config.thermometerConfig,
										thermometers: t.thermometers,
										tanksConfig: this.config.tanksConfig,
										hasOBD: t.hasOBD,
										isOn: t.isOn,
										isIdle: t.isIdle,
									},
									true
								);
							} else {
								newMapPoint = this.createMapPoint({
									trackingHistoryId: t.id,
									utcDate: t.utcDate,
									icon: icon.getIcon(t.speed > 0 ? 'arrow' : 'circle', azimuth, '#2f9cff'),
									lng: t.longitude,
									lat: t.latitude,
									batteryVoltage: t.batteryVoltage,
									signal: t.signal,
									speed: t.speed,
									rpm: t.rpm,
									battery: t.battery,
									photoId: t.photoId,
									tanks: t.tanks,
									azimuth,
									thermometers: t.thermometers,
									thermometersConfig: this.config.thermometerConfig,
									tanksConfig: this.config.tanksConfig,
									hasOBD: t.hasOBD,
									isOn: t.isOn,
									isIdle: t.isIdle,
								});
							}

							bounds.extend(newMapPoint.getPosition());
							this.dbTrace.trace.push(newMapPoint);
							this.dbTrace.positions.push(newMapPoint.getPosition());
							this.dbTrace.events.push(events[t.id]);
							this.dbTrace.mediaEvents.push(mediaEvents[t.id]);
							this.originalTrace.push({
								...t,
								events: events[t.id] ? events[t.id].map((e) => e.id) : [],
								mediaEvents: mediaEvents[t.id] ? mediaEvents[t.id] : [],
							});
						});

						if (lastDbReport) {
							if (lastDbReport.hasOBD) lastObdInfoTrakingHistoryId = lastDbReport.id;
							if (lastDbReport.thermometers) {
								thermometersLastReport = lastDbReport.thermometers;
								Object.keys(lastDbReport.thermometers).forEach((thermometer) => {
									thermometersLastReportForMapControler[thermometer] =
										lastDbReport.thermometers[thermometer];
								});
							}
							lastDbReport = {
								...lastDbReport,
								tanks: tanksLastReport ? tanksLastReport : [],
								thermometers: thermometersLastReportForMapControler
									? thermometersLastReportForMapControler
									: [],
							};
							let tempAzimuth = this.original.azimuth;
							let newDate = moment.utc(lastDbReport.utcDate);
							let today = moment.utc();
							let diff = newDate.diff(today, 'days', true);
							this.original = {
								...this.original,
								trackingHistoryId: lastDbReport.id,
								utcDate: lastDbReport.utcDate,
								longitude: lastDbReport.longitude,
								latitude: lastDbReport.latitude,
								signal: lastDbReport.signal,
								rpm: lastDbReport.rpm,
								speed: lastDbReport.speed,
								events: lastDbReport.id in events ? events[lastDbReport.id] : [],
								azimuth: tempAzimuth,
								photoId: lastDbReport.photoId,
								tanks: lastDbReport.tanks,
								mapThermometers: thermometersLastReportForMapControler,
								thermometers: thermometersLastReport,
								thermometersConfig: this.config.thermometerConfig,
								tanksConfig: this.config.tanksConfig,
								lastObdInfoTrakingHistoryId,
								hasOBD: lastDbReport.hasOBD,
								battery: lastDbReport.battery,
								isOn: lastDbReport.isOn,
								isIdle: lastDbReport.isIdle,
							};

							if (lastDbReport.id in events) {
								this.addEvents({
									...this.original,
									events: events[lastDbReport.id],
								});
							}

							if (lastDbReport.id in mediaEvents) {
								const multimediaVideos = mediaEvents[lastDbReport.id].filter(
									({ type }) => type === MULTIMEDIA_TYPE.VIDEO
								);
								const multimediaPhotos = mediaEvents[lastDbReport.id].filter(
									({ type }) => type === MULTIMEDIA_TYPE.PHOTO
								);
								if (multimediaVideos.length) {
									this.addMediaEvents({
										...lastDbReport,
										trackingHistoryId: lastDbReport.id,
										mediaEvents: multimediaVideos,
									});
								}
								if (multimediaPhotos.length) {
									this.addEvents({
										...lastDbReport,
										trackingHistoryId: lastDbReport.id,
										events: multimediaPhotos.map(({ eventId, trackingHistoryId, type }) => ({
											id: eventId,
											trackingHistoryId,
											data: null,
											isAccountEvent: 0,
											type,
										})),
									});
								}
							}

							this.config.eventEmmiter.emitEvent('updateMapControlDeviceOriginal', [this.original]);

							this.draw();
							if (this.config.selected && diff) {
								this.drawTrace(this.dbTrace);
								this.drawTrace(this.rabTrace);
							} else if (
								this.config.selected !== 0 &&
								this.config.centerOnSelect &&
								this.map !== undefined &&
								this.map !== null
							) {
								this.map.panTo(this.mapPoint.getPosition());
							}

							bounds.extend(new window.google.maps.LatLng(lastDbReport.latitude, lastDbReport.longitude));
							this.map.fitBounds(bounds);
							this.config.LoadingTrace();
							this.originalTrace.push({
								...this.original,
								trackingHistoryId: lastDbReport.id,
								utcDate: lastDbReport.utcDate,
								longitude: lastDbReport.longitude,
								latitude: lastDbReport.latitude,
								signal: lastDbReport.signal,
								speed: lastDbReport.speed,
								rpm: lastDbReport.rpm,
								events: lastDbReport.id in events ? events[lastDbReport.id] : [],
								azimuth: tempAzimuth,
								photoId: lastDbReport.photoId,
								tanks: lastDbReport.tanks,
								thermometers: lastDbReport.thermometers,
								thermometersConfig: this.config.thermometerConfig,
								tanksConfig: this.config.tanksConfig,
								lastObdInfoTrakingHistoryId,
								hasOBD: lastDbReport.hasOBD,
								battery: lastDbReport.battery,
								isOn: lastDbReport.isOn,
								isIdle: lastDbReport.isIdle,
							});
						} else {
							if (!this.config.routeMonitor) {
								let newDate = moment(this.original.utcDate)
									.startOf('days')
									.utcOffset(this.config.utcOffsetMinutes, true)
									.utc()
									.format('YYYY-MM-DD');
								if (this.config.sharedExternalMap) {
									this.loadDbTraceRetry(
										selected,
										newDate,
										getEventsByIdExternal,
										getTraceByIdExternal,
										retryCount - 1
									);
								} else {
									this.loadDbTraceRetry(
										selected,
										newDate,
										getEventsById,
										getTraceById,
										retryCount - 1
									);
								}
							} else {
								if (this.config.eventEmmiter) {
									this.config.eventEmmiter.emitEvent('past_date', []);
									this.config.eventEmmiter.emitEvent('point_loaded', []);
								}
							}
							return true;
						}
						this.config.UpdateDeviceEvent();
						this.source = undefined;
						return false;
					})
					.then((cancelRequest) => {
						if (!cancelRequest && this.config.routeMonitor) {
							let newDate = moment.utc(this.original.utcDate).utcOffset(this.config.utcOffsetMinutes);
							GetPointsRouteMonitor({
								id: this.original.id,
								clientId: this.config.clientId,
								day: newDate.day(),
								cancelToken: new axios.CancelToken((c) => {
									this.source = c;
								}),
							}).then((points) => {
								this.config.eventEmmiter.emitEvent('point_count', [points.length]);
								const self = this;

								let q = queue((poi, callback) => {
									self.poi[poi.id] = new poiInstance({
										map: self.map,
										point: poi,
										config: {
											...self.config,
										},
									});
									callback();
								}, 10);

								q.push(points);

								q.drain(() => {
									async.each(
										self.poi,
										(poi, callback) => {
											async.each(
												self.originalTrace,
												(trace, callback2) => {
													poi.analizeVisit(trace);
													callback2();
												},
												() => {
													if (poi.pointIsVisited > 0)
														self.config.eventEmmiter.emitEvent('point_is_visited', [
															poi.info.id,
														]);
													callback();
												}
											);
										},
										() => {
											self.config.eventEmmiter.emitEvent('point_loaded', []);
										}
									);
								});
							});
						}
					})
					.then((cancelRequest) => {
						if (!cancelRequest && this.config.routeMonitor) {
							let newDay = moment.utc(this.original.utcDate).utcOffset(this.config.utcOffsetMinutes);
							let newDate = moment
								.utc(this.original.utcDate)
								.add(this.config.utcOffsetMinutes, 'minutes')
								.format('YYYY-MM-DD HH:mm:ss');
							const self = this;
							GetLineRouteMonitor({
								id: this.original.id,
								clientId: this.config.clientId,
								day: newDay.day(),
								date: newDate,
								utcOffsetMinutes: this.config.utcOffsetMinutes,
								cancelToken: new axios.CancelToken((c) => {
									this.source = c;
								}),
							}).then((lines) => {
								this.config.eventEmmiter.emitEvent('add_point_count', [lines.length]);
								lines.forEach((item) => {
									item = {
										...item,
										encodedObject: item.placeEncodedObject,
									};
									self.line[item.id] = new places('routeMonitor', self.config);
									self.line[item.id].addPlaceByMap(item.id, item, self.map);
									if (item.lineIsVisited)
										self.config.eventEmmiter.emitEvent('line_is_visited', [item.id]);
								});
								self.config.eventEmmiter.emitEvent('point_loaded', []);
							});
						}
					})
					.catch(() => {
						this.config.LoadingTrace();
					});
			}
		} else {
			this.config.LoadingTrace();
		}
	};

	loadDbTrace = (selected, date, getEvents, getTrace) => {
		this.loadDbTraceRetry(selected, date, getEvents, getTrace);
	};

	load = (map, options, config) => {
		this.map = map;
		if (this.mapPoint == null) {
			if (this.cellTower) this.cellTower.load(map);
			return;
		}
		this.setMapPointOptions({
			...options,
			map: map,
		});
		this.setConfiguration(config);
		this.draw();
	};

	createMarkerWithLabel = (device, setmap = true) => {
		let carColor = this.getCarColor(device);
		let marker = new MarkerWithLabel({
			options: {
				visible: true,
				optimized: false,
				clickable: true,
				zIndex: MAX_ZINDEX,
				title: moment.utc(device.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow(),
			},
			map: setmap ? this.map : null,
			labelVisible: true,
			labelContent: this.original.description,
			labelAnchor: new window.google.maps.Point(0, 35),
			labelClass: `markerLabel ${carColor.class}`,
			labelZIndexOffset: MAX_ZINDEX - 1,
		});
		marker.addListener('mouseover', () => {
			marker.set('title', this.getMarkerTitle());
		});

		return marker;
	};

	getMarkerTitle = () => {
		let title = moment.utc(this.original.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow();
		const { speed, battery } = this.original;
		if (speed > 0) title += ` - ${this.config.messages.speed} ( ${unitSystem.speed.toString(speed)} )`;
		if (battery > -1)
			title += ` -  ${this.config.messages.batteryLevel} ( ${unitSystem.getPercentText(battery / 100, {
				decimals: 0,
			})} )`;
		return title;
	};

	createMapPoint = (device, eventLevel) => {
		let marker = new window.google.maps.Marker({
			position: {
				lat: device.lat,
				lng: device.lng,
			},
			options: {
				visible: true,
				optimized: false,
				clickable: true,
				zIndex: !eventLevel ? MAX_ZINDEX - 2 : MAX_ZINDEX - 1,
				title: moment.utc(device.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow(),
			},
			speed: device.speed,
			thermometers: device.thermometers,
			icon: device.icon,
		});

		marker.addListener('click', () => {
			this.showInfowindow(device);
		});

		marker.addListener('mouseover', () => {
			marker.set('title', moment.utc(device.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow());
		});
		return marker;
	};

	createEventPoint = (device) => {
		let marker = new window.google.maps.Marker({
			position: {
				lat: device.latitude,
				lng: device.longitude,
			},
			options: {
				visible: true,
				optimized: false,
				clickable: true,
				zIndex: MAX_ZINDEX - 1,
				title: moment.utc(device.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow(),
			},
			icon: device.icon,
		});

		let clickListener = marker.addListener('click', () => {
			this.showEventInfowindow(device);
		});

		marker.getMediaEvents = () => [];

		marker.addListener('mouseover', () => {
			marker.set('title', moment.utc(device.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow());
		});

		if (device.event.id === EVENT_ENUM.PHOTO_REPORT) {
			let data = JSON.parse(device.event.data);
			this.photoHash[data.photoId] = device.event ? device.event : {};
			marker.getPhotoId = () => data.photoId;
		}

		marker.getEvent = () => device.event.id;
		marker.getTrackingHistoryId = () => device.trackingHistoryId;

		marker.renewClickListener = (update) => {
			window.google.maps.event.removeListener(clickListener);

			marker.addListener('click', () => {
				this.showEventInfowindow({ ...device, event: update });
			});
		};

		return marker;
	};

	createMediaEventPoint = (event, multimediaEvents) => {
		let marker = new window.google.maps.Marker({
			position: {
				lat: event.latitude,
				lng: event.longitude,
			},
			options: {
				visible: true,
				optimized: false,
				clickable: true,
				zIndex: MAX_ZINDEX - 1,
				title: moment.utc(event.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow(),
			},
			icon: event.icon,
		});
		let clickListener = marker.addListener('click', () => {
			if (this.config.eventEmmiter) {
				this.config.OpenMultimediaByTrackingHistoryId({
					trackingHistoryId: event.trackingHistoryId,
					utcOffsetMinutes: this.config.utcOffsetMinutes,
					deviceId: this.original.id,
					shared: this.original.shared,
				});
			}
		});
		marker.getTrackingHistoryId = () => event.trackingHistoryId;
		marker.getMediaEvents = () => multimediaEvents;

		marker.addListener('mouseover', () => {
			marker.set('title', moment.utc(event.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow());
		});

		marker.renewClickListener = (update) => {
			window.google.maps.event.removeListener(clickListener);

			marker.addListener('click', () => {
				this.onMultimediaRecieved({ trackingHistoryId: event.trackingHistoryId });
			});
		};

		return marker;
	};

	onMultimediaRecieved = ({ trackingHistoryId, key, shouldRefreshData = false }) => {
		if (shouldRefreshData) {
			this.config.StropRequestVideoLoading();
		}
		this.config.OpenMultimediaByTrackingHistoryId({
			trackingHistoryId: trackingHistoryId.toString(),
			utcOffsetMinutes: this.config.utcOffsetMinutes,
			deviceId: this.original.id,
			shouldRefreshData,
			channelKey: key,
			shared: this.original.shared,
		});
	};

	updateEvent = (update) => {
		if (update.id !== EVENT_ENUM.PHOTO_REPORT) return;

		this.events = this.updateAllEvents(this.events, update);
		this.lastEvents = this.updateLastEvents(this.lastEvents, update);
		this.config.UpdateDeviceEvent();
	};

	updateLastEvents = (events, update) =>
		events.map((event) => {
			let data = JSON.parse(event.event.data);
			let updateData = JSON.parse(update.data);

			if (event.event.id !== EVENT_ENUM.PHOTO_REPORT || data.photoId !== updateData.photoId) return event;

			return {
				...event,
				photoId: data.photoId,
				event: {
					...event.event,
					data: update.data,
					photoId: data.photoId,
				},
			};
		});

	updateMultimediaLastEvents = (update) => {
		this.lastEvents = this.lastEvents.map(({ trackingHistoryId, mediaEvents = [], ...rest }) => {
			if (trackingHistoryId === update.trackingHistoryId) {
				const updatedMediaEvents = [...mediaEvents, update];
				return {
					trackingHistoryId,
					mediaEvents: updatedMediaEvents,
					...rest,
					...this.getMultimediaIcon(updatedMediaEvents),
				};
			}
			return { trackingHistoryId, ...rest };
		});
	};

	updateAllEvents = (events, update) =>
		events.map((event) => {
			if (event.getEvent() !== EVENT_ENUM.PHOTO_REPORT) return event;

			let eventData = this.photoHash[event.getPhotoId()];

			if (!eventData || eventData.status === 2) return event;

			let updateData = JSON.parse(update.data);
			let data = JSON.parse(eventData.data);

			if (data.photoId === updateData.photoId) {
				let photoId = updateData.status ? data.photoId : updateData.photoId;
				this.photoHash[photoId] = { ...update, photoId };
				if (updateData.status === 2) event.renewClickListener({ ...update, photoId });
			}

			return event;
		});

	addEvents = (marker) => {
		marker.events.forEach((event) => {
			let eventPoint = {
				event,
				trackingHistoryId: marker.trackingHistoryId,
				utcDate: marker.utcDate,
				longitude: marker.longitude,
				latitude: marker.latitude,
				signal: marker.signal,
				azimuth: marker.azimuth,
				speed: marker.speed,
				tanks: marker.tanks,
				photoId: marker.photoId,
				thermometers: marker.thermometers,
				thermometersConfig: this.config.thermometerConfig,
				tanksConfig: this.config.tanksConfig,
				isOn: marker.isOn,
				isIdle: marker.isIdle,
			};

			if (DIGITAL_INPUTS.includes(event.id) && event.data) {
				let data = JSON.parse(event.data);
				eventPoint.icon = data.eventIconId
					? icon.svgToIcon({
							id: `event${data.eventIconId}`,
							azimuth: 0,
							color: '#2f9cff',
					  })
					: icon.svgToIcon({
							id: `event${event.id}`,
							azimuth: 0,
							color: '#2f9cff',
					  });
			} else if (event.type === MULTIMEDIA_TYPE.PHOTO) {
				eventPoint = {
					...eventPoint,
					icon: icon.svgToIcon({
						id: MULTIMEDIA_TYPE.PHOTO,
						color: '#2f9cff',
					}),
					type: MULTIMEDIA_TYPE.PHOTO,
					mediaEvents: [event],
				};
			} else {
				eventPoint.icon = icon.svgToIcon({
					id: `event${event.id}`,
					azimuth: 0,
					color: '#2f9cff',
				});
			}

			let e = {
				...marker,
				...eventPoint,
				time: moment.utc(marker.utcDate).utcOffset(this.config.utcOffsetMinutes).format('hh:mm A'),
			};
			delete e.events;
			this.lastEvents.unshift(e);

			this.lastEvents = this.lastEvents.sort((a, b) => moment.utc(b.utcDate).diff(moment.utc(a.utcDate)));

			if (this.lastEvents.length > 6) this.lastEvents.pop();

			this.events.push(this.createEventPoint(eventPoint));
		});
	};

	addMediaEvents = (marker) => {
		const [event] = marker.mediaEvents;

		let eventPoint = {
			...event,
			...this.getMultimediaIcon(marker.mediaEvents),
			time: moment.utc(marker.utcDate).utcOffset(this.config.utcOffsetMinutes).format('hh:mm A'),
			mediaEvents: marker.mediaEvents,
		};

		this.lastEvents.unshift(eventPoint);
		this.lastEvents = this.lastEvents.sort((a, b) => moment.utc(b.utcDate).diff(moment.utc(a.utcDate)));
		if (this.lastEvents.length > 6) this.lastEvents.pop();
		this.events.push(this.createMediaEventPoint(eventPoint, marker.mediaEvents));
	};

	getMultimediaIcon = (mediaEvents) => {
		let hasImage = false;
		let hasVideo = false;
		forEach(mediaEvents, (event) => {
			switch (event.type) {
				case MULTIMEDIA_TYPE.VIDEO:
					hasVideo = true;
					break;
				case MULTIMEDIA_TYPE.PHOTO:
					hasImage = true;
					break;
				default:
					// eslint-disable-next-line no-console
					console.log('unsuported Media.');
			}
		});
		if (hasImage && hasVideo) {
			return {
				icon: icon.svgToIcon({
					id: MULTIMEDIA_TYPE.VIDEO_PHOTO,
					color: '#2f9cff',
				}),
				type: MULTIMEDIA_TYPE.VIDEO_PHOTO,
			};
		} else if (hasImage) {
			return {
				icon: icon.svgToIcon({
					id: MULTIMEDIA_TYPE.PHOTO,
					color: '#2f9cff',
				}),
				type: MULTIMEDIA_TYPE.PHOTO,
			};
		} else if (hasVideo) {
			return {
				icon: icon.svgToIcon({
					id: MULTIMEDIA_TYPE.VIDEO,
					color: '#2f9cff',
				}),
				type: MULTIMEDIA_TYPE.VIDEO,
			};
		}
	};

	removeCellTower = () => {
		if (this.cellTower) {
			this.cellTower.hide();
			this.cellTower = null;
		}
	};
	updateDeviceInformation = (data) => {
		if (this.config.selected && !this.config.pastDate) {
			const { information } = data;
			const { lastEvent, utcDate, multimediaStatus, firmware } = information;
			this.original = {
				...this.original,
				utcDate,
				rabbitMqLastReport: {
					event: { id: lastEvent.id, utcDate: lastEvent.utcDate },
					multimediaStatus,
					firmware,
				},
			};
			this.config.UpdateDeviceEvent();
			if (this.config.eventEmmiter) {
				this.config.eventEmmiter.emitEvent('updateMapControlDeviceOriginal', [this.original]);
			}
		}
	};

	moveDevice = (wsMsg) => {
		let msg = JSON.parse(wsMsg);
		let data = msg.data;
		switch (msg.type) {
			case 'position': {
				this.setDevicePosition(data);
				break;
			}
			case 'information': {
				this.updateDeviceInformation(data);
				break;
			}
			case 'isolatedEvent': {
				if (
					this.original.id === data.device.id &&
					this.config.selected &&
					isSameDay({
						date: data.position.utcDate,
						current: this.original.utcDate,
						utcOffsetMinutes: this.original.utcOffsetMinutes,
					})
				) {
					if (data.position.events.length > 0) {
						const [event] = data?.position?.events;

						const { trackingHistoryId, events, latitude, longitude, utcDate } = data.position;
						if (
							shouldDisplayMultimediaIcons({
								isImpersonating: this.config.isImpersonating,
								shared: this.original.shared,
								roles: this.config.roles,
								impersonatorRoles: this.config.impersonatorRoles,
							}) &&
							event?.data?.type &&
							event?.data?.type === MULTIMEDIA_TYPE.VIDEO
						) {
							const event = {
								...data.position,
								trackingHistoryId,
								latitude,
								longitude,
								utcDate,
								mediaEvents: events.map(({ id, data: { multimediaId, ...rest } }) => ({
									id: multimediaId,
									trackingHistoryId,
									eventId: id,
									latitude,
									longitude,
									utcDate,
									...rest,
								})),
							};
							const [multimedia] = event.mediaEvents;
							this.addMediaEvents(event);

							this.onMultimediaRecieved({
								trackingHistoryId,
								key: multimedia.key,
								shouldRefreshData: true,
							});
						} else {
							if (event?.data?.type === MULTIMEDIA_TYPE.PHOTO) {
								const { trackingHistoryId, events } = data.position;
								const multimediaPhotos = events.map(({ id, data: { type, eventId, ...rest } }) => ({
									id: eventId,
									trackingHistoryId,
									eventId: id,
									latitude,
									longitude,
									utcDate,
									data: null,
									isAccountEvent: 0,
									type,
									...rest,
								}));

								this.addEvents({
									...data.position,
									trackingHistoryId,
									events: multimediaPhotos,
								});

								this.config.StropRequestVideoLoading();
							} else {
								this.addEvents(data.position);
							}
						}
						this.config.UpdateDeviceEvent();
						this.events.map((point) => point.setMap(this.map));
					}
				} else if (this.original.id === data.device.id && this.config.selected) {
					if (data.position.events.length > 0) {
						const { trackingHistoryId, events } = data.position;
						const [{ data: multimedia }] = events;
						if (
							shouldDisplayMultimediaIcons({
								isImpersonating: this.config.isImpersonating,
								shared: this.original.shared,
								roles: this.config.roles,
								impersonatorRoles: this.config.impersonatorRoles,
							}) &&
							multimedia.type &&
							multimedia.type === MULTIMEDIA_TYPE.VIDEO
						) {
							this.onMultimediaRecieved({
								trackingHistoryId,
								key: multimedia.key,
								shouldRefreshData: true,
							});
						}
					}
				}
				break;
			}
			default:
				break;
		}
	};

	eventExist = (trackingHistoryId) =>
		this.events.filter(({ getTrackingHistoryId }) => getTrackingHistoryId() === trackingHistoryId).length > 0;

	updateMultimediaEvent = (trackingHistoryId, update) =>
		this.events.map((event) => {
			if (event.getTrackingHistoryId() === trackingHistoryId) {
				event.setOptions({
					...this.getMultimediaIcon([...event.getMediaEvents(), update]),
				});
			}
			return event;
		});

	multimediaEventExist = (trackingHistoryId) => {
		for (let index = 0; index < this.events.length; index++) {
			const event = this.events[index];

			const multimedias = event.getMediaEvents();
			if (multimedias.length > 0 && multimedias[0].trackingHistoryId === trackingHistoryId) {
				return true;
			}
		}
		return false;
	};

	setDevicePosition(data) {
		if (data.isMemory) return; // TO-DO add logic here.
		let carColor = this.getCarColor(data.position).color;
		let hasCellTower = !!this.cellTower;
		if (!this.mapPoint) {
			this.removeCellTower();
			let azimuth = data.position.speed > 0 ? data.position.azimuth : this.original.azimuth;
			this.mapPoint = this.createMarkerWithLabel({
				utcDate: data.position.utcDate,
				icon: icon.getIcon(data.position.speed > 0 ? 'arrow' : 'circle', azimuth, carColor),
				azimuth,
				batteryVoltage: data.position.batteryVoltage,
				lng: data.position.longitude,
				lat: data.position.latitude,
				signal: data.position.signal,
				speed: data.position.speed,
				battery: data.position.battery,
			});
			this.config.setDeviceListeners(this.original.id);
			this.config.UpdateDevice({
				...this.original,
				...data.position,
			});
		}

		if (this.config.selected && !this.config.pastDate && !hasCellTower) {
			let newDate = moment(data.position.utcDate)
				.startOf('days')
				.utcOffset(this.config.utcOffsetMinutes, true)
				.utc();
			let oldDate = moment(this.original.utcDate)
				.startOf('days')
				.utcOffset(this.config.utcOffsetMinutes, true)
				.utc();
			let diff = newDate.diff(oldDate, 'days');
			if (diff > 0) {
				this.select(this.map, newDate.format('YYYY-MM-DD'));
				return;
			}
			let azimuth = data.position.speed > 0 ? data.position.azimuth : this.original.azimuth;
			data.position.azimuth = azimuth;
			let newMapPoint = this.createMapPoint({
				...this.original,
				utcDate: this.original.utcDate,
				icon: icon.getIcon(this.original.speed > 0 ? 'arrow' : 'circle', azimuth, carColor),
				azimuth,
				batteryVoltage: this.original.batteryVoltage,
				lng: this.original.longitude,
				lat: this.original.latitude,
				signal: this.original.signal,
				rpm: this.original.rpm,
				speed: this.original.speed,
				battery: this.original.battery,
				tanks: this.original.tanks,
				photoId: this.original.photoId,
				thermometers: this.original.thermometers,
				thermometersConfig: this.config.thermometerConfig,
				tanksConfig: this.config.tanksConfig,
				hasOBD: this.original.hasOBD,
				isOn: this.original.isOn,
				isIdle: this.original.isIdle,
			});
			if (data.position.events.length > 0) {
				this.addEvents(data.position);
			}

			this.rabTrace.trace.push(newMapPoint);
			this.rabTrace.positions.push(newMapPoint.getPosition());
			this.originalTrace.push({
				...data.position,
				events: data.position.events.map((e) => e.id),
			});
		}
		if (!this.config.selected && !this.config.pastDate) {
			this.original = {
				...this.original,
				...data.position,
				hasOBD: !!data.position.obdInfo,
			};
			this.draw();
		}

		if (this.config.selected && !this.config.pastDate) {
			this.original = {
				...this.original,
				...data.position,
				hasOBD: !!data.position.obdInfo,
			};

			this.config.UpdateDeviceEvent();
			this.draw();

			if (
				this.config.selected !== 0 &&
				this.config.centerOnUpdate &&
				this.map !== undefined &&
				this.map !== null
			) {
				this.map.panTo(this.mapPoint.getPosition());
			}

			this.drawTrace(this.rabTrace);
			forEach(this.poi, (poi) => {
				poi.updateVisits({
					...this.original,
					events: this.original.events.map((e) => e.id),
				});
			});
			forEach(this.line, (line) => {
				line.analizeVisit({ ...this.original }, this.config);
			});

			if (this.config.eventEmmiter) {
				this.config.eventEmmiter.emitEvent('updateMapControlDeviceOriginal', [this.original]);
			}
		}
		this.originalTrace.push(this.original);
		if (this.config.eventEmmiter) {
			this.config.eventEmmiter.emitEvent('update_device', [data.position]);
		}

		if (this.config.multipleMap && !this.config.selected) {
			this.config.centerMap();
		}
	}

	setConfiguration = (newConfig) => {
		this.config = {
			...this.config,
			...newConfig,
		};
	};

	setMapPointOptions = (options, device = this.original) => {
		this.mapPoint.setOptions({
			...options,
		});
		if ('map' in options) this.map = options.map;
		this.original = device;
	};

	updateVehicleInfo = (newOriginal) => {
		this.setConfiguration({
			utcOffsetMinutes: newOriginal.utcOffsetMinutes,
		});
		this.original = { ...this.original, ...newOriginal };

		this.mapPoint.set('labelContent', newOriginal.description);
		this.draw();
	};

	rabbitReconnect = (pin) => {
		setTimeout(() => retryCall(3, () => mqtt.subscribe(pin, this.moveDevice)), 3000);
		setTimeout(
			() => retryCall(3, () => mqtt.subscribe(routingKeys.USER_ID(this.config.userId), this.moveDevice)),
			3000
		);
	};

	initRabbitSubscription = (pin) => {
		try {
			if (mqtt.connected) {
				mqtt.subscribe(pin, this.moveDevice);
				mqtt.subscribe(routingKeys.USER_ID(this.config.userId), this.moveDevice);
			} else {
				this.rabbitReconnect(pin);
			}
		} catch (e) {
			this.rabbitReconnect(pin);
		}
	};

	showInfowindow = (options) => {
		let position = new window.google.maps.LatLng(options.lat, options.lng);
		let device = {
			id: this.original.id,
			event: options.event,
			description: this.original.description,
			trackingHistoryId: options.trackingHistoryId,
			utcOffsetMinutes: this.config.utcOffsetMinutes,
			tanksConfig: this.config.tanksConfig,
			thermometersConfig: this.config.thermometerConfig,
			modelProfileId: this.original.modelProfileId,
		};

		const payload = {
			device,
			title: 'position',
			multipleMap: this.config.multipleMap,
			routeMonitor: this.config.routeMonitor,
			isDevice: options.isDevice,
			shared: this.original.shared,
		};
		this.config.OpenInfoWindow({ position, payload });
	};

	showDisplacementAlert = ({ description, latitude, longitude, id }) => {
		if (this.config && this.config.eventEmmiter) {
			this.config.eventEmmiter.emitEvent('temporalGeofence', [description, latitude, longitude, id]);
		}
	};

	showEventInfowindow = (options) => {
		let position = new window.google.maps.LatLng(options.latitude, options.longitude);
		let device = {
			id: this.original.id,
			description: this.original.description,
			trackingHistoryId: options.trackingHistoryId,
			event: options.event,
			utcOffsetMinutes: this.config.utcOffsetMinutes,
			tanksConfig: this.config.tanksConfig,
			thermometersConfig: this.config.thermometerConfig,
			modelProfileId: this.original.modelProfileId,
		};

		const payload = {
			device,
			title: 'position',
			multipleMap: this.config.multipleMap,
			routeMonitor: this.config.routeMonitor,
			isDevice: options.isDevice,
			shared: this.original.shared,
		};

		this.config.OpenInfoWindow({ position, payload });
	};

	showVideo = (taskId) => this.config.eventEmmiter.emitEvent('videoEvent', [this.original.id, true, taskId]);

	select = (map = this.map, date) => {
		this.showMarker(map);

		this.setConfiguration({
			selected: true,
			showLabel: false,
		});

		if (this.mapPoint != null) {
			this.config.LoadingTrace();
			if (this.config.sharedExternalMap) {
				this.loadDbTrace(true, date, getEventsByIdExternal, getTraceByIdExternal);
			} else {
				this.loadDbTrace(true, date, getEventsById, getTraceById);
				this.getDeviceInfo();
				if (this.config.roles.includes(ROLES.VIDEO_CAMERA)) {
					this.getCameraData();
				}
			}

			this.draw();
		}
	};

	selectDetailedHistory = (date) => {
		this.setConfiguration({
			selected: true,
			showLabel: false,
		});
		if (this.mapPoint != null) {
			this.config.LoadingTrace();
			let start = moment(date).startOf('days').format('YYYY-MM-DD HH:mm:ss');
			let end = moment(date).endOf('days').format('YYYY-MM-DD HH:mm:ss');
			this.detailedHistory(start, end);
		}
	};

	fullHide = () => {
		this.setConfiguration({
			visible: false,
			showLabel: false,
			selected: false,
		});

		if (this.mapPoint == null) return;
		this.draw();
	};

	draw = () => {
		let labelClass = 'markerLabel';
		if (this.config.visible) {
			let carColor = this.getCarColor(this.original);
			if (this.original.events && this.original.events.length)
				this.original.siren = validatePoliceSiren(this.original.events, this.original.siren);

			this.setMapPointOptions({
				position: {
					lat: this.original.latitude,
					lng: this.original.longitude,
				},
				icon: icon.svgToIcon({
					id: `units${this.original.icon}`,
					azimuth: this.original.azimuth,
					color: carColor.color,
					siren: this.original.siren,
				}),
			});
			labelClass += ` ${carColor.class}`;
			this.setMapPointOptions({
				visible: true,
			});
		} else {
			this.setMapPointOptions({
				visible: false,
			});
		}
		if (this.config.selected) labelClass += ' hideLabel';

		this.mapPoint.set('labelClass', labelClass);
		this.mapPoint.set('title', moment.utc(this.original.utcDate).utcOffset(this.config.utcOffsetMinutes).fromNow());
	};

	setSpeedRangePreviousConfigBySpeed = (speed) => {
		let configurations = this.config.speedRange.configuration;

		let colorConfig = configurations.find((config) => speed >= config.minSpeed && speed <= config.maxSpeed);
		if (colorConfig) {
			this.config.speedRange.previousConfig = colorConfig;
		} else {
			this.config.speedRange.previousConfig = {
				color: this.config.traceColor,
			};
		}
	};

	addPolylinesToTraceLinesBySegmet = (trace, segment) => {
		let polyLine = new window.google.maps.Polyline({
			path: segment,
			geodesic: false,
			strokeColor: this.config.speedRange.previousConfig.color,
			strokeOpacity: 0.7,
			strokeWeight: 5,
			zIndex: MAX_ZINDEX - 3,
		});
		polyLine.setMap(this.map);
		trace.traceLines.push(polyLine);
	};

	drawTrace = (trace, isForHistory = false) => {
		if (this.map && this.mapPoint != null) {
			if (trace.traceLines.length) {
				trace.traceLines.forEach((polyLine) => polyLine.setMap(null));
				trace.traceLines.length = 0;
			}
			if (
				isForHistory ||
				(this.config.showTrace && this.config.mapName !== 'routeMonitor') ||
				(this.showTraceInWeeklyMap && this.config.mapName === 'routeMonitor')
			) {
				if (
					this.config.speedRange.isActive &&
					this.config.speedRange.configuration &&
					this.config.speedRange.configuration.length
				) {
					let segment = [];
					let original = {
						speed: this.original.speed,
						position: new window.google.maps.LatLng(this.original.latitude, this.original.longitude),
					};

					[...trace.trace, original].forEach((position) => {
						let speed = Math.floor(position.speed);
						if (!this.config.speedRange.previousConfig) {
							this.setSpeedRangePreviousConfigBySpeed(speed);
							segment.push(position.position);
						} else {
							if (
								speed >= this.config.speedRange.previousConfig.minSpeed &&
								speed <= this.config.speedRange.previousConfig.maxSpeed
							) {
								segment.push(position.position);
							} else {
								segment.push(position.position);
								this.addPolylinesToTraceLinesBySegmet(trace, segment);
								this.setSpeedRangePreviousConfigBySpeed(speed);
								segment = [position.position];
							}
						}
					});

					if (segment.length > 1) {
						segment.push(this.mapPoint.getPosition());
						this.addPolylinesToTraceLinesBySegmet(trace, segment);
					}
				} else {
					trace.positions.push(this.mapPoint.getPosition());
					trace.traceLines = [
						new window.google.maps.Polyline({
							path: trace.positions,
							geodesic: false,
							strokeColor: this.config.traceColor,
							strokeOpacity: 0.7,
							strokeWeight: 5,
							zIndex: MAX_ZINDEX - 3,
						}),
					];
				}
				trace.traceLines.forEach((polyLine) => polyLine.setMap(this.map));
				trace.trace.map((point) => point.setMap(this.map));
				this.events.map((point) => point.setMap(this.map));
			} else {
				trace.traceLines.forEach((polyLine) => polyLine.setMap(null));
			}
		}
	};

	updateTraces = () => {
		if (this.dbTrace.traceLines.length) {
			this.dbTrace.traceLines.forEach((polyLine) =>
				polyLine.setOptions({
					strokeColor: this.config.traceColor,
				})
			);
		}

		if (this.rabTrace.length) {
			this.rabTrace.traceLines.forEach((polyLine) =>
				polyLine.setOptions({
					strokeColor: this.config.traceColor,
				})
			);
		}
		this.drawTrace(this.dbTrace);
		this.drawTrace(this.rabTrace);

		if (
			(this.config.showTrace && this.config.mapName !== 'routeMonitor') ||
			(this.showTraceInWeeklyMap && this.config.mapName === 'routeMonitor')
		) {
			if (this.dbTrace.trace.length > 0) {
				this.dbTrace.trace.forEach((prop) => {
					prop.setMap(this.map);
				});
			}
			if (this.rabTrace.trace.length > 0) {
				this.rabTrace.trace.forEach((prop) => {
					prop.setMap(this.map);
				});
			}
			if (this.events.length > 0) {
				this.events.forEach((prop) => {
					prop.setMap(this.map);
				});
			}
			if (this.mediaEvents.length > 0) {
				this.mediaEvents.forEach((prop) => {
					prop.setMap(this.map);
				});
			}
		} else {
			if (this.dbTrace.trace.length > 0) {
				this.dbTrace.trace.forEach((prop) => {
					prop.setMap(null);
				});
			}
			if (this.rabTrace.trace.length > 0) {
				this.rabTrace.trace.forEach((prop) => {
					prop.setMap(null);
				});
			}
			if (this.events.length > 0) {
				this.events.forEach((prop) => {
					prop.setMap(null);
				});
			}
			if (this.mediaEvents.length > 0) {
				this.mediaEvents.forEach((prop) => {
					prop.setMap(null);
				});
			}
		}
	};

	updateColorRangeTraces = () => {
		if (this.dbTrace.traceLines.length) {
			this.dbTrace.traceLines.forEach((polyLine) => polyLine.setMap(null));
			this.dbTrace.traceLines.length = 0;
		}
		if (this.rabTrace.traceLines.length) {
			this.rabTrace.traceLines.forEach((polyLine) => polyLine.setMap(null));
			this.rabTrace.traceLines.length = 0;
		}
		if (
			this.config.speedRange.isActive &&
			this.config.speedRange.configuration &&
			this.config.speedRange.configuration.length
		) {
			let original = {
				speed: this.original.speed,
				position: new window.google.maps.LatLng(this.original.latitude, this.original.longitude),
			};
			let traceToMap = [...this.dbTrace.trace, ...this.rabTrace.trace, original];
			let segment = [];

			traceToMap.forEach((position) => {
				let speed = Math.floor(position.speed);
				if (!this.config.speedRange.previousConfig) {
					this.setSpeedRangePreviousConfigBySpeed(speed);
					segment.push(position.position);
				} else {
					if (
						speed >= this.config.speedRange.previousConfig.minSpeed &&
						speed <= this.config.speedRange.previousConfig.maxSpeed
					) {
						segment.push(position.position);
					} else {
						segment.push(position.position);
						this.addPolylinesToTraceLinesBySegmet(this.dbTrace, segment);
						this.setSpeedRangePreviousConfigBySpeed(speed);
						segment = [position.position];
					}
				}
			});

			if (segment.length > 1) {
				segment.push(this.mapPoint.getPosition());
				this.addPolylinesToTraceLinesBySegmet(this.dbTrace, segment);
			}
		} else {
			let concatPositions = this.dbTrace.positions.concat(this.rabTrace.positions);
			this.dbTrace.traceLines = [
				new window.google.maps.Polyline({
					path: [...concatPositions, this.mapPoint.getPosition()],
					geodesic: false,
					strokeColor: this.config.traceColor,
					strokeOpacity: 0.7,
					strokeWeight: 5,
					zIndex: MAX_ZINDEX - 3,
				}),
			];

			this.dbTrace.traceLines.forEach((polyLine) => polyLine.setMap(this.map));
		}
	};

	updateDeviceColor = () => {
		if (this.mapPoint == null) return;

		let carColor = this.getCarColor(this.original);

		this.draw();
		this.mapPoint.set('labelClass', `markerLabel ${carColor.class}`);
	};

	getCarColor = (device) => {
		if (device.isIdle)
			return { color: this.config.idleColor, class: `car-color-${this.config.idleColor.replace('#', '')}` };
		else if (isVehicleMoving({ ...device, utcOffsetMinutes: -360 }))
			return {
				color: this.config.movementColor,
				class: `car-color-${this.config.movementColor.replace('#', '')}`,
			};
		else
			return { color: this.config.parkingColor, class: `car-color-${this.config.parkingColor.replace('#', '')}` };
	};

	hideTrace = () => {
		if (this.dbTrace.traceLines.length) this.dbTrace.traceLines.forEach((polyLine) => polyLine.setMap(null));

		if (this.dbTrace.trace.length > 0) {
			this.dbTrace.trace.forEach((prop) => {
				prop.setMap(null);
			});
		}

		if (this.events.length > 0) {
			this.events.forEach((prop) => {
				prop.setMap(null);
			});
		}

		if (this.rabTrace.traceLines.length) this.rabTrace.traceLines.forEach((polyLine) => polyLine.setMap(null));

		if (this.rabTrace.trace.length > 0) {
			this.rabTrace.trace.forEach((prop) => {
				prop.setMap(null);
			});
		}

		forEach(this.poi, (poi) => {
			poi.hide();
		});
		Object.keys(this.line).forEach((lineKey) => {
			this.line[lineKey].removeAllPlace();
		});
	};

	centerOnMe = () => {
		let bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(this.original.latitude, this.original.longitude));
		this.map.fitBounds(bounds);
		this.map.setZoom(18);
	};

	hideMarker = () => {
		this.hideTrace();

		if (this.mapPoint != null) {
			this.setMapPointOptions({
				map: null,
			});
		} else if (this.cellTower) {
			this.cellTower.hide();
		}

		this.setConfiguration({
			visible: false,
			showLabel: false,
			selected: false,
		});
	};

	showMarker = (map) => {
		this.setConfiguration({
			visible: true,
			showLabel: true,
		});
		if (this.mapPoint != null) {
			this.setMapPointOptions({
				map: map,
			});

			this.draw();
		} else if (this.cellTower) {
			this.cellTower.show();
		}
	};
	getDeviceInfo = () => {
		this.config.GetVehicleInfo(this.original.id).then((vehicleInfo) => {
			this.deviceInfo.vehicleInfo = {
				...vehicleInfo,
				description: this.original.description,
			};
		});
		this.config.GetDriverInfo(this.original.id).then((driverInfo) => {
			this.deviceInfo.driverInfo = driverInfo;
		});
		this.config.GetExtraInfo(this.original.id).then((extraInfo) => {
			this.deviceInfo.extraInfo = extraInfo;
		});
	};

	getCameraData = () => {
		CameraService.get(this.original.id).then(({ success, error }) => {
			if (!error) {
				this.config.GetCameraInfo(success.data);
			}
		});
	};

	detailedHistory = (start, end) => {
		if (this.source !== undefined) this.source();
		const originalStart = start;
		let getTrailHistory = this.config.sharedExternalMap ? getTrailHistoryExternal : getDetaileTracebyId;
		return getTrailHistory({
			id: this.original.id,
			startDate: start,
			endDate: end,
			utcOffsetMinutes: this.config.utcOffsetMinutes,
			source: new axios.CancelToken((c) => {
				this.source = c;
			}),
		}).then((response) => {
			let trails = [];
			let allTrail = {
				avgSpeed: 0,
				distance: 0,
				start: moment(),
				end: moment(),
				duration: 0,
				trace: [],
			};

			if (Object.keys(response.data).length === 0) {
				let start = moment(this.original.utcDate)
					.startOf('days')
					.utcOffset(this.config.utcOffsetMinutes, true)
					.utc()
					.format('YYYY-MM-DD');
				let end = moment(this.original.utcDate)
					.endOf('days')
					.utcOffset(this.config.utcOffsetMinutes, true)
					.utc();

				const isDateToday = moment(start).isSame(moment(originalStart));
				this.config.eventEmmiter.emitEvent('noTraceThisDate', [
					unitSystem.getDate(
						moment(this.original.utcDate)
							.startOf('days')
							.utcOffset(this.config.utcOffsetMinutes, true)
							.utc()
					),
					this.original.description,
					isDateToday,
				]);
				if (isDateToday) {
					return;
				}
				return this.detailedHistory(start, end);
			}

			if (this.config.selectedTrail === selectedMapTrail.LAST) {
				let traceKeys = Object.keys(response.data);
				let lastTrace = traceKeys.length - 1;

				this.UpdateTrace(response.data[traceKeys[lastTrace]]);
				return;
			}
			_.forEach(response.data, (value) => {
				let trailDistance;
				let avgSpeedData = value.filter((trace) => trace.speed > 0);
				let avgSpeed =
					avgSpeedData.length > 0
						? _.meanBy(
								value.filter((trace) => trace.speed > 0),
								'speed'
						  )
						: 0;
				let duration = moment
					.utc(value[value.length - 1].utcDate)
					.utcOffset(this.config.utcOffsetMinutes)
					.diff(moment.utc(value[0].utcDate).utcOffset(this.config.utcOffsetMinutes));
				let lat, lon;
				trailDistance = _.sum(
					value.map((row, index) => {
						if (index === 0) {
							lat = row.latitude;
							lon = row.longitude;
							return 0;
						}
						let distance = distanceInMeters(row.latitude, row.longitude, lat, lon);
						lat = row.latitude;
						lon = row.longitude;

						return distance;
					})
				);
				trailDistance = trailDistance / 1000;

				var segment = {
					trace: value,
					avgSpeed: avgSpeed,
					distance: trailDistance,
					start: moment.utc(value[0].utcDate).utcOffset(this.config.utcOffsetMinutes),
					end: moment.utc(value[value.length - 1].utcDate).utcOffset(this.config.utcOffsetMinutes),
					duration: duration,
					startEvent: value[0].eventHistory.length > 0,
					endEvent: value[value.length - 1].eventHistory.length > 0,
				};

				if (!(segment.avgSpeed === 0 && segment.distance < 0.1 && segment.duration === 0)) {
					trails.push(segment);
				}

				allTrail.trace = [...allTrail.trace, ...value];
			});

			this.source = undefined;
			let avgSpeedData = trails.filter((trace) => trace.avgSpeed > 0);
			if (trails.length)
				allTrail = {
					...allTrail,
					avgSpeed: avgSpeedData.length > 0 ? _.meanBy(avgSpeedData, 'avgSpeed') : 0,
					distance: _.sum(
						trails.map((trail) => {
							return trail.distance;
						})
					),
					start: trails[0].start,
					end: trails[trails.length - 1].end,
					duration: _.sum(
						trails.map((trail) => {
							return trail.duration;
						})
					),
					startEvent: trails[0].startEvent,
					endEvent: trails[trails.length - 1].endEvent,
				};

			this.UpdateTrace(allTrail.trace);
			return [allTrail, ...trails];
		});
	};

	UpdateTrace = (points) => {
		if (!points.length) return;
		if (this.source !== undefined) this.source();
		let GetEventsBetweenDates = this.config.sharedExternalMap
			? getEventsBetweenDatesExternal
			: getEventsBetweenDates;

		let tasks = [
			(callback) => {
				GetEventsBetweenDates(
					this.original.id,
					points[0].utcDate,
					points[points.length - 1].utcDate,
					this.config.userId,
					new axios.CancelToken((c) => {
						this.source = c;
					})
				)
					.then((events) => callback(null, events))
					.catch(callback);
			},
			(callback) => {
				if (
					shouldDisplayMultimediaIcons({
						isImpersonating: this.config.isImpersonating,
						shared: this.original.shared,
						roles: this.config.roles,
						impersonatorRoles: this.config.impersonatorRoles,
					})
				) {
					const startDate = moment(points[0].utcDate).add(this.config.utcOffsetMinutes, 'minutes').format();

					const endDate = moment(points[points.length - 1].utcDate)
						.add(this.config.utcOffsetMinutes, 'minutes')
						.format();

					MultimediaService.getEvents(this.original.id, startDate, endDate, this.setCancelToken())
						.then(({ success, error }) => callback(error, success))
						.catch(callback);
				} else {
					callback([]);
				}
			},
		];

		return parallel(reflectAll(tasks))
			.then((response) => {
				let [eventsResponse, mediaEventsResponse] = response;

				let events = eventsResponse.error ? { data: [] } : eventsResponse.value;
				let mediaEvents = mediaEventsResponse.error ? { data: [] } : mediaEventsResponse.value;
				if (this.dbTrace.traceLines.length) {
					this.dbTrace.traceLines.forEach((polyLine) => polyLine.setMap(null));
				}

				if (this.dbTrace.trace.length > 0) {
					this.dbTrace.trace.forEach((prop) => {
						prop.setMap(null);
					});
				}

				if (this.events.length > 0) {
					this.events.forEach((prop) => {
						prop.setMap(null);
					});
				}

				if (this.mediaEvents.length > 0) {
					this.mediaEvents.forEach((prop) => {
						prop.setMap(null);
					});
				}

				if (this.rabTrace.traceLines.length) {
					this.rabTrace.traceLines.forEach((polyLine) => polyLine.setMap(null));
				}

				if (this.rabTrace.trace.length > 0) {
					this.rabTrace.trace.forEach((prop) => {
						prop.setMap(null);
					});
				}

				this.dbTrace.trace.length = 0;
				this.rabTrace.trace.length = 0;
				this.dbTrace.positions.length = 0;
				this.rabTrace.positions.length = 0;
				this.events.length = 0;
				this.lastEvents.length = 0;
				this.dbTrace.events.length = 0;
				this.dbTrace.mediaEvents.length = 0;

				return { eventsResponse: events.data, mediaEventsResponse: mediaEvents };
			})
			.then(({ eventsResponse, mediaEventsResponse }) => {
				let lastDbReport = [...points].pop();
				let bounds = new window.google.maps.LatLngBounds();

				let events = _.groupBy(eventsResponse, 'trackingHistoryId');
				let mediaEvents = _.groupBy(mediaEventsResponse, 'trackingHistoryId');
				points.forEach((t, index) => {
					let azimuth = t.speed > 0 ? t.azimuth : this.original.azimuth;
					let newMapPoint = null;
					if (t.id in events) {
						this.addEvents({
							...t,
							trackingHistoryId: t.id,
							events: events[t.id],
						});
					}

					if (t.id in mediaEvents) {
						const multimediaVideos = mediaEvents[t.id].filter(({ type }) => type === MULTIMEDIA_TYPE.VIDEO);
						const multimediaPhotos = mediaEvents[t.id].filter(({ type }) => type === MULTIMEDIA_TYPE.PHOTO);
						if (multimediaVideos.length) {
							this.addMediaEvents({
								...t,
								trackingHistoryId: t.id,
								mediaEvents: multimediaVideos,
							});
						}
						if (multimediaPhotos.length) {
							this.addEvents({
								...t,
								trackingHistoryId: t.id,
								events: multimediaPhotos.map(({ eventId, trackingHistoryId, type }) => ({
									id: eventId,
									trackingHistoryId,
									data: null,
									isAccountEvent: 0,
									type,
								})),
							});
						}
					}

					this.original.azimuth = azimuth;
					if (index === 0) {
						newMapPoint = this.createMapPoint(
							{
								trackingHistoryId: t.id,
								utcDate: t.utcDate,
								icon: icon.svgToIcon({
									id: 'startPoint',
									azimuth: 0,
									color: '#2f9cff',
								}),
								lng: t.longitude,
								lat: t.latitude,
								batteryVoltage: t.batteryVoltage,
								signal: t.signal,
								speed: t.speed,
								rpm: t.rpm,
								photoId: t.photoId,
								thermometers: t.thermometers,
								battery: t.battery,
								isOn: t.isOn,
								isIdle: t.isIdle,
							},
							true
						);
					} else {
						newMapPoint = this.createMapPoint({
							trackingHistoryId: t.id,
							utcDate: t.utcDate,
							icon: icon.getIcon(t.speed > 0 ? 'arrow' : 'circle', azimuth, '#2f9cff'),
							lng: t.longitude,
							lat: t.latitude,
							batteryVoltage: t.batteryVoltage,
							signal: t.signal,
							speed: t.speed,
							rpm: t.rpm,
							photoId: t.photoId,
							thermometers: t.thermometers,
							battery: t.battery,
							isOn: t.isOn,
							isIdle: t.isIdle,
						});
					}

					this.dbTrace.trace.push(newMapPoint);
					this.dbTrace.positions.push(newMapPoint.getPosition());
					bounds.extend(newMapPoint.getPosition());
				});
				this.original = {
					...this.original,
					trackingHistoryId: lastDbReport.id,
					utcDate: lastDbReport.utcDate,
					longitude: lastDbReport.longitude,
					latitude: lastDbReport.latitude,
					signal: lastDbReport.signal,
					speed: lastDbReport.speed,
					photoId: lastDbReport.photoId,
					events: lastDbReport.id in events ? events[lastDbReport.id] : [],
					azimuth: lastDbReport.azimuth,
					battery: lastDbReport.battery,
					isOn: lastDbReport.isOn,
					isIdle: lastDbReport.isIdle,
				};
				this.draw();
				this.drawTrace(this.dbTrace, true);
				if (
					this.config.selected !== 0 &&
					this.config.centerOnSelect &&
					this.map !== undefined &&
					this.map != null
				) {
					this.map.fitBounds(bounds);
				}
			});
	};
}

export default devices;

import axios from 'axios';
import { ExportReportArgs, MileagePerStateArgs, ReportArgs, ReportService } from '@/features/reports/interfaces';
import { MileagePerStateData } from '@/features/reports/mileagePerState/mileagePerState.dto';
import { Result } from '@/OptimusRoutes/interfaces';

const getData = async (args: ReportArgs): Promise<Result<MileagePerStateData[]>> => {
	const { devices, dateRange, cancelToken } = args as MileagePerStateArgs;
	const params = {
		deviceIds: devices,
		'start-date': dateRange[0],
		'end-date': dateRange[1],
	};
	const { data } = await axios.get<Result<MileagePerStateData[]>>('/v2/reports/mileage-per-state', {
		params,
		cancelToken,
	});
	return data;
};

const exportData = async ({ data }: ExportReportArgs<MileagePerStateData>): Promise<void> => {
	const { data: file, headers } = await axios.post<BlobPart>(
		`/v2/reports/mileage-per-state/export`,
		{ data },
		{
			responseType: 'blob',
		}
	);
	const url = window.URL.createObjectURL(new Blob([file]));
	const contentDisposition = headers['content-disposition'];
	const link = document.createElement('a');
	link.href = url;
	link.download = contentDisposition.split('filename=')[1].split(';')[0].replace(/['"]/g, '');
	document.body.appendChild(link);
	link.click();
};

const MileagePerStateService: ReportService<MileagePerStateData> = {
	getData,
	export: exportData,
};
export default MileagePerStateService;

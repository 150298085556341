import React from 'react';
import { injectIntl } from 'react-intl';
import ReactTable from 'react-table';
import { messages } from '@optimusgps/optimus-intl';
import { Col, Pagination, Row } from 'antd';
import PropTypes from 'prop-types';

class ReportTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			page: 1,
			pageSize: 10,
		};
	}

	onPageChange = (page) => this.setState({ page: page });

	onShowSizeChange = (current, pageSize) => {
		this.setState({
			page: 1,
			pageSize: pageSize,
		});
	};

	render() {
		let { intl, data, columns, pivotBy, loading, className, collapseOnDataChange } = this.props;
		let { pageSize, page } = this.state;

		return (
			<Row type="flex" justify="space-around" className="table-container">
				<Col span={24}>
					<ReactTable
						data={data}
						columns={columns}
						pivotBy={pivotBy}
						pageSize={pageSize}
						minRows={10}
						page={page - 1}
						loading={loading}
						className={className}
						collapseOnDataChange={collapseOnDataChange ? true : collapseOnDataChange}
						collapseOnSortingChange={false}
						collapseOnPageChang={false}
						showPaginationBottom={false}
						sortable={this.props.sortable}
						multiSort={true}
						loadingText={`${intl.formatMessage(messages.loading)}`}
						noDataText={`${intl.formatMessage(messages.noData)}`}
						defaultSorted={this.props.defaultSorted}
					/>
				</Col>
				<Col span={24} className="table-paging">
					<Pagination
						showSizeChanger
						onShowSizeChange={this.onShowSizeChange}
						onChange={this.onPageChange}
						current={page}
						pageSize={pageSize}
						total={this.props.totalCount}
						pageSizeOptions={['10', '15', '20', '30', '40']}
					/>
				</Col>
			</Row>
		);
	}
}

ReportTable.defaultProps = {
	sortable: true,
};

ReportTable.propTypes = {
	intl: PropTypes.object.isRequired,
	data: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	pivotBy: PropTypes.array,
	loading: PropTypes.bool.isRequired,
	className: PropTypes.string.isRequired,
	collapseOnDataChange: PropTypes.bool,
	totalCount: PropTypes.number.isRequired,
	sortable: PropTypes.bool.isRequired,
	defaultSorted: PropTypes.object,
};

export default injectIntl(ReportTable);

import axios from 'axios';
import _ from 'lodash';
import { MAP_TYPE, selectedMapTrail } from '../../constants/global';
import { getMapInstance } from '../../utilities/mapUtilities';
import { INIT_MAP_CONFIGURATION, SET_UNIT_SYSTEM } from '../auth/actionTypes';
import { UpdateCameraInfo } from '../camera/actions';
import { UpdateCommandsInfo } from '../commands/actions';
import { GetDriverInfo, GetExtraInfo, GetVehicleInfo } from '../deviceInformation/actions';
import { LoadingTrace, UpdateDeviceEvent } from '../temporal/actions';
import { SELECTED_TRAIL, SHARED_DEVICES_EXTERNAL } from './actionTypes';

export const SharedMapMasterOfMarkers =
	({ map, mapName, selectedDevices, eventEmmiter, openInfoWindow, openPoiInfoWindow }) =>
	(dispatch, getState) => {
		let state = getState();
		let messages = state.language.messages;
		let locale = state.language.code;
		let otherUnits = state.map.otherUnits;
		let centerOnSelect = state.configuration.centerOnSelect;
		let centerOnUpdate = state.configuration.centerOnUpdate;
		let traceColor = state.configuration.traceColor;
		let showTrace = state.configuration.showTrace;
		let movementColor = state.configuration.movementColor;
		let parkingColor = state.configuration.parkingColor;
		let idleColor = state.configuration.idleColor;
		let selectedTrail = state.sharedDevicesExternal.selectedTrail;
		let userId = state.auth.user.id;
		let traceBySpeedIsActive = state.configuration.traceBySpeedIsActive;
		let speedRangesConfiguration = state.configuration.speedRangesConfiguration;
		let clusterDevices = state.configuration.clusterDevices;
		let isImpersonating = state.impersonate.isImpersonating;

		let options = {
			messages,
			showOthers: otherUnits,
			dispatch: dispatch,
			locale: locale,
			mapName: mapName,
			centerOnSelect: centerOnSelect,
			centerOnUpdate: centerOnUpdate,
			showTrace: selectedTrail !== -1 && showTrace,
			traceColor: traceColor,
			movementColor: movementColor,
			parkingColor: parkingColor,
			idleColor: idleColor,
			sharedMap: true,
			selectfirst: selectedDevices.length === 1,
			selectedTrail: selectedTrail === 1 ? selectedMapTrail.LAST : selectedMapTrail.All,
			sharedExternalMap: true,
			forceReload: true,
			eventEmmiter: eventEmmiter,
			userId: userId,
			clusterDevices,
			roles: [],
			speedRange: {
				isActive: traceBySpeedIsActive,
				configuration: speedRangesConfiguration,
			},
			isImpersonating,
			UpdateDeviceEvent: () => dispatch(UpdateDeviceEvent()),
			LoadingTrace: () => dispatch(LoadingTrace()),
			GetVehicleInfo: (id) => dispatch(GetVehicleInfo(id)),
			GetDriverInfo: (id) => dispatch(GetDriverInfo(id)),
			GetExtraInfo: (id) => dispatch(GetExtraInfo(id)),
			OpenInfoWindow: (params) => openInfoWindow(params),
			OpenPoiInfoWindow: (params) => openPoiInfoWindow(params),
			GetCameraInfo: (camera) => dispatch(UpdateCameraInfo(camera)),
			GetCommandsInfo: (commands) => dispatch(UpdateCommandsInfo(commands)),
		};
		getMapInstance(map, _.cloneDeepWith(selectedDevices), options);
	};

export const SaveSharedExternalDevices = (values) => (dispatch, getState) => {
	let state = getState();
	let accountId = state.auth.user.id;

	let linkInfo = { ...values, accountId };
	return axios.post(`/SharedExternals/SaveSharedExternalDevices`, { linkInfo }).then((response) => {
		return response.data;
	});
};

export const DeleteLinks = (id) => () => {
	return axios.delete(`/SharedExternals/${id}`);
};

export const GetSharedLinks = () => (dispatch, getState) => {
	let state = getState();
	let accountId = state.auth.user.id;
	return axios.get(`/SharedExternals/GetSharedLinks?accountId=${accountId}`).then((response) => {
		return response.data;
	});
};
export const ShareLink = (values) => (dispatch, getState) => {
	let state = getState();
	let clientId = state.auth.user.clientId;
	let languageId = state.auth.user.languageId;

	let data = { ...values, clientId, languageId };

	return axios.post(`/SharedExternals/ShareLinkExternal`, { data }).then((response) => {
		return response.data;
	});
};

export const GetSharedDevices = (token) => (dispatch) => {
	let data = {
		expire: new Date(Date.now()),
		token: token ? token : 'noToken',
	};

	return axios.post(`/SharedExternals/GetDevices`, { data }).then((response) => {
		const { unitSystemConfig, mapConfigurations } = response.data;
		const maptypes = Object.keys(MAP_TYPE);
		const config = {
			...mapConfigurations,
			mapType: MAP_TYPE[maptypes[mapConfigurations?.mapType || 0]],
		};

		dispatch({ type: INIT_MAP_CONFIGURATION, config });
		dispatch({ type: SET_UNIT_SYSTEM, unitSystemConfig });
		dispatch({
			type: SHARED_DEVICES_EXTERNAL,
			data: response.data.devices,
		});
		dispatch({
			type: SELECTED_TRAIL,
			selectedTrail: response.data.selectedTrail,
		});
		return response.data;
	});
};

export const getTraceByIdExternal = (id, date, utcOffsetMinutes, source) => {
	let options = { start: date, utcOffsetMinutes };
	return axios.post(`/SharedExternals/${id}/trackExternal/`, options, source);
};

export const getEventsByIdExternal = (id, date, utcOffsetMinutes, accountId, source) => {
	let options = { start: date, utcOffsetMinutes, accountId };
	return axios.post(`/SharedExternals/${id}/eventsExternal/`, options, source);
};

export const getTrailHistoryExternal = ({ id, startDate, utcOffsetMinutes, source }) => {
	let options = { start: startDate, utcOffsetMinutes };
	return axios.post(`/SharedExternals/${id}/trailExternal/`, options, source);
};

export const getEventsBetweenDatesExternal = (id, startDate, endDate, accountId, source) => {
	let options = { startDate, endDate, accountId };
	return axios.post(`/SharedExternals/${id}/trailExternal/events`, options, source);
};

import React, { useRef, useState } from 'react';
import { TreeSelect } from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';

const GroupComponent = (props) => {
	const [data, setData] = useState([]);
	const [areAllSelected, setAreAllSelected] = useState(false);
	const groups = useRef([]);
	const children = useRef([]);

	useDeepCompareEffect(() => {
		let treeData = [];
		treeData = createData(props);
		setData(treeData);
	}, [props.tree, props.items]);

	const createData = (props) => {
		let treeNodes = createTreeNodes(props);
		let allItems = props.items.map((node) => node.id);
		if (props.showNoGroupNode) {
			//create group node with the childs that have no parent
			let inGroups = [];
			for (let key in treeNodes) {
				inGroups = _.union(inGroups, treeNodes[key]);
			}
			treeNodes[0] = _.difference(allItems, inGroups);
		}
		let treeData = [];
		let children;
		let parent;
		let parentKey;
		let node;
		for (let key in treeNodes) {
			parent = GetParent(Number(key));
			if (parent) {
				parentKey = `parent_${parent.id}`;
				let title =
					typeof props.groupTitle === 'function' ? props.groupTitle(parent) : parent[props.groupTitle];
				node = {
					title: title,
					value: parentKey,
					key: parentKey,
					children: [],
				};
				var childNodes = treeNodes[key];
				children = GetNodeChildrens(childNodes, props);
				node.children = children;
				node.disabled = !children.length;
				treeData.push(node);
			}
		}

		if (!props.showNoGroupNode) {
			//if the 'no group' node is not required, add the 'children with no parent' to the root of the tree
			let inGroups = [];
			for (let key in treeNodes) {
				inGroups = _.union(inGroups, treeNodes[key]);
			}
			var childsWithNoGroup = _.difference(allItems, inGroups);
			children = GetNodeChildrens(childsWithNoGroup, props);
			treeData = treeData.concat(children);
		}

		if (props.showAllNode) {
			// if the 'show all' node is required, add the whole tree under this node
			parent = GetParent(-1);
			parentKey = `parent`;
			let title = typeof props.groupTitle === 'function' ? props.groupTitle(parent) : parent[props.groupTitle];
			node = {
				title: title,
				value: parentKey,
				key: parentKey,
				children: treeData,
			};
			return [node];
		}
		return treeData;
	};

	// validates if tree prop is an array
	const createTreeNodes = (props) => {
		if (props.tree && Array.isArray(props.tree)) return props.tree;
	};

	const GetNodeChildrens = (childNodes, props) => {
		return _.uniq(childNodes)
			.map((id) => {
				var child = GetChild(Number(id));
				if (child) {
					var childKey = `${child.id}`;
					let title = typeof props.itemTitle === 'function' ? props.itemTitle(child) : child[props.itemTitle];
					let node = {
						title: title,
						value: childKey,
						key: child.id,
					};
					if (props.disableNode && typeof props.disableNode === 'function')
						node.disabled = props.disableNode(child);
					return node;
				}
				return null;
			})
			.filter((child) => child);
	};

	const onChange = (keys) => {
		var treeData = data;
		if (props.showAllNode) {
			// if 'show all' node is required iterate over its children
			treeData = data.find((o) => o.key === 'parent').children;
		}

		var selectedKeys = keys;

		if (keys[0] === 'parent') {
			// if the selected keys refere to the 'show all' node
			if (areAllSelected) {
				// if the whole tree was alredy selected, deselect all
				selectedKeys = [];
			} else {
				// if the whole tree was not selected, select all
				selectedKeys = treeData.map((node) => node.key);
			}
		}

		var selected = _.uniq(
			_.flatMap(selectedKeys, (node) => {
				// get the selected keys

				if (typeof node !== 'string') return node;

				if (node.indexOf('parent') > -1)
					return treeData
						.find((o) => o.key === node)
						.children.filter((o) => !o.disabled)
						.map((o) => o.key);

				return +_.last(node.split('_'));
			})
		);

		let allItems = props.items.map((node) => node.id);
		let notSelected = _.difference(allItems, selected);

		if (!notSelected.length && !areAllSelected) {
			// define in state is the whole tree is selected
			setAreAllSelected(true);
		} else {
			setAreAllSelected(false);
		}
		if (props.onChange) {
			props.onChange(selected);
		}
	};

	const GetParent = (id) => {
		if (id in groups.current) return groups.current[id];
		var parent;
		if (id === -1) {
			parent = {};
			parent.id = -1;
			parent[props.groupTitle] = props.allLabel;
		} else if (id === 0) {
			parent = {};
			parent.id = 0;
			parent[props.groupTitle] = props.noGroupLabel;
		} else {
			parent = props.groups.find((parent) => parent.id === id);
		}
		groups.current[id] = parent;
		return groups.current[id];
	};

	const GetChild = (id) => {
		if (id in children.current && children.current[id]) return children.current[id];
		var child = props.items.find((child) => child.id === id);
		children.current[id] = child;
		return children.current[id];
	};

	const tProps = {
		treeData: data,
		onChange: onChange,
		value: props.selected,
		treeCheckable: true,
		showCheckedStrategy: props.strategy,
		dropdownStyle: { maxHeight: 400, overflow: 'auto' },
		placeholder: 'Please select',
		style: {
			width: props.width,
		},
		filterTreeNode: (input, option) => option.props.title.toLowerCase().includes(input.trim().toLowerCase()),
		allowClear: true,
	};

	return (
		<TreeSelect
			{...tProps}
			maxTagTextLength={16}
			maxTagCount={props.maxTagCount}
			data-testid="groupComponent_treeSelect"
			allowClear
			disabled={props.isDisabled}
		/>
	);
};

GroupComponent.defaultProps = {
	selected: [],
	groups: [],
	tree: [],
	groupTitle: 'name',
	itemTitle: 'description',
	strategy: TreeSelect.SHOW_PARENT,
	showAllNode: false,
	allLabel: 'All',
	showNoGroupNode: false,
	noGroupLabel: 'No group',
	width: '100%',
	maxTagCount: 2,
	isDisabled: false,
};

GroupComponent.propTypes = {
	groups: PropTypes.array.isRequired,
	items: PropTypes.array.isRequired,

	selected: PropTypes.array,
	onChange: PropTypes.func,
	disableNode: PropTypes.func,

	groupTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

	itemTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

	//if this prop is present, it will overwrite the props above
	tree: PropTypes.array,

	strategy: PropTypes.oneOf([TreeSelect.SHOW_PARENT, TreeSelect.SHOW_CHILD, TreeSelect.SHOW_ALL]),

	showAllNode: PropTypes.bool,
	allLabel: PropTypes.string,
	showNoGroupNode: PropTypes.bool,
	noGroupLabel: PropTypes.string,

	width: PropTypes.string,
	maxTagCount: PropTypes.number,
	isDisabled: PropTypes.bool,
};

export default GroupComponent;

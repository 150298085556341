import axios from 'axios';
import DeviceInformationService from '@/features/deviceInformation/deviceInformation.service';
import { FetchGroups } from '@/features/groups/actions';
import GroupsService from '@/features/groups/group.service';
import { amazonImageUrl } from '../../utilities/util';
import { GetIcons, GetTimeZones } from '../devices/actions';
import { FETCH_DRIVER_INFO, FETCH_EXTRA_VEHICLE_INFO, FETCH_VEHICLE_INFO } from '../devices/actionTypes';
import { GetDevices } from '../map/actions';
import { FETCH_MAP_DEVICES } from '../map/actionTypes';

export const UpdateVehicleInfo = (newData) => (dispatch, getState) => {
	let state = getState();
	let mapDevices = state.map.devices;
	let vehicleInfoResp = state.device.vehicleInfo;

	let vehicleInfo = {
		numberPlate: newData.numberPlate,
		color: newData.color,
		brand: newData.brand,
		model: newData.model,
		capacity: +newData.capacity,
		VIN: newData.VIN,
		odometer: +newData.odometer,
	};
	let newDevice = {
		description: newData.description,
		iconId: newData.iconId,
		timeZoneId: newData.timeZoneId,
		deviceStatusId: 1,
	};

	let newDeviceInfo = {
		...newDevice,
		utcOffsetMinutes: newData.utcOffsetMinutes,
		icon: +newData.iconId,
	};

	let filteredDevices = mapDevices.map((device) =>
		device.id === newData.deviceId ? { ...device, ...newDeviceInfo } : device
	);
	return DeviceInformationService.update(newData.deviceId, vehicleInfo).then(({ success, error }) => {
		if (error) {
			throw error;
		}
		axios.patch(`/v2/devices/${newData.deviceId}`, newDevice).then(() => {
			window.main.devices[newData.deviceId].updateVehicleInfo(newDeviceInfo);
			dispatch({ type: FETCH_MAP_DEVICES, devices: filteredDevices });
			dispatch({
				type: FETCH_VEHICLE_INFO,
				vehicleInfo: {
					...vehicleInfoResp,
					...success,
					...newDeviceInfo,
				},
			});
		});
	});
};

export const GetDevicesMetaData = (cancelTocken) => async (dispatch) => {
	try {
		const icon = await GetIcons(dispatch, cancelTocken);
		const timeZone = await GetTimeZones(dispatch, cancelTocken);

		return [icon, timeZone];
	} catch (error) {
		Promise.reject(error);
	}
};

export const GetVehicleInfo = (deviceId) => async (dispatch) => {
	const { success, error } = await DeviceInformationService.get(deviceId);

	if (error) {
		throw error;
	}

	let imageUrl = null;

	if (success?.image) {
		imageUrl = amazonImageUrl({ folder: 'vehicle', name: `vehicle_${deviceId}` });
	}

	const vehicleInfo = {
		deviceId: deviceId,
		...success,
		imageUrl,
	};

	dispatch({
		type: FETCH_VEHICLE_INFO,
		vehicleInfo,
	});

	return vehicleInfo;
};

export const updateDriverInfo = (driverInfo) => ({ type: FETCH_DRIVER_INFO, driverInfo });

export const UpdateDriverInfo = (driverInfo) => (dispatch) => {
	return axios.post(`/DriverInfos/save`, { driverInfo }).then(() => {
		dispatch({ type: FETCH_DRIVER_INFO, driverInfo });
	});
};

export const GetDriverInfo = (deviceId) => async (dispatch) => {
	let filter = {
		where: { deviceId: deviceId },
	};
	try {
		const driverInfoResp = await axios.get(`/DriverInfos?filter=${encodeURIComponent(JSON.stringify(filter))}`);
		const driverInfo = driverInfoResp.data[0];
		if (driverInfoResp.data.length === 0 || (driverInfo && !driverInfo.image)) {
			dispatch({
				type: FETCH_DRIVER_INFO,
				driverInfo: { deviceId: deviceId, ...driverInfo },
			});
			return Promise.resolve({
				deviceId: deviceId,
				...driverInfo,
			});
		}

		const imageUrl = amazonImageUrl({ folder: 'driver', name: `driver_${driverInfo.id}` });

		dispatch({
			type: FETCH_DRIVER_INFO,
			driverInfo: {
				...driverInfo,
				imageUrl,
			},
		});
		return Promise.resolve({
			deviceId: deviceId,
			...driverInfo,
			imageUrl,
		});
	} catch (error) {
		return Promise.reject(error);
	}
};

export const GetDriversByClientId = () => async (dispatch, getState) => {
	try {
		const state = getState();
		const clientId = state.auth.client.id;
		const response = await axios.get(`/DriverInfos/client/${clientId}/drivers`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const GetDriverInfoById = async (id) => {
	try {
		const response = await axios.get(`/DriverInfos/driver/${id}/info`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const UpdateDriverDevice = (deviceId, driver, oldDriverId) => async (dispatch) => {
	try {
		await axios.put(`DriverInfos/device/${deviceId}/driver/${driver.id}`, { oldDriverId });
		if (driver.image) driver.imageUrl = amazonImageUrl({ folder: 'driver', name: `driver_${driver.id}` });
		dispatch({ type: FETCH_DRIVER_INFO, driverInfo: { ...driver, deviceId: deviceId } });
	} catch (error) {
		throw error;
	}
};

export const AddExtraInfo = (extraVehicleInfo) => (dispatch, getState) => {
	let state = getState();

	let extraInfo = state.device.extraVehicleInfo;

	return axios.post(`/ExtraDeviceInfos/save`, { extraVehicleInfo }).then((response) => {
		dispatch({
			type: FETCH_EXTRA_VEHICLE_INFO,
			extraVehicleInfo: [...extraInfo, response.data],
		});
		return response.data;
	});
};

export const UpdateExtraInfo = (extraVehicleInfo) => (dispatch) => {
	return axios.post(`/ExtraDeviceInfos/edit`, { extraVehicleInfo }).then(() => {
		dispatch({
			type: FETCH_EXTRA_VEHICLE_INFO,
			extraVehicleInfo: extraVehicleInfo,
		});
	});
};

export const DeleteExtraInfo = (id) => (dispatch, getState) => {
	let state = getState();
	let extraVehicleInfo = state.device.extraVehicleInfo;
	let filteredItems = extraVehicleInfo.filter((val) => val.id !== id);

	return axios.delete(`/ExtraDeviceInfos/${id}`).then(() => {
		dispatch({
			type: FETCH_EXTRA_VEHICLE_INFO,
			extraVehicleInfo: filteredItems,
		});
	});
};

export const GetExtraInfo = (deviceId) => async (dispatch) => {
	let filter = {
		where: { deviceId: deviceId },
	};
	try {
		const response = await axios.get(`/ExtraDeviceInfos?filter=${encodeURIComponent(JSON.stringify(filter))}`);

		dispatch({
			type: FETCH_EXTRA_VEHICLE_INFO,
			extraVehicleInfo: response.data,
		});
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const UpdateVehicleImage = (vehicleInfoResp) => {
	return {
		type: FETCH_VEHICLE_INFO,
		vehicleInfo: {
			...vehicleInfoResp,
			imageUrl: amazonImageUrl({ folder: 'vehicle', name: `vehicle_${vehicleInfoResp.deviceId}` }),
		},
	};
};

// DriverInfos/devices
export const GetVehicleFullInfo =
	(deviceIds = []) =>
	async () => {
		try {
			let params = { deviceIds };
			const response = await axios.get(`/DriverInfos/devices`, {
				params,
			});
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const initVehiculeInfo = (deviceId) => async (dispatch) => {
	try {
		const [vehicleInfo, driverInfo, extraInfo] = await Promise.all([
			dispatch(GetVehicleInfo(deviceId)),
			dispatch(GetDriverInfo(deviceId)),
			dispatch(GetExtraInfo(deviceId)),
		]);
		return { vehicleInfo, driverInfo, extraInfo };
	} catch (error) {
		return error;
	}
};

export const initDeviceInformationPage = (cancelToken) => async (dispatch) => {
	const {
		success: { data: groups },
	} = await GroupsService.get();
	await dispatch(FetchGroups(groups));
	return await dispatch(GetDevices(cancelToken));
};

export const OdometerHistory = (options) => {
	return axios.post('/OdometersHistory', options);
};

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '@optimusgps/optimus-intl';
import { Button, FormInstance, Popconfirm } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import { DeleteBatchProps } from '@/OptimusRoutes/interfaces';

export type CheckSelectedStatus = 'SOME' | 'ALL' | 'NONE';

type Props = {
	page: number;
	itemName: string;
	formName: string;
	formRef: FormInstance;
	displayedItems: number;
	total: number;
	loading: boolean;
	checkedStatus: CheckSelectedStatus;
	onCheckAll: (value: CheckSelectedStatus) => void;
	onDelete?: (params: DeleteBatchProps) => Promise<void>;
};

const TableSelectionToolbar = ({
	formRef,
	checkedStatus,
	displayedItems,
	total,
	loading,
	page,
	itemName: context,
	formName,
	onCheckAll,
	onDelete,
}: Props) => {
	const intl = useIntl();

	const [check, setCheck] = useState<CheckSelectedStatus>('NONE');
	const [modifyAll, setModifyAll] = useState<boolean>(false);

	const changeAllCheckboxes = async (newValue: boolean) => {
		const allFormItems = await formRef.getFieldsValue();
		Object.keys(allFormItems).forEach((key) => formRef.setFieldValue(key, newValue));
		setCheck(newValue ? 'ALL' : 'NONE');
	};

	const onCheckboxChange = async (value: CheckboxChangeEvent) => {
		changeAllCheckboxes(value.target.checked);
		const checked = value.target.checked ? 'ALL' : 'NONE';
		setCheck(checked);
		onCheckAll(checked);
		setModifyAll(false);
	};

	const reset = async () => {
		changeAllCheckboxes(false);
		setModifyAll(false);
		onCheckAll('NONE');
	};

	const onModifyAll = async () => {
		setModifyAll((prev) => !prev);
	};

	const getSelected = async () => {
		let ids: number[] = [];
		if (!modifyAll) {
			const formData = await formRef.getFieldsValue();
			const filteredData = _.pickBy(formData, (value) => value === true);
			ids = _.map(_.keys(filteredData), (key) => parseInt(key.replace(formName, '')));
		}

		return ids;
	};
	const SelectedMessage = () => {
		const selectionLabel = modifyAll ? 'clearSelection' : 'toolbarSelectAll';
		const allSelectedLabel = modifyAll ? (
			<FormattedMessage {...translate('toolbarAllSelected')} values={{ quantity: total, context }} />
		) : (
			<FormattedMessage {...translate('toolbarSelected')} values={{ selected: displayedItems, context }} />
		);
		return (
			check === 'ALL' && (
				<div className="message">
					<div className="feedback">{allSelectedLabel}</div>
					<Button type="text" onClick={onModifyAll}>
						<div className={modifyAll ? 'select-all-warn' : 'select-all'}>
							<FormattedMessage {...translate(selectionLabel)} values={{ total: total, context }} />
						</div>
					</Button>
				</div>
			)
		);
	};
	const onDeleteSelected = async () => {
		const ids = await getSelected();
		if (onDelete) {
			onDelete({
				ids,
				modifyAll,
			});
		}
	};

	const DeleteButton = onDelete && (
		<Popconfirm
			title={intl.formatMessage(translate('deleteConfirm'))}
			onConfirm={onDeleteSelected}
			okText={intl.formatMessage(translate('yes'))}
			cancelText={intl.formatMessage(translate('no'))}
			key="delete"
		>
			<Button
				type="text"
				icon={<span className="icon-trash icon" />}
				className="toolbar-button"
				loading={loading}
			/>
		</Popconfirm>
	);

	const Buttons = () => (check === 'SOME' || check === 'ALL') && <div className="tool-buttons">{DeleteButton}</div>;

	useEffect(() => {
		setCheck(checkedStatus);
		if (checkedStatus !== 'ALL') {
			setModifyAll(false);
		}
	}, [checkedStatus]);

	useEffect(() => {
		reset();
	}, [page, total]);

	return (
		<>
			<div className="toolbar">
				<div className="tools">
					<div className="toolbar-check">
						<Checkbox onChange={onCheckboxChange} checked={check === 'ALL' ? true : false} />
					</div>
					{Buttons()}
				</div>
				{SelectedMessage()}
			</div>
		</>
	);
};

export default TableSelectionToolbar;

const Policies = () => {
	return (
		<div style={{ padding: '80px' }}>
			<section className="primary small-12 columns">
				<main role="main">
					<header className="page-header">
						<div className="" style={{ textAlign: 'center' }}>
							<h1 className="title" data-default-text="Privacy Policy">
								Privacy Policy
							</h1>
						</div>
					</header>

					<div className="separator" aria-hidden="true"></div>

					<h1 data-mce-fragment="1">
						<strong data-mce-fragment="1"> </strong>
					</h1>
					<p data-mce-fragment="1">
						We take user privacy seriously and want you to be informed. This Privacy Policy, which is
						incorporated by reference into our
						<u data-mce-fragment="1">Terms of Use</u> at
						optimustracker.com/pages/terms-of-use-and-privacy-policy outlines the privacy practices of
						Optimus GPS Tracking (“Optimus,” “Company,” “us,” “we,” or “our”) in connection with how we
						collect, store, use, disclose, and protect your (“you” or “your”) personal data, such as when
						you interact with us through the following channels:
					</p>
					<p>
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use of our website (https://optimustracker.com/),
							including without limitation interactions related to online advertisements, emails, and
							other communication that arise from use of the website;{' '}
						</span>
						<br />
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use of our mobile application software platform (the
							“App”) accessible to users that have an active subscription and minimum system requirements,
							such as a minimum version of an operating system;{' '}
						</span>
						<br />
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use of one of our GPS-tracking related devices (“GPS”)
							and/or dashboard camera (“Dashcam”) devices (together, the “Product(s)”);{' '}
						</span>
						<br />
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use of our online GPS tracking platform (the “GPS
							Tracking Platform” or simply the “Platform”).
						</span>
					</p>
					<p>
						The Website, App, GPS, Dashcam, and Platform are collectively called the “Service(s)”. By
						accessing or using our Services, you acknowledge that you have read, understood, and agree to be
						bound by our terms, including this Privacy Policy.
					</p>
					<p data-mce-fragment="1">
						<strong data-mce-fragment="1">
							<u data-mce-fragment="1">
								Please carefully this Privacy Policy and make sure that you fully understand and accept
								it. If you do not agree to this Privacy Policy, you must immediately stop using the
								Products, Website, Services, and App
							</u>
						</strong>
						<strong data-mce-fragment="1">.</strong>
					</p>
					<p data-mce-fragment="1">
						<strong data-mce-fragment="1">Table of Contents:</strong>
					</p>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">Who are we?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">What does this Privacy Policy cover?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">How do we collect and use information?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">When do we disclose information? </u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">How do we use cookies and similar technologies?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">Do we sell information?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">How do we protect and transfer information?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">How long do we retain information?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">What are your privacy rights?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">Do we collect information about children?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">When do we collect sensitive information?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">Do we offer financial incentives?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">Do we respond to DNT signals?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">How do we update this Privacy Policy?</u>
						</span>
					</h3>
					<h3 data-mce-fragment="1">
						<span style={{ color: '#2b00ff' }}>
							·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<u data-mce-fragment="1">How can you contact us?</u>
						</span>
					</h3>
					<h2>Who are we?</h2>
					<p data-mce-fragment="1">
						Our Services include sale of GPS tracking-related devices (“Product(s)”), such as for your
						vehicles and assets. We also provide a mobile app software platform (“Platform”), to exclusively
						support the Products, accessible to users that have an active subscription and minimum system
						requirements, such as a minimum version of an operating system.
					</p>
					<h2 data-mce-fragment="1">What does this Privacy Policy cover?</h2>
					<p data-mce-fragment="1">
						This Privacy Policy outlines how we collect, use, and disclose personal non-aggregated
						information of users. Please also see our separate
						<u data-mce-fragment="1">Terms of Use</u> at
						optimustracker.com/pages/terms-of-use-and-privacy-policy, which incorporate by reference this
						Privacy Policy and which include definitions applicable to this Privacy Policy.
					</p>
					<p data-mce-fragment="1">
						Unless otherwise expressly stated, this Privacy Policy does not apply to any third-party
						practices, websites, or services, whether or not used by our Services. Each third party will
						have its own privacy practices and policies, which you should review before using.
					</p>
					<h2 data-mce-fragment="1">How do we collect and use information?</h2>
					<p data-mce-fragment="1">
						Generally, we collect information you provide when you use our Services, place an order, or
						provide information to us, including your name and contact information, transaction data, and
						account information. We also collect tracking and related information when you subscribe to and
						use our Platform, which provides subscription GPS tracking features. Specifically, the personal
						information we collect through our website, affiliates, or other service providers may be
						acquired through the following means:
					</p>
					<ul data-mce-fragment="1">
						<li data-mce-fragment="1">
							Information you provide when you submit an information or other webforms on our Website.
							This encompasses data you furnish when creating an online account, subscribing to our
							Services, signing up to receive communications from us, seeking information from us,
							engaging with customer support, reporting issues with our Website or Service, or engaging in
							other forms of communication with us.
						</li>
						<li data-mce-fragment="1">
							Records and copies of your correspondence, including email addresses, if you reach out to
							us.
						</li>
						<li data-mce-fragment="1">
							Your survey responses, which we or our service providers might request for research
							purposes.
						</li>
						<li data-mce-fragment="1">Your search queries conducted on our Website.</li>
						<li data-mce-fragment="1">Interactions with customer service/support.</li>
						<li data-mce-fragment="1">
							Information from third-party websites and mobile applications (e.g., websites sharing
							information with us or advertising partners about online activities).
						</li>
						<li data-mce-fragment="1">
							Data from suppliers (e.g., companies providing consumer demographics and other data).
						</li>
						<li data-mce-fragment="1">
							Data acquired from mobile applications (parent, subsidiary, and affiliate brands).
						</li>
						<li data-mce-fragment="1">Joint marketing partners.</li>
						<li data-mce-fragment="1">Online advertising companies.</li>
						<li data-mce-fragment="1">Social media companies.</li>
						<li data-mce-fragment="1">Other service providers.</li>
					</ul>
					<p data-mce-fragment="1">&nbsp;</p>
					<p data-mce-fragment="1">
						Like many companies, we may also collect internet and device information from cookies and other
						technologies when you use our Services, including our Website and App.
					</p>
					<p data-mce-fragment="1">
						We collect this information for our business purposes, namely, to provide and improve our
						Services and allow us to give support for sales and subscriptions, which may include promotions
						or other features. Part of this includes compliance with applicable laws, such as responding to
						legal processes, enforcing our rights, and protecting others. We may also use information for
						short-term, transient uses for example, interacting with you and to personalize your experience
						and communications. We use information for security purposes and to protect against harmful
						activity. Further, we may use information for debugging for functionality, auditing
						interactions, and internal research. Moreover, we may use information as otherwise instructed by
						you and with your consent. In addition, we collect information for commercial purposes, meaning
						to market our goods and services. For example, we may ask for reviews or collect internet and
						device data to better target advertisements to create a more personally relevant experience for
						our users.
					</p>
					<p data-mce-fragment="1">
						We use information for the same reasons that we collect it. For example, we use information for
						business purposes, such as providing our Services and fulfilling your purchases, as well as
						commercial purposes, such as advertising offers to you.
					</p>
					<p data-mce-fragment="1">
						Specifically, we collect the following information for the referenced purposes:
					</p>
					<table data-mce-fragment="1" width="630">
						<tbody data-mce-fragment="1">
							<tr data-mce-fragment="1">
								<td data-mce-fragment="1" width="107">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Category</strong>
									</p>
								</td>
								<td data-mce-fragment="1" width="157">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Examples of Data Collected</strong>
									</p>
								</td>
								<td data-mce-fragment="1" width="366">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											Collection Point; Purposes for Collection and Use
										</strong>
									</p>
								</td>
							</tr>
							<tr data-mce-fragment="1">
								<td data-mce-fragment="1" width="107">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">Background Data</em>
										</strong>
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">&nbsp;</em>
										</strong>
									</p>
								</td>
								<td data-mce-fragment="1" width="157">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Contact Information</strong>, and identifiers,
										such as name, address, and email address.
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Account Information</strong>, such as account name
										and number.
									</p>
									<p data-mce-fragment="1">&nbsp;</p>
									<p data-mce-fragment="1">&nbsp;</p>
								</td>
								<td data-mce-fragment="1" width="366">
									<p data-mce-fragment="1">
										We collect this information when you provide it, such as when you place an order
										on our website or create an account.
									</p>
									<p data-mce-fragment="1">
										We collect and use data for all business purposes, such as providing and
										improving our Services, responding to your communications, and for security and
										auditing purposes.
									</p>
									<p data-mce-fragment="1">
										We may also collect and use your contact information to market our offerings to
										you, such as through emails.
									</p>
								</td>
							</tr>
							<tr data-mce-fragment="1">
								<td data-mce-fragment="1" width="107">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">Commercial Data</em>
										</strong>
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">&nbsp;</em>
										</strong>
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">&nbsp;</em>
										</strong>
									</p>
								</td>
								<td data-mce-fragment="1" width="157">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Transactions</strong>, such as products or
										services purchased or considered.
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Financial Data</strong>, such as name, billing
										address, and card number and expiration date.
									</p>
								</td>
								<td data-mce-fragment="1" width="366">
									<p data-mce-fragment="1">
										We collect this information when you provide it, such as when you place an order
										on our website. Purchases are processed through our service provider, Stripe,
										see
										<u data-mce-fragment="1">Stripe’s Privacy Policy</u> at
										https://stripe.com/privacy.
									</p>
									<p data-mce-fragment="1">
										We collect and use data for all business purposes, such as providing and
										improving our Services, responding to your communications, and for security and
										auditing purposes.
									</p>
								</td>
							</tr>
							<tr data-mce-fragment="1">
								<td data-mce-fragment="1" width="107">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">Platform Data </em>
										</strong>
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">&nbsp;</em>
										</strong>
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">&nbsp;</em>
										</strong>
									</p>
								</td>
								<td data-mce-fragment="1" width="157">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">GPS Tracking Data and Geolocation</strong>, such
										as real time or historic tracking of Products via our Platform.
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Dashcam Audio and Video Footage</strong>,
										including the audio and video footage that is uploaded from the Products,
										streamed live online, or stored locally in the Products.
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Platform Usage</strong>, such as your use of the
										subscription Services and communications.
									</p>
									<p data-mce-fragment="1">&nbsp;</p>
								</td>
								<td data-mce-fragment="1" width="366">
									<p data-mce-fragment="1">
										We collect this information when you register for and use the Services, namely
										our subscription Platform for tracking your Products.
									</p>
									<p data-mce-fragment="1">
										We collect and use data for all business purposes, such as providing and
										improving our Services, e.g., to provide you with support and detect any
										problems with your Product, respond to you, and for security and auditing
										purposes.
									</p>
									<p data-mce-fragment="1">
										Video Information. We collect data on when and where the video is created, as
										well as the text for video titles. We may also view Video Footage in response to
										written and verbal technical support requests from customers for purposes of
										responding to your requests. We use reasonable precautions to anonymize this
										data. We may elect, in our sole discretion, to view video when we have detected
										a crash event, for the purposes of improving the accuracy of our alerts, and to
										facilitate the effectuation of our services. We do not sell or share Audio and
										Video Footage.
									</p>
								</td>
							</tr>
							<tr data-mce-fragment="1">
								<td data-mce-fragment="1" width="107">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">Device Data</em>
										</strong>
									</p>
								</td>
								<td data-mce-fragment="1" width="157">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Internet and Network Data</strong>, such as
										browsing history, search history, and information regarding a consumer’s
										interaction with a website or advertisement.
									</p>
								</td>
								<td data-mce-fragment="1" width="366">
									<p data-mce-fragment="1">
										We collect this data through cookies and other automated technologies. For
										example, like many companies, we may automatically gather information about your
										device, such as your IP address, browser type, and operating system. For more
										information on cookies, see below.
									</p>
									<p data-mce-fragment="1">
										We collect and use data for all business purposes, such as providing and
										improving our Services and for security and auditing purposes.
									</p>
									<p data-mce-fragment="1">
										We may use device data collected when you use our website to market offerings of
										interest to you.&nbsp;
									</p>
								</td>
							</tr>
							<tr data-mce-fragment="1">
								<td data-mce-fragment="1" width="107">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">Other Personalized Data</em>
										</strong>
									</p>
								</td>
								<td data-mce-fragment="1" width="157">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Other Information</strong>, such as reviews and
										communications.
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Employment</strong>, such as professional history.
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Education</strong>, such as educational history.
									</p>
								</td>
								<td data-mce-fragment="1" width="366">
									<p data-mce-fragment="1">
										We may collect this information if you provide it, such as if you communicate
										with us, respond to a survey or promotion, or apply for a job.
									</p>
									<p data-mce-fragment="1">
										We collect and use data for business purposes, such as providing and improving
										our Services and for security and auditing purposes.
									</p>
									<p data-mce-fragment="1">
										If you provide employment or education history for a job application, we review
										it for that purpose.
									</p>
								</td>
							</tr>
							<tr data-mce-fragment="1">
								<td data-mce-fragment="1" width="107">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">
											<em data-mce-fragment="1">Other Non-Personalized Data</em>
										</strong>
									</p>
								</td>
								<td data-mce-fragment="1" width="157">
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Public information</strong>
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Aggregate information</strong>
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">De-identified data </strong>
									</p>
									<p data-mce-fragment="1">
										<strong data-mce-fragment="1">Pseudonymized data</strong>
									</p>
									<p data-mce-fragment="1">&nbsp;</p>
								</td>
								<td data-mce-fragment="1" width="366">
									<p data-mce-fragment="1">
										We may collect this information at any time, from public, third party, and other
										sources.
									</p>
									<p data-mce-fragment="1">
										For example, we may share a public review that you posted about us or use
										aggregate data for our analytics.
									</p>
									<p data-mce-fragment="1">
										We may collect and use this data for any business or commercial purposes. The
										information will either be public data or not identify individual users.&nbsp;
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<h2 data-mce-fragment="1">When do we disclose information?</h2>
					<p data-mce-fragment="1">
						For your Platform and App data (including your Dashcam Audio and Video Footage and your GPS
						Tracking Data and Geolocation), we only operate as a “service provider,” including under the
						California Consumer Privacy Act (CCPA) and similar privacy laws. This means we may process
						personal information on your behalf for your business purposes, and we do not sell, share,
						retain, or use that personal information for purposes other than providing our Products and
						Services as outlined to you, or as otherwise permitted by applicable laws. If we receive
						consumer privacy requests that relate to a business customer, we will forward those requests to
						the appropriate business if and as authorized by applicable law. If you have any questions or
						requests to audit our data processing, please contact us.
					</p>
					<p data-mce-fragment="1">&nbsp;</p>
					<p data-mce-fragment="1">
						We disclose information as follows and for the outlined purposes, which include business
						purposes to provide and improve the Services and comply with legal and contractual obligations,
						as well as commercial purposes, if indicated, such as to market our business.
					</p>
					<ul data-mce-fragment="1">
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Our Respective Customers.</em>
							</strong>
							If you are a customer, your personal and tracking information will be available through your
							Platform account to provide the Services.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Service Providers</em>
							</strong>
							<strong data-mce-fragment="1">. </strong>We share information as necessary with our service
							providers and vendors to provide and improve the Services, including to fulfill your product
							and service orders. As explained above, service providers only use your information for a
							contracted-business purpose; and such disclosure is for our business purposes.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Company Parties; Merger or Sale</em>
							</strong>
							<strong data-mce-fragment="1">. </strong>We may share any information with affiliated
							companies that are related to us under a common ownership, if applicable, where they comply
							with this Privacy Policy. Such disclosure is for our business purposes, including to provide
							and improve our Services. Further, we may share any information as part of a sale, merger,
							acquisition, or other change in control or entity status, either in whole or in part. We
							reserve the right to transfer or assign your information as part of any such transaction or
							investigation.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Cookies and Technologies. </em>
							</strong>
							We may share information collected from cookies and technologies for our business purposes,
							in particular to provide you with the Services, as well as commercial purposes, such as to
							market to consumers that may be interested in our Services. For more information, see
							further below.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Legal Process and Protection</em>
							</strong>
							<strong data-mce-fragment="1">.</strong> For more information about legal process and
							related requests, see further below. For example, we may disclose information as necessary
							to comply with our legal obligations, such as to respond to court orders, subpoenas,
							warrants, and other legal processes; and we may disclose information when we believe it is
							necessary to investigate, prevent, or respond to illegal, fraudulent, or injurious actions
							or security incidents that may cause harm to us, the Services, or others, including in
							emergency situations. We may disclose information where necessary to investigate or enforce
							a violation of our Terms or legal rights.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Law Enforcement and Legal Requests.</em>
							</strong>
							All law enforcement and legal requests we receive must adhere to the due process of law and
							be subject to judicial oversight. In accordance with US law, Optimus may disclose
							information without delay to law enforcement if the request involves imminent danger of
							death or serious injury to any person. Optimus will evaluate emergency disclosure and other
							requests from law enforcement on a case-by-case basis. Law enforcement agency requests and
							other legal requests can be made by emailing legal@optimustracker.com.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1"> </em>
							</strong>
							We may disclose information as requested or consented to by you. Such disclosure may be for
							any business or commercial purpose as described to you.
						</li>
					</ul>
					<p data-mce-fragment="1">&nbsp;</p>
					<h2 data-mce-fragment="1">How do we use cookies and similar technologies?</h2>
					<p data-mce-fragment="1">
						As with many companies, we collect personal data through automated technologies. Specifically,
						we may use cookies, pixels, gifs, web beacons, log files, and/or similar technologies to
						automatically collect certain information when you use the Services or interact with our digital
						media content, such as when you visit our website. This allows us to compile aggregate data
						about website traffic and interaction and to customize better experiences. Specifically, we use
						cookies to help remember and process items in the shopping cart and to save user preferences,
						such as preferred language.
					</p>
					<p data-mce-fragment="1">
						For example, when you use our website, your browser may automatically send certain device,
						browser, internet connection, and general geolocation information and certain internet activity
						information. There are different types of first-party and third-party cookies, which may be
						placed by us or third parties, including essential cookies that are required to display and
						provide the Services; preference cookies that are used to store preferences and improve user
						experiences; analytics cookies to determine how the Services are used and that often collect
						non-personalized information; and marketing cookies to deliver personalized and targeted
						advertisements that may be of interest to you and others, and which are used for business and
						commercial marketing purposes.
					</p>
					<p data-mce-fragment="1">
						In particular, when you visit our website, we may use services of, and your device data may be
						shared with, Google, Meta fka Facebook, and Shopify. This data does not include your precise
						geolocation data. For more information, see e.g.,
						<u data-mce-fragment="1">Meta’s Privacy Policy at </u>
						<a
							data-mce-fragment="1"
							href="https://www.facebook.com/privacy/policy/"
							data-mce-href="https://www.facebook.com/privacy/policy/"
						>
							https://www.facebook.com/privacy/policy/
						</a>
						and <u data-mce-fragment="1">Shopify’s Privacy Policy at </u>
						<a
							data-mce-fragment="1"
							href="https://www.shopify.com/legal/privacy"
							data-mce-href="https://www.shopify.com/legal/privacy"
						>
							https://www.shopify.com/legal/privacy
						</a>
						. We use third-party advertising companies to display ads tailored to your individual interests
						based on your website activity and to provide related services such as analytics and research.
						Third parties, in turn, may use a cookie, web beacon, or other similar technology to collect
						information. See <u data-mce-fragment="1">All about Ads at </u>
						<a
							data-mce-fragment="1"
							href="www.aboutads.info/choices"
							data-mce-href="www.aboutads.info/choices"
						>
							www.aboutads.info/choices
						</a>
						. For example, we use Google cookies and tools, such as Google Analytics. For more information,
						visit the
						<u data-mce-fragment="1">Google Cookie Policy at </u>
						<a
							data-mce-fragment="1"
							href="https://policies.google.com/technologies/cookies?hl=en-US"
							data-mce-href="https://policies.google.com/technologies/cookies?hl=en-US"
						>
							https://policies.google.com/technologies/cookies?hl=en-US
						</a>
						,
						<a
							data-mce-fragment="1"
							href="https://tools.google.com/dlpage/gaoptout"
							data-mce-href="https://tools.google.com/dlpage/gaoptout"
						>
							Google Analytics Opt-Out Browser Add-On at https://tools.google.com/dlpage/gaoptout
						</a>
						, <u data-mce-fragment="1">Ads Settings at </u>
						<a
							data-mce-fragment="1"
							href="https://adssettings.google.com/authenticated"
							data-mce-href="https://adssettings.google.com/authenticated"
						>
							https://adssettings.google.com/authenticated
						</a>
						, and
						<u data-mce-fragment="1">
							Google's Advertising Principles at
							https://support.google.com/adwordspolicy/answer/1316548?hl=en
						</u>
						.&nbsp; You can change your cookie settings in your internet browser and use device settings to
						manage your privacy controls. See also
						<u data-mce-fragment="1">All About Cookies at </u>
						<a
							data-mce-fragment="1"
							href="https://www.allaboutcookies.org/"
							data-mce-href="https://www.allaboutcookies.org/"
						>
							https://www.allaboutcookies.org/
						</a>
						. When you use our GPS Tracking Platform via our App, we do
						<u data-mce-fragment="1">not</u> use Google or Meta marketing cookies.
					</p>
					<h2 data-mce-fragment="1">Do we sell or share information?</h2>
					<p data-mce-fragment="1">
						We only share data as outlined in this Privacy Policy. We do not sell personal information for
						any monetary consideration. When you visit or use our website, we may participate in targeted
						ads, also called online behavioral advertising, using cookies and technologies. Under some
						privacy legislation, this may constitute “selling” of personal information, such as for business
						purposes (<em data-mce-fragment="1">e.g.</em>, providing our Services) and commercial purposes (
						<em data-mce-fragment="1">e.g.</em>, other marketing). To opt out of these practices, contact us
						as outlined below or see our
						<u data-mce-fragment="1">Do Not Sell or Share My Personal Information</u>
						link, or equivalent, if applicable. We do not sell or share data obtained from GPS or dashcam
						products, including our Platform or App.
					</p>
					<p data-mce-fragment="1">
						<strong data-mce-fragment="1">How do we protect and transfer information?</strong>
					</p>
					<p data-mce-fragment="1">
						We take efforts to employ technical, administrative, and physical security measures for personal
						information, taking into account reasonable security procedures and accessible technology.
						However, no system can be completely secure; and we cannot promise, and you should not expect,
						that your personal information will always remain secure. Your provision of personal information
						is at your own risk. The safety and security of your information also depends on you. Take steps
						to safeguard your passwords and other data and notify us as soon as possible if you believe your
						account security has been breached.
					</p>
					<p data-mce-fragment="1">
						As noted, we use the Shopify platform. Our Services are designed for users and data in the
						United States, and information you provide will be processed and securely maintained in our web
						servers and internal systems located within the United States. By using the Services, you
						authorize and specifically consent to the transfer of personal data to the United States and its
						storage and use as specified in this Privacy Policy.
					</p>
					<h2 data-mce-fragment="1">How long do we retain information?</h2>
					<p data-mce-fragment="1">
						We retain personal information for the length of time necessary to fulfill the purposes outlined
						in this Privacy Policy, unless a different retention period is requested by you or required or
						permitted by law. For example, we will retain your personal information for as long as it is
						needed to provide you with the Services or fulfill a legal or contractual obligation. Generally,
						we retain tracking history for one year, subject to exceptions and applicable laws. We may also
						aggregate or deidentify information for use in analytics, such as to analyze trends over periods
						of time without specifically identifying you.
					</p>
					<p data-mce-fragment="1">
						We use the following criteria to determine how long we retain personal information: (a) our
						relationship with you, such as if there is an open contract or account, or a pending transaction
						or request; (b) legal obligations to retain personal information for certain purposes, such as
						to maintain transaction records; and (c) other obligations or considerations relating to the
						retention of data, such as contract requirements, litigation holds, investigations, or statutes
						of limitation.&nbsp;
					</p>
					<h2 data-mce-fragment="1">What are your privacy rights?</h2>
					<p data-mce-fragment="1">
						We value consumer privacy rights and strive to provide flexibility with how your personal data
						is used and shared. If you want to make a privacy request or have any questions, please contact
						us through our website or email us at support@optimustracker.com with your name and email
						address, or other identifier used in connection with our Services so that we can verify your
						request. If an authorized agent is making a request on your behalf, the agent should provide its
						name and contact information, such as an email address, in addition to your information. There
						may be situations where we cannot grant your request; for example, if you make a request and we
						cannot verify your identity, or if you request deletion of data that we have a legal obligation
						to keep. Where we deny your request in whole or in part, we will take steps to inform you of the
						denial and provide an explanation of our actions and the reasons for the denial.
					</p>
					<p data-mce-fragment="1">
						Applicable privacy laws have different requirements and depend on various factors, such as where
						you live and how much revenue or data is at issue. Generally, we adhere to the following set of
						privacy rights to the extent applicable and subject to any limitations authorized by law.
					</p>
					<ul data-mce-fragment="1">
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1"> </em>
							</strong>
							You can access and obtain your data and ask us for certain information, including: the
							categories of personal information collected and used; the categories of the sources of
							data; the business or commercial purposes for collecting, selling, or sharing data; the
							categories of third parties to whom data is disclosed; and the specific pieces of personal
							information collected. You also have a similar right to data portability (the ability to
							export, back up, and transfer data).
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Amend</em>
							</strong>
							<strong data-mce-fragment="1">. </strong>You can amend, correct, or rectify your data if it
							is inaccurate.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Delete</em>
							</strong>
							<strong data-mce-fragment="1">. </strong>You can have your data deleted subject to certain
							legal limitations.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1"> </em>
							</strong>
							You can limit the processing of your data, in particular any sensitive data.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Opt-Out. </em>
							</strong>
							You have the right to opt-out of certain data practices. For example, you can unsubscribe
							from marketing communications by following the opt-out instructions in each message or by
							contacting us as outlined in this Privacy Policy (please note that we may still send
							non-marketing messages, and that consent to receiving marketing communications is not
							mandatory to use the Service). You may also opt out of the “sale” or “sharing” or your
							personal information to the extent applicable. Please see our Do Not Sell or Share My
							Personal Information link. You may opt out of the use of automated decision-making
							technology if applicable.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1"> </em>
							</strong>
							You have the right to make certain complaints, including for privacy concerns. We value your
							feedback and seek the opportunity to work with you on any issues. You have the right to no
							discrimination for asserting your privacy rights.
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Specific State Laws. </em>
							</strong>
							Several states have enacted privacy laws that may apply to you, depending on the
							circumstances. For example, the California Consumer Privacy Act (“CCPA”), amended by the
							California Privacy Rights Act (“CPRA”), governs certain California-related conduct. Other
							states have also enacted comprehensive data privacy laws. Under California’s “Shine the
							Light” law, California residents may also request certain information regarding our sharing
							personal information with third parties for their direct marketing purposes. Further, if you
							are a California resident under the age of 18, California Business &amp; Professions Code
							Section 22581 permits you to request and obtain removal of content you have publicly posted
							(but does not ensure complete removal of public content).
						</li>
						<li data-mce-fragment="1">
							<strong data-mce-fragment="1">
								<em data-mce-fragment="1">Other Laws. </em>
							</strong>
							Depending on where you live, you may have the right to access your own information that we
							hold; to ask that your information be corrected, updated, or erased; and the right to object
							to, or request that we restrict, certain processing of your information. Our legal basis for
							collecting and using your personal data is your consent, the fulfillment of our obligations
							pursuant to the contract created between you and us, or where the collection and use is in
							our legitimate interests and does not violate your data protection interests or fundamental
							rights. You may withdraw your consent to our collection and use of your personal data.
							Withdrawing your consent will not affect the lawfulness of any processing we conducted prior
							to your withdrawal, nor will it affect processing of your information used in reliance on
							lawful processing grounds other than consent. If certain other privacy laws apply to you
							that provide you with additional rights, please contact us to make a request.
						</li>
					</ul>
					<p data-mce-fragment="1">
						<strong data-mce-fragment="1">Do we collect information about children?</strong>
					</p>
					<p data-mce-fragment="1">
						No, our Services are not intended for use with children, and we do not knowingly collect any
						personal information about children under the age of 13 without the verifiable consent and
						request of the parent or guardian. If we obtain actual knowledge that we have collected such
						information, we will delete it from our database. We have no such information to use or to
						disclose to third parties or to otherwise report, including under the Children’s Online Privacy
						Protection Act (“COPPA”). We do not have actual knowledge of selling or sharing the personal
						information of consumers under the age of 16.
					</p>
					<p data-mce-fragment="1">
						<strong data-mce-fragment="1">When do we collect sensitive information?</strong>
					</p>
					<p data-mce-fragment="1">
						If you subscribe to our GPS Tracking Platform or use the Products, we collect and process your
						geolocation data as tracked with your Products, which may include precise geolocation under
						certain laws, and if you use Dashcam Products, we collect audio and video data as captured by
						the dashcams, which may include personal or non-public footage. The Products may also collect
						this type of data about third parties, in particular where our customers use the Products in
						certain ways and represent complying with applicable laws. We do this to perform the services
						reasonably expected by the average user who uses these Services and to perform our service
						provider role in servicing accounts and processing and fulfilling orders. As authorized, we may
						also use information as reasonably necessary and proportionate to (a) detect security incidents,
						(b) resist malicious, deceptive, fraudulent, or illegal actions, (c) ensure physical safety of
						natural persons, (d) for short-term use where there is no disclosure or profiling, and (e) to
						verify or maintain the quality or safety of a service or device or to upgrade, enhance, or
						improve it.
					</p>
					<p data-mce-fragment="1">
						<strong data-mce-fragment="1">Do we offer financial incentives?</strong>
					</p>
					<p data-mce-fragment="1">
						From time to time, we may offer incentives related to the collection, retention, or sharing of
						information that may be deemed a “financial incentive” or “price or service difference.” If you
						opt in to such an offering pursuant to the terms described at the time of signup, we may collect
						information such as name and contact information and other information as disclosed. Any
						difference in price or rate, such as a discount, will be reasonably related to the value of the
						data. If you want to withdraw from the offering, please contact us.
					</p>
					<h2 data-mce-fragment="1">Do we respond to DNT signals?</h2>
					<p data-mce-fragment="1">
						We generally do not recognize DNT (Do Not Track) requests. DNT is a feature that, when enabled,
						sends a signal to websites to request that your browsing not be tracked.
					</p>
					<p data-mce-fragment="1">
						“GPC” is short for Global Privacy Control settings in your browser or extension. We recognize
						GPC signals. This means that if your browser has GPC enabled, our website will automatically
						recognize your GPC signal and opt you out of the sale of your personal information, if any. For
						more information about GPC, please click here:
					</p>
					<p data-mce-fragment="1">https://globalprivacycontrol.org/</p>
					<h2 data-mce-fragment="1">How do we update this Privacy Policy?</h2>
					<p data-mce-fragment="1">
						We will update this Privacy Policy when our privacy practices change or as otherwise required or
						permitted by law. Each time you use the Services, the current version of this Privacy Policy
						will apply. Unless we receive your express consent, any revised Privacy Policy will apply only
						to information collected after the effective date of the revised Privacy Policy.
					</p>
					<h2 data-mce-fragment="1">How can you contact us?</h2>
					<p data-mce-fragment="1">Please contact us with any questions or concerns! We can be reached at:</p>
					<p data-mce-fragment="1">
						<strong data-mce-fragment="1">Optimus GPS Tracking</strong>
					</p>
					<p data-mce-fragment="1">
						15499 SW 12th St., Suite 509
						<br data-mce-fragment="1" />
						Sunrise, Florida 33326
					</p>
					<p data-mce-fragment="1">
						<a
							data-mce-fragment="1"
							href="mailto:sales@optimustracker.com"
							data-mce-href="mailto:sales@optimustracker.com"
						>
							sales@optimustracker.com
						</a>
					</p>
					<p data-mce-fragment="1">
						<a
							data-mce-fragment="1"
							href="mailto:support@optimustracker.com"
							data-mce-href="mailto:support@optimustracker.com"
						>
							support@optimustracker.com
						</a>
					</p>
					<p data-mce-fragment="1">(855) 893-0707</p>
					<p data-mce-fragment="1">&nbsp;</p>
					<p data-mce-fragment="1">
						<strong data-mce-fragment="1">Last Updated: January 6, 2025</strong>
						<strong data-mce-fragment="1">.</strong>
					</p>
				</main>
			</section>
		</div>
	);
};

export default Policies;

import { ActionType, CleanReducerAction, IAction, LogOutAction } from '../../types/actionType';
import { MultimediaModal } from './reducer';

interface OpenByDateAction extends IAction<MultimediaModal> {
	type: ActionType.MULTIMEDIA_OPEN_BY_DATE;
}

interface OpenByTrackingHistoryIdAction extends IAction<MultimediaModal> {
	type: ActionType.MULTIMEDIA_OPEN_BY_TRACKING_HISTORY_ID;
}

interface UpdateMultimediaDateAction extends IAction<Date> {
	type: ActionType.MULTIMEDIA_UPDATE_UTC_DATE;
}

interface CloseMultimediaAction extends IAction {
	type: ActionType.MULTIMEDIA_CLOSE_MODAL;
}

interface ClearRefreshDataAction extends IAction<boolean> {
	type: ActionType.MULTIMEDIA_CLEAR_REFHESH_DATA;
}

interface ClearChannelKeyMultimediaAction extends IAction {
	type: ActionType.MULTIMEDIA_CLEAR_CHANNEL_KEY;
}

interface UpdateDeviceEventAction extends IAction {
	type: ActionType.UPDATE_DEVICE_EVENT;
}

interface LoadingTraceAction extends IAction {
	type: ActionType.LOADING_TRACE;
}

interface GoogleLoadedAction extends IAction {
	type: ActionType.GOOGLE_LOADED;
}

export type Action =
	| OpenByDateAction
	| OpenByTrackingHistoryIdAction
	| UpdateMultimediaDateAction
	| CloseMultimediaAction
	| ClearChannelKeyMultimediaAction
	| UpdateDeviceEventAction
	| LoadingTraceAction
	| GoogleLoadedAction
	| ClearRefreshDataAction
	| CleanReducerAction
	| LogOutAction;

export const OpenMultimediaByDate = (config: MultimediaModal): OpenByDateAction => ({
	type: ActionType.MULTIMEDIA_OPEN_BY_DATE,
	payload: { ...config, open: true, trackingHistoryId: undefined },
});

export const OpenMultimediaByTrackingHistoryId = (config: MultimediaModal): OpenByTrackingHistoryIdAction => ({
	type: ActionType.MULTIMEDIA_OPEN_BY_TRACKING_HISTORY_ID,
	payload: { ...config, open: true, utcDate: undefined },
});

export const UpdateMultimediaDate = (utcDate: Date): UpdateMultimediaDateAction => ({
	type: ActionType.MULTIMEDIA_UPDATE_UTC_DATE,
	payload: utcDate,
});

export const CloseMultimedia = (): CloseMultimediaAction => ({
	type: ActionType.MULTIMEDIA_CLOSE_MODAL,
});

export const ClearRefreshMultimediaData = (): ClearRefreshDataAction => ({
	type: ActionType.MULTIMEDIA_CLEAR_REFHESH_DATA,
	payload: false,
});

export const ClearChannelKeyMultimedia = (): ClearChannelKeyMultimediaAction => ({
	type: ActionType.MULTIMEDIA_CLEAR_CHANNEL_KEY,
});

export const UpdateDeviceEvent = (): UpdateDeviceEventAction => ({
	type: ActionType.UPDATE_DEVICE_EVENT,
});

export const GoogleMapsLoaded = (): GoogleLoadedAction => ({
	type: ActionType.GOOGLE_LOADED,
});

export const LoadingTrace = (): LoadingTraceAction => ({
	type: ActionType.LOADING_TRACE,
});

import { ActionType, CleanReducerAction, IAction, LogOutAction } from '../../types/actionType';
import { CameraDto } from './camera.dto';

interface SetCameraInfoAction extends IAction<CameraDto[]> {
	type: ActionType.CAMERA_FETCH_INFO;
}

export type Action = SetCameraInfoAction | CleanReducerAction | LogOutAction;

export const UpdateCameraInfo = (data: CameraDto[]): SetCameraInfoAction => ({
	type: ActionType.CAMERA_FETCH_INFO,
	payload: data,
});
